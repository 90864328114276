import React, { useState } from "react";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress/index";
import View from "./modals/View";
import Delete from "./modals/Remove";
import Edit from "./modals/Edit";
import LibraryContentTable from "./components/LibraryContentTable";
import { RenderIf } from "../../../components";
import { useGetContentsQuery } from "../../../../redux/api/Content/contentApi";

export function LibraryContent() {
  const [showEdit, setShowEdit] = useState(false);
  const [showView, setShowView] = React.useState(false);
  const [showRemove, setShowRemove] = useState(false);

  const { user } = useSelector((state) => state.user);
  const permissions = user?.user?.permissions;

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowView(open);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [teamId, setTeamId] = useState(null);
  const [deleteUsersIds, setDeleteUsersIds] = useState([]);

  const { data, isLoading, refetch } = useGetContentsQuery({
    contentType: 14,
  });

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  return (
    <div className="row bg-white rounded d-flex  justify-content-center h-100">
      <div className="col-12 d-flex align-items-center py-4 height-65">
        <h1 className="display-4 mb-0">About Hubdev</h1>
      </div>
      <RenderIf condition={isLoading}>
        <div>
          <CircularProgress />
        </div>
      </RenderIf>
      <RenderIf condition={data && !isLoading} renderElse={""}>
        <LibraryContentTable
          setOpenEdit={setShowEdit}
          setOpenView={toggleDrawer(true)}
          setOpenRemove={setShowRemove}
          rows={data?.data}
          count={data?.totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          permissions={permissions}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          setTeamId={setTeamId}
          setDeleteUsersID={setDeleteUsersIds}
          deleteUsersIds={deleteUsersIds}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </RenderIf>

      <RenderIf condition={showView}>
        <View open={showView} onClose={toggleDrawer(false)} id={teamId} />
      </RenderIf>
      <RenderIf condition={showEdit}>
        <Edit
          id={teamId}
          show={showEdit}
          onHide={setShowEdit}
          refetch={refetch}
        />
      </RenderIf>
      <RenderIf condition={showRemove}>
        <Delete
          id={teamId}
          show={showRemove}
          onHide={setShowRemove}
          refetch={refetch}
        />
      </RenderIf>
    </div>
  );
}
