import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RenderIf, ReactSelect, RichTextEditor } from "../../../components";
import {
  useGetAllCourseOrganizationQuery,
  useGetAllCourseTypeQuery,
  useGetAllHubDevCategoryQuery,
  useGetCourseQuery,
  useUpdateCourseMutation,
} from "../../../../redux/api/Library/libraryApi";
import { useGetAllTagsWithoutFilterQuery } from "../../../../redux/api/Tags/tagsApi";
import { useGetUsersQuery } from "../../../../redux/api/Auth/Users/usersApi";
import { complexitiesOptions } from "../../../constants";
import { initialValues } from "./data";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const { REACT_APP_BASE_PHOTO_URL_MAIN } = process.env;

export default function Edit(props) {
  const { onHide, show, refetch, id } = props;
  const { data, isSuccess: dataSuccess, isFetching } = useGetCourseQuery(id);
  const {
    data: tagList,
    isSuccess: isTagListSuccess,
  } = useGetAllTagsWithoutFilterQuery();
  const {
    data: courseTypes,
    isSuccess: courseSuccss,
    isFetching: courseFetching,
  } = useGetAllCourseTypeQuery({});
  const {
    data: organizationTypes,
    isSuccess: organizationSuccess,
    isFetching: organizationFetching,
  } = useGetAllCourseOrganizationQuery({});
  const {
    data: categoryTypes,
    isSuccess: categorySuccess,
    isFetching: categoryFetching,
  } = useGetAllHubDevCategoryQuery({
    skip: "",
    take: "",
    field: "id",
    orderBy: false,
    title: "",
  });
  const {
    data: users,
    isSuccess: userSuccess,
    isFetching: userFetching,
  } = useGetUsersQuery({});

  const filePath = REACT_APP_BASE_PHOTO_URL_MAIN + data?.iconFileUrl;
  const [file, setFile] = useState("");
  const [imageSrc, setImageSrc] = useState(filePath);
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [defaultVal, setDefaultVal] = useState([]);
  const [deletedTags, setDeletedTags] = useState([]);
  const [newsTagsApi, SetNewsTagsApi] = useState([]);
  const [tagsApi, SetTagsApi] = useState([]);
  const [content, setContent] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const usersIds = [];
  const typeOptions = courseTypes?.data?.map((data) => ({
    value: data?.id,
    label: data?.title,
  }));
  const organizationOptions = organizationTypes?.data?.map((data) => ({
    value: data?.id,
    label: data?.title,
  }));
  const categoryOptions = categoryTypes?.data?.map((data) => ({
    value: data?.id,
    label: data?.title,
  }));
  const usersOption = users?.data?.map((data) => ({
    value: data?.id,
    label: data?.email,
  }));

  const handleOnChange = (value) => {
    const difference = selected?.filter((x) => !value.includes(x));
    difference?.forEach((opt) => {
      if (defaultVal?.includes(opt)) {
        setDeletedTags([...deletedTags, opt]);
      } else if (tagsApi?.includes(opt)) {
        const TagsApiClone = tagsApi?.filter((optApi) => optApi !== opt);
        SetTagsApi(TagsApiClone);
      } else {
        const newsTagsApiClone = newsTagsApi?.filter(
          (optApi) => optApi !== opt
        );
        SetNewsTagsApi(newsTagsApiClone);
      }
    });
    value?.forEach((val) => {
      if (!defaultVal?.includes(val)) {
        if (val?.__isNew__) {
          !newsTagsApi?.includes(val) && SetNewsTagsApi([...newsTagsApi, val]);
        } else {
          !tagsApi?.includes(val) &&
            !defaultVal?.includes(val) &&
            SetTagsApi([...tagsApi, val]);
        }
      }
    });
    setSelected(value);
  };

  const onTypes = () => {
    const assets = tagList?.data?.map((suggestion) => ({
      value: suggestion.id,
      label: suggestion.name,
    }));
    setList(assets);
    const selectedDefault = data?.tags?.map((item) => {
      return {
        value: item?.id,
        label: item?.name,
      };
    });
    setSelected(selectedDefault);
    setDefaultVal(selectedDefault);
  };

  const imagePreview = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    setFile(e.target.value);

    reader.onloadend = () => {
      setImageSrc(reader.result);
    };

    reader.readAsDataURL(file);
  };
  const onClick = (e) => {
    setImageSrc("");
    setFile("");
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [
    updateCourse,
    { isLoading, error, isSuccess },
  ] = useUpdateCourseMutation();

  const form = useRef(null);

  const EditSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string()
      .required("Description is required")
      .test("rich-text-not-empty", "Description cannot be empty", (value) => {
        if (typeof value !== "string") return false;
        const trimmedValue = value.trim();
        return trimmedValue !== "<p><br></p>" && trimmedValue !== "";
      }),
    url: Yup.string().required("Url is required"),
    scrollCourseId: Yup.number().required("Scroll Course Id is required"),
    iconFile: Yup.string().required("File is required"),
    lenghtInMinutes: Yup.number()
      .min(1, "Min. value must be greater than 0")
      .required("Duration is required"),
    orderingId: Yup.number()
      .min(1, "Min. value must be greater than 0")
      .required("Order Id is required"),
  });

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }

    return false;
  };

  const onPutCourse = (values) => {
    const newExpert = selectedUsers
      .filter(
        (selectedItem) =>
          !data?.experts?.some((item) => selectedItem.value === item.id)
      )
      .map((selectedItem) => selectedItem.value);
    const deletedExpert = data?.experts
      ?.filter(
        (item) =>
          !selectedUsers.some(
            (selectedItem) => selectedItem?.value === item?.id
          )
      )
      .map((item) => item?.id);
    const library = new FormData(form.current);
    library.append("Id", id);
    library.append("description", values.description);
    library.append("IFrameUrl", values.url);
    library.append("scrollCourseId", values.scrollCourseId);
    library.append("title", values.title);

    if (newExpert?.length) {
      for (let i = 0; i < newExpert?.length; i++) {
        library.append("experts", newExpert?.[i]);
      }
    }
    if (deletedExpert?.length) {
      for (let i = 0; i < deletedExpert?.length; i++) {
        library.append("deletedExperts", deletedExpert?.[i]);
      }
    }
    library.append("lenghtInMinutes", values.lenghtInMinutes);
    library.append("categoryId", values.categoryId?.value);

    library.append("OrderingId", String(values.orderingId));
    library.append(
      "hubDevCourseOrganizationId",
      String(values.hubDevCourseOrganizationId?.value)
    );
    library.append(
      "hubDevCourseTypeId",
      String(values.hubDevCourseTypeId?.value)
    );
    library.append("complexity", String(values.complexity?.value));
    library.append("iconFile", file);

    if (tagsApi?.length) {
      for (let i = 0; i < tagsApi?.length; i++) {
        library.append("tags", tagsApi?.[i].value);
      }
    }
    if (newsTagsApi?.length) {
      for (let i = 0; i < newsTagsApi?.length; i++) {
        library.append("newTags", newsTagsApi?.[i].label);
      }
    }
    if (deletedTags?.length) {
      for (let i = 0; i < deletedTags?.length; i++) {
        library.append("DeletedTags", deletedTags?.[i].value);
      }
    }

    updateCourse(library);
  };
  const formik = useFormik({
    initialValues,
    validationSchema: EditSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      onPutCourse(values, resetForm, setSubmitting);
    },
  });
  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
    }
  }, [isLoading]);

  useEffect(() => {
    setImageSrc(filePath);
  }, [filePath]);

  useEffect(() => {
    if (isTagListSuccess && dataSuccess) onTypes();
  }, [isTagListSuccess, dataSuccess]);

  function handleChangeExpert(c) {
    setSelectedUsers(c);
    const mockArray = [];
    c.forEach((element) => {
      mockArray.push(element.value);
    });
    formik.setFieldValue("experts", mockArray);
  }
  useEffect(() => {
    if (
      (dataSuccess,
      courseSuccss,
      organizationSuccess,
      categorySuccess,
      userSuccess)
    ) {
      const type = typeOptions?.find(
        (option) => option.value === data?.hubDevCourseType?.id
      );
      const category = categoryOptions?.find(
        (option) => option.value === data?.hubDevCategory?.id
      );
      const organization = organizationOptions?.find(
        (option) => option.value === data?.hubDevCourseOrganization?.id
      );
      const complexity = complexitiesOptions?.find(
        (option) => option.value === data?.complexity
      );

      if (data?.experts?.length && usersOption?.length) {
        data?.experts?.map((item, index) => {
          usersIds.push(
            usersOption?.find((option) => option.value === item?.id)
          );
          return index;
        });
        setSelectedUsers((prev) => usersIds);
      }

      formik.setValues({
        url: data?.iFrameUrl,
        title: data?.title,
        lenghtInMinutes: data?.lenghtInMinutes,
        description: data?.description,
        hubDevCourseTypeId: type,
        hubDevCourseOrganizationId: organization,
        categories: category,
        experts: selectedUsers,
        complexity: complexity,
        categoryId: category,
        tags: data?.tags,
        scrollCourseId: data?.scrollCourseId,
        iconFile: data?.iconFileUrl,
        orderingId: data?.orderingId,
      });
    }
  }, [
    isFetching,
    courseFetching,
    categoryFetching,
    userFetching,
    organizationFetching,
  ]);
  useEffect(() => {
    if (data) {
      setContent(data?.description);
    }
  }, [data, id]);
  return (
    <Modal
      size="xl"
      onHide={onHide}
      show={show}
      aria-labelledby="edit"
      className="modal-center pr-0"
      centered
    >
      <RenderIf
        condition={
          Object.keys(data || {}).length && data?.constructor === Object
        }
      >
        <form onSubmit={formik.handleSubmit} ref={form}>
          <Modal.Header closeButton>
            <Modal.Title id="edit">Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body id="userModal">
            <div className="col-12 pl-4 pr-4">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                    <TextField
                      id="title"
                      name="title"
                      placeholder="Title"
                      className="w-100"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={getInputClasses(
                        formik.touched,
                        formik.errors,
                        "title"
                      )}
                      onKeyDown={handleEnter}
                    />
                  </div>
                  <RenderIf
                    condition={formik.touched.title && formik.errors.title}
                  >
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.title}</div>
                    </div>
                  </RenderIf>
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                    <TextField
                      id="lenghtInMinutes"
                      name="lenghtInMinutes"
                      placeholder="Duration"
                      className="w-100"
                      type="number"
                      value={formik.values.lenghtInMinutes}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={getInputClasses(
                        formik.touched,
                        formik.errors,
                        "lenghtInMinutes"
                      )}
                      onKeyDown={handleEnter}
                    />
                  </div>
                  <RenderIf
                    condition={
                      formik.touched.lenghtInMinutes &&
                      formik.errors.lenghtInMinutes
                    }
                  >
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.lenghtInMinutes}
                      </div>
                    </div>
                  </RenderIf>
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                    <TextField
                      id="url"
                      name="url"
                      placeholder="Url"
                      className="w-100"
                      value={formik.values.url}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={getInputClasses(
                        formik.touched,
                        formik.errors,
                        "url"
                      )}
                      onKeyDown={handleEnter}
                    />
                  </div>
                  <RenderIf condition={formik.touched.url && formik.errors.url}>
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.url}</div>
                    </div>
                  </RenderIf>
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                    <TextField
                      id="scrollCourseId"
                      type="number"
                      name="scrollCourseId"
                      placeholder="Scroll Course Id"
                      className="w-100"
                      value={formik.values.scrollCourseId}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={getInputClasses(
                        formik.touched,
                        formik.errors,
                        "scrollCourseId"
                      )}
                      onKeyDown={handleEnter}
                    />
                  </div>
                  <RenderIf
                    condition={
                      formik.touched.scrollCourseId &&
                      formik.errors.scrollCourseId
                    }
                  >
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.scrollCourseId}
                      </div>
                    </div>
                  </RenderIf>
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                    <TextField
                      id="orderingId"
                      type="number"
                      name="orderingId"
                      placeholder="Order Id"
                      className="w-100"
                      value={formik.values.orderingId}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={getInputClasses(
                        formik.touched,
                        formik.errors,
                        "orderingId"
                      )}
                      onKeyDown={handleEnter}
                    />
                  </div>
                  <RenderIf
                    condition={
                      formik.touched.orderingId && formik.errors.orderingId
                    }
                  >
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.orderingId}
                      </div>
                    </div>
                  </RenderIf>
                </div>
                <div className="col-12 col-md-12 col-lg-6 mt-4">
                  <CreatableSelect
                    isMulti
                    defaultValue={formik.values.tags?.map((item) => {
                      return {
                        value: item?.id,
                        label: item?.name,
                      };
                    })}
                    onChange={handleOnChange}
                    value={selected}
                    options={list}
                    id="creatable-select"
                    instanceId="creatable-select"
                    className="creatable-select"
                  />
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <ReactSelect
                    placeholder="Course Type"
                    inputId="courseTypeId"
                    options={typeOptions}
                    single={formik.values.hubDevCourseTypeId}
                    onChange={(newValue) => {
                      formik.setFieldValue(
                        "hubDevCourseTypeId",
                        newValue || ""
                      );
                    }}
                    menuPosition="fixed"
                  />
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <ReactSelect
                    placeholder="Course Organization"
                    inputId="courseOrganizationId"
                    options={organizationOptions}
                    single={formik.values.hubDevCourseOrganizationId}
                    onChange={(newValue) => {
                      formik.setFieldValue(
                        "hubDevCourseOrganizationId",
                        newValue || ""
                      );
                    }}
                    menuPosition="fixed"
                  />
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <ReactSelect
                    placeholder="Course Category"
                    inputId="courseCategoryId"
                    options={categoryOptions}
                    single={formik.values.categoryId}
                    onChange={(newValue) => {
                      formik.setFieldValue("categoryId", newValue || "");
                    }}
                    menuPosition="fixed"
                  />
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <ReactSelect
                    placeholder="Complexities"
                    inputId="complexitiesId"
                    options={complexitiesOptions}
                    single={formik.values.complexity}
                    onChange={(newValue) => {
                      formik.setFieldValue("complexity", newValue || "");
                    }}
                    menuPosition="fixed"
                  />
                </div>
                <div className="col-12 col-md-12 col-lg-6">
                  <ReactSelect
                    placeholder="Experts"
                    inputId="expertId"
                    options={usersOption}
                    isMulti
                    single={selectedUsers}
                    onChange={handleChangeExpert}
                    isSearchable
                    menuPosition="fixed"
                  />
                </div>

                <div className="col-12 col-md-12 col-lg-6">
                  <div className="w-100 pt-3 pb-3">
                    <label htmlFor="contained-button-file">
                      <input
                        accept="image/*"
                        id="contained-button-file"
                        type="file"
                        name="iconFile"
                        label="File {Path}"
                        placeholder="File Path"
                        className="w-100"
                        onChange={(e) => {
                          imagePreview(e);
                          formik.setValues({
                            ...formik.values,
                            iconFile: e.target.files[0],
                          });
                        }}
                        value={file}
                      />
                    </label>

                    <RenderIf condition={imageSrc?.length}>
                      <div className="image-preview mt-4">
                        <div
                          style={{
                            position: "relative",
                            width: "100px",
                            height: "100px",
                          }}
                        >
                          <img
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              borderRadius: "100%",
                            }}
                            src={imageSrc}
                            alt="img"
                          />
                          <button
                            style={{
                              position: "absolute",
                              right: "-30px",
                            }}
                            className="BtnSvg"
                            onClick={() => {
                              onClick();
                              formik.setFieldValue("iconFile", "");
                            }}
                          >
                            <i
                              style={{
                                fontSize: "10px",
                              }}
                              className="flaticon2-delete"
                            ></i>
                          </button>
                        </div>
                      </div>
                    </RenderIf>
                    <RenderIf
                      condition={
                        formik.touched.iconFile && formik.errors.iconFile
                      }
                    >
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.iconFile}
                        </div>
                      </div>
                    </RenderIf>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-12">
                  <div className="w-100 pt-2 pb-3">
                    <RichTextEditor
                      onChange={(newContent) => {
                        const trimmedContent = newContent.trim();
                        const isEmpty = trimmedContent === "<p><br></p>";
                        setContent(isEmpty ? "" : newContent);

                        formik.setFieldValue(
                          "description",
                          isEmpty ? "" : newContent
                        );
                      }}
                      value={content}
                    />
                  </div>
                  <RenderIf
                    condition={
                      formik.touched.description && formik.errors.description
                    }
                  >
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.description}
                      </div>
                    </div>
                  </RenderIf>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="light"
              onClick={() => {
                onHide(false);
                formik.resetForm();
              }}
            >
              Close
            </Button>
            <Button type="submit" disabled={isLoading} className={`px-9`}>
              Save
              <RenderIf condition={isLoading}>
                <span className="ml-3 spinner spinner-white"></span>
              </RenderIf>
            </Button>
          </Modal.Footer>
        </form>
      </RenderIf>
    </Modal>
  );
}
