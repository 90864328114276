import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import * as Yup from "yup";
import Scope from "../components/Scope";
import { useSelector, useDispatch } from "react-redux";
import {
  useGetRoleQuery,
  useUpdateRolesMutation,
} from "../../../../redux/api/Roles/rolesApi";
import {
  addPermission,
  resetPermission,
} from "../../../../redux/features/Role/RoleSlicer";

import { useGetBackOfficeUsersPermissionsQuery } from "../../../../redux/api/Auth/BackOfficeUsers/backofficeUsersApi";

export default function Edit(props) {
  const { onHide, show, refetch, id } = props;
  const dispatch = useDispatch();
  const [chooseScope, setChooseScope] = useState({});
  const { permissions2 } = useSelector((state) => state.rolesSlicer);
  const { permissions } = useSelector((state) => state.usersState);
  const [select, setSelect] = useState([]);
  const { data, isSuccess: dataSuccess } = useGetRoleQuery(id);
  const [perId, setPerId] = useState("");

  useEffect(() => {
    if (permissions.length) {
      let tempState = permissions.map((item) => ({
        ...item,
        checked: false,
        parameters: item.parameters.map((el) => ({
          ...el,
          checked: false,
        })),
      }));

      let basePermissions = data?.permissions?.filter(
        (item) => item.scopeId === 0
      );

      for (let i = 0; i < basePermissions?.length; i++) {
        let id = basePermissions[i].permissionId;

        let index = tempState.findIndex((item) => item.id == id);
        tempState[index].checked = true;
        dispatch(addPermission({ id: tempState[index].id }));
      }

      let scopePermissions = data?.permissions?.filter(
        (item) => item.scopeId !== 0
      );

      for (let a = 0; a < scopePermissions?.length; a++) {
        let scopeIdx = a;

        for (let b = 0; b < tempState?.length; b++) {
          let tempStateIdx = b;

          for (let c = 0; c < tempState[b].parameters.length; c++) {
            let parametersIdx = c;
            if (
              tempState[b].parameters[c].id ===
              scopePermissions[scopeIdx].scopeId
            ) {
              tempState[tempStateIdx].parameters[parametersIdx].checked = true;
              dispatch(
                addPermission({
                  id: tempState[tempStateIdx].id,
                  value:
                    tempState[tempStateIdx].parameters[parametersIdx].value,
                  scopeId: tempState[tempStateIdx].parameters[parametersIdx].id,
                })
              );
            }
          }
        }
      }

      setSelect(tempState);
    }
  }, [permissions, data?.permissions]);
  const changeRadioButton = (
    name,
    valid,
    touch,
    permissonId,
    value = null,
    scopeId = null,
    parameterIdx,
    scopeIdx,
    e
  ) => {
    let tempState = [...select];
    if (!value && !scopeId) {
      tempState[scopeIdx].checked = !tempState[scopeIdx].checked;
      setSelect(tempState);
    } else {
      let parametersArr = tempState[scopeIdx].parameters;
      for (let i = 0; i < parametersArr.length; i++) {
        if (i === parameterIdx) {
          tempState[scopeIdx].parameters[i].checked = !tempState[scopeIdx]
            .parameters[i].checked;
        } else {
          tempState[scopeIdx].parameters[i].checked = false;
        }
      }

      setSelect(tempState);
    }

    const newScope = {
      ...chooseScope,
      [name]: {
        valid: valid,
        touch: touch,
        message: "Select the permission type",
        scope: {
          id: permissonId,
          scopeId: scopeId,
          value: value,
        },
      },
    };

    setChooseScope(newScope);
  };
  const handeleHide = () => {
    onHide();
    dispatch(resetPermission());
  };

  React.useEffect(() => {}, [select]);

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [
    updateRoles,
    { isLoading, error, isSuccess },
  ] = useUpdateRolesMutation();
  const { Pdata } = useGetBackOfficeUsersPermissionsQuery();

  useEffect(() => {
    if (isSuccess) {
      refetch();
      dispatch(resetPermission());
      onHide();
    }
  }, [isLoading]);

  const onPutRoles = (values, resetForm, setSubmitting) => {
    // {result.length?}
    const regionalCenter = {
      id: id,
      name: values.name,
      permissons: newPer,
    };
    updateRoles(regionalCenter);
    dispatch(resetPermission());
  };

  const EditSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  const getInputClasses = (touched, errors, FieldName) => {
    if (touched[FieldName] && errors[FieldName]) {
      return true;
    }
    if (touched[FieldName] && errors[FieldName]) {
      return false;
    }

    return false;
  };

  let falseItem;
  const categoryNames = [
    "Xəbərlər",
    "Fəaliyyət",
    "Haqqında",
    "Kontakt",
    "Discussion",
    "Community",
    "Tədbir",
    "Kitabxana",
    "Rol",
    "İstifadəçi",
  ];
  const [newPer, setNewPer] = useState([]);
  useEffect(() => {
    falseItem = select.filter(
      (el) => el.checked == false && el.parameters.length == 0
    );
    const falseItemId = falseItem.map((el) => el.id);

    let newData = permissions2.filter((per) => !falseItemId.includes(per.id));

    setNewPer(newData);
  }, [select]);

  const resetPermissionId = (id) => {
    let index = select.findIndex((item) => item.id == id);
    let newState = [...select];
    for (let m = 0; m < select.length; m++) {
      if (m === index) {
        newState[index].parameters.map((el) => (el.checked = false));
        setSelect(newState);
      }
    }
  };

  return (
    <Modal
      size="xl"
      onHide={handeleHide}
      show={show}
      aria-labelledby="edit"
      className="modal-center pr-0"
      centered
    >
      {data && Object.keys(data).length !== 0 && data.constructor === Object && (
        <Formik
          initialValues={{
            name: data?.name,
            // permissons:permissons
          }}
          validationSchema={EditSchema}
          onSubmit={(values, { resetForm, setSubmitting }) => {
            onPutRoles(values, resetForm, setSubmitting);
            resetForm();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            handleBlur,
            resetForm,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title id="edit">Edit</Modal.Title>
              </Modal.Header>
              <Modal.Body id="userModal">
                <div className="col-12 pl-4 pr-4">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-12">
                      <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                        <TextField
                          id="name"
                          name="name"
                          label="Name"
                          placeholder="Name"
                          className="w-100"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getInputClasses(touched, errors, "name")}
                          onKeyDown={handleEnter}
                        />
                      </div>
                      {touched.name && errors.name ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.name}</div>
                        </div>
                      ) : null}
                    </div>

                    <div className="col-12 col-md-12 col-lg-12">
                      <div className="w-100 pt-2 pb-3">
                        {select?.map(
                          ({ id, name, parameters, checked }, scopeIdx) => {
                            const permissonId = id;
                            const name2 = `Checkboxes[${permissonId}]`;
                            const categoryIndex = Math.floor(scopeIdx / 4);

                            return (
                              <div key={id} className="mb-4">
                                <div className="font-weight-bold">
                                  {categoryNames[categoryIndex]}
                                </div>
                                <Scope
                                  setPerId={setPerId}
                                  resetPermissionId={resetPermissionId}
                                  perId={perId}
                                  key={id}
                                  name={name2}
                                  oneChoice={checked}
                                  select={select}
                                  permissonId={permissonId}
                                  changeRadioButton={changeRadioButton}
                                  parameters={parameters}
                                  label={name}
                                  setSelect={setSelect}
                                  chooseScope={chooseScope}
                                  scopeIdx={scopeIdx}
                                />
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="light"
                  onClick={() => {
                    onHide(false);
                    resetForm();
                    dispatch(resetPermission());
                  }}
                >
                  Close
                </Button>
                <Button type="submit" disabled={isLoading} className={`px-9`}>
                  Save
                  {isLoading && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      )}
    </Modal>
  );
}
