import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

import { v4 as uuid } from "uuid";
import axios from "axios";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { makeStyles } from "@material-ui/core/styles";

import { useFormik } from "formik";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  textarea: {
    width: "100% !important",
    height: "100px !important",
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
}));

const initialValues = {
  deleteReason: "",
};

export default function RemoveAll(props) {
  const classes = useStyles();
  const {
    onHide,
    show,
    deleteUsersIds,
    setDeleteUsersIds,
    skip,
    take,
    sortField,
    orderBy,
    onFilter,
    filterInputs,
  } = props;
  const [deleteReason, setDeleteReason] = useState("");

  const handleChange = (e) => {
    setDeleteReason(e.target.value);
  };

  const DeleteSchema = Yup.object().shape({
    deleteReason: Yup.string().required("Səbəb daxil etməyiniz tələb olunur."),
  });

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }

    return false;
  };

  const onDeleteUser = (values, resetForm, setSubmitting) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}users`, {
        headers: {
          "x-requestid": uuid(),
        },
        data: {
          id: deleteUsersIds,
          deleteReason: values.deleteReason,
        },
      })
      .then((res) => {
        setDeleteUsersIds([]);
        onFilter(skip, take, sortField, orderBy, filterInputs);
        onHide(false);
        setSubmitting(false);
        resetForm();
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: DeleteSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      onDeleteUser(values, resetForm, setSubmitting);
    },
  });

  return (
    <Modal show={show} onHide={onHide} aria-labelledby="delete" centered>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="delete">Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 pl-4 pr-4 mb-3">
            <div className="row">Are you sure you want to delete?</div>
          </div>
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <TextareaAutosize
                id="deleteReason"
                placeholder="Enter the reason for deletion"
                className={classes.textarea}
                error={getInputClasses("deleteReason")}
                {...formik.getFieldProps("deleteReason")}
              />
              {formik.touched.deleteReason && formik.errors.deleteReason ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.deleteReason}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
            }}
          >
            Close
          </Button>
          <Button type="submit" variant="danger">
            Delete
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
