import React from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField/index";

export default function Filter(props) {
  const {
    onHide,
    show,
    filterInputsUpdate,
    filterInputs,
    setFilterInputs,
  } = props;

  const onResetFilter = () => {
    setFilterInputs({
      firstName: "",
      lastName: "",
      email: "",
    });
  };

  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
    >
      <Modal.Header closeButton>
        <Modal.Title id="create">BackOffice Users</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12 pl-4 pr-4">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <TextField
                  id="firstName"
                  name="firstName"
                  label="Name"
                  placeholder="Name"
                  className="w-100"
                  value={filterInputs.firstName}
                  onChange={(e) => filterInputsUpdate(e)}
                />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <TextField
                  id="lastName"
                  name="lastName"
                  label="Lastname"
                  placeholder="Lastname"
                  className="w-100"
                  value={filterInputs.lastName}
                  onChange={(e) => filterInputsUpdate(e)}
                />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  placeholder="Email"
                  className="w-100"
                  value={filterInputs.email}
                  onChange={(e) => filterInputsUpdate(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => {
            onResetFilter();
          }}
        >
          Reset
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
