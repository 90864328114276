import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import FormControlLabel from "@material-ui/core/FormControlLabel/index";
import CircularProgress from "@material-ui/core/CircularProgress/index";
import Switch from "@material-ui/core/Switch/index";
import View from "./modals/View";
import Create from "./modals/Create";
import Delete from "./modals/Remove";
import Filter from "./modals/Filter";
import Edit from "./modals/Edit";
import { useGetContributorsQuery } from "../../../redux/api/Contributors/contributorsApi";
import { updateObject } from "../../../Helpers/updateObject";
import ContributorsTable from "./components/ContributorsTable";
import { RenderIf } from "../../components";

export function Contributors() {
  const [showEdit, setShowEdit] = useState(false);
  const [showView, setShowView] = React.useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [sortField, setSortField] = useState("lastUpdatedDate");
  const [orderBy, setOrderBy] = useState(false);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);

  const { user } = useSelector((state) => state.user);
  const permissions = user?.user?.permissions;

  const handleChange = () => {
    setShowFilter(!showFilter);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowView(open);
  };

  function doc_keyUp(e) {
    if (e.ctrlKey && e.key === "x") {
      setShowCreate(true);
    }
  }
  document.addEventListener("keyup", doc_keyUp, false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [contributorId, setContributorId] = useState(null);
  const [deleteUsersIds, setDeleteUsersIds] = useState([]);
  const [filter, setFilter] = useState(false);
  const [filterInputs, setFilterInputs] = useState({
    firstName: "",
  });

  const { data, isLoading, refetch, isSuccess } = useGetContributorsQuery({
    skip: page * rowsPerPage,
    take: rowsPerPage,
    field: "id",
    orderBy: false,
    name: filterInputs,
  });

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  const filterInputsUpdate = (e) => {
    const updateFiltersInput = updateObject(filterInputs, {
      [e.target.name]: e.target.value,
    });
    setFilterInputs(updateFiltersInput);
  };
  const filterStatusesUpdate = (key, value) => {
    const updateFiltersInput = updateObject(filterInputs, {
      [key]: value,
    });

    setFilterInputs(updateFiltersInput);

    setFilter(true);
  };
  // useEffect(() => {
  //     if (isSuccess) {
  //         refetch();
  //     }
  // }, [isSuccess]);

  return (
    <div className="row bg-white rounded d-flex  justify-content-center h-100">
      <div className="col-12 d-flex align-items-center py-4 height-65">
        <h1 className="display-4 mb-0">Contributors</h1>
        <FormControlLabel
          control={
            <Switch
              checked={showFilter}
              onChange={handleChange}
              color="secondary"
            />
          }
          label={showFilter ? "Hide filter" : "Show filter"}
          className="ml-auto mb-0"
        />
      </div>

      {isLoading ? (
        <div>
          <CircularProgress />
        </div>
      ) : data && !isLoading ? (
        <ContributorsTable
          setOpenEdit={setShowEdit}
          setOpenView={toggleDrawer(true)}
          setOpenRemove={setShowRemove}
          setOpenCreate={setShowCreate}
          rows={data.data}
          count={data.totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          permissions={permissions}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          setContributorId={setContributorId}
          setDeleteUsersID={setDeleteUsersIds}
          deleteUsersIds={deleteUsersIds}
          filterInputsUpdate={filterInputsUpdate}
          filterInputs={filterInputs}
          setFilterInputs={setFilterInputs}
          filterStatusesUpdate={filterStatusesUpdate}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : (
        ""
      )}
      <RenderIf condition={showView}>
        <View
          open={showView}
          onClose={toggleDrawer(false)}
          id={contributorId}
        />
      </RenderIf>
      <RenderIf condition={showCreate}>
        <Create
          show={showCreate}
          onHide={setShowCreate}
          refetch={refetch}
          filterInputs={filterInputs}
        />
      </RenderIf>
      <RenderIf condition={showEdit}>
        <Edit
          id={contributorId}
          show={showEdit}
          onHide={setShowEdit}
          refetch={refetch}
          filterInputs={filterInputs}
        />
      </RenderIf>
      <RenderIf condition={showRemove}>
        <Delete
          id={contributorId}
          show={showRemove}
          onHide={setShowRemove}
          refetch={refetch}
          filterInputs={filterInputs}
          skip={skip}
          take={take}
          sortField={sortField}
          orderBy={orderBy}
        />
      </RenderIf>

      <Filter
        show={showFilter}
        onHide={handleChange}
        filterInputsUpdate={filterInputsUpdate}
        filterInputs={filterInputs}
        setFilterInputs={setFilterInputs}
      />
    </div>
  );
}
