/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
// import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { AuthPage } from "./modules/Auth";
import AuthMiddleware from "../Helpers/AuthMiddleware";


export function Routes() {
    const { user } = useSelector((state) => state?.user);

    return (
        <Switch>
            {!user ? (
                <Route>
                    <AuthPage />
                </Route>
            ) : (
                <Redirect from="/auth" to="/dashboard" />
            )}

            <Route path="/error" component={ErrorsPage} />


            {!user ? (
                <Redirect to="/auth/login" />
            ) : (
                <Layout>
                    <BasePage />
                </Layout>
            )}
        </Switch>
    );
}
