import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";

export const suggestApi = createApi({
  reducerPath: "suggestApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getAllSuggest: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, info } = filter;
        const emailParam =
          info && info.email ? `&Email=${encodeURIComponent(info.email)}` : "";
        const textParam =
          info && info.text ? `&Text=${encodeURIComponent(info.text)}` : "";
        return {
          url: `suggestion/filter?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${emailParam}${textParam}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          // dispatch(setInterests(data.data));
        } catch (error) {}
      },
    }),
    getSuggest: builder.query({
      query(id) {
        return {
          url: `suggestion/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    createSuggest: builder.mutation({
      query(form) {
        return {
          url: "suggestion",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    updateSuggest: builder.mutation({
      query(data) {
        return {
          url: "suggestion",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
    }),
    deleteSuggest: builder.mutation({
      query(id) {
        return {
          url: `suggestion`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),

    getAllComplainReason: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, title } = filter;
        return {
          url: `suggestion/filterComplaintReason?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            title ? `&Title=${title.title}` : ""
          }`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          // dispatch(setInterests(data.data));
        } catch (error) {}
      },
    }),
    getAllComplainReasonWithoutFilter: builder.query({
      query(filter) {
        return {
          url: `suggestion/filterComplaintReason`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          // dispatch(setInterests(data.data));
        } catch (error) {}
      },
    }),
    getComplainReason: builder.query({
      query(id) {
        return {
          url: `suggestion/complaintReason/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    createComplainReason: builder.mutation({
      query(form) {
        return {
          url: "suggestion/complaintReason",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    updateComplainReason: builder.mutation({
      query(data) {
        return {
          url: "suggestion/complaintReason",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
    }),
    deleteComplainReason: builder.mutation({
      query(id) {
        return {
          url: `suggestion/complaintReason`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),
  }),
});

export const {
  useCreateComplainReasonMutation,
  useCreateSuggestMutation,
  useDeleteComplainReasonMutation,
  useDeleteSuggestMutation,
  useGetAllComplainReasonQuery,
  useGetAllSuggestQuery,
  useGetComplainReasonQuery,
  useGetSuggestQuery,
  useUpdateComplainReasonMutation,
  useUpdateSuggestMutation,
  useGetAllComplainReasonWithoutFilterQuery,
} = suggestApi;
