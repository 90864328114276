import React, { useState, useEffect } from "react";
import TablePagination from "@material-ui/core/TablePagination";
import CircularProgress from "@material-ui/core/CircularProgress/index";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@material-ui/core/styles/index";
import Drawer from "@material-ui/core/Drawer/index";
import Divider from "@material-ui/core/Divider/index";
import CloseIcon from "@material-ui/icons/Close";
import Member from "../../../media/userIcon.png";
import { Button, RenderIf, SearchInput } from "../../../components";
import {
    useChooseManagerMutation,
    useUpdateEventMemberRoleMutation,
} from "../../../../redux/api/Events/eventsApi";
import { useGetUsersQuery } from "../../../../redux/api/Auth/Users/usersApi";
import { updateObject } from "../../../../Helpers/updateObject";
import "./MemberList.scss";
const { REACT_APP_BASE_PHOTO_URL } = process.env;
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const useStyles = makeStyles({
    list: {
        width: 400,
        height: "100vh",
    },
    card: {
        margin: "30px",
    },
    pointer: {
        cursor: "pointer",
    },
});

export default function MemberList(props) {
    const { onHide } = props;
    const [page, setPage] = React.useState(0);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [deletedUserIds, setDeletedUserIds] = useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [filter, setFilter] = useState(false);

    const [filterInputs, setFilterInputs] = useState({
        firstName: "",
        lastName: "",
        email: "",
    });

    const {
        data: userDetailedInfo,
        isSuccess: detailedInfo,
        isLoading: detailedInfoLoading,
        refetch: refetchDetailedInfo,
    } = useGetUsersQuery({
        skip: page * rowsPerPage,
        take: rowsPerPage,
        field: "id",
        order: false,
        name: filterInputs,
    });

    const [
        updateMember,
        { isLoading: memberLoading, isSuccess: memberSuccess },
    ] = useUpdateEventMemberRoleMutation();
    const [
        chooseManager,
        { isSuccess: chooseSuccess, isLoading: chooseLoading },
    ] = useChooseManagerMutation();
    const classes = useStyles();

    const count = userDetailedInfo?.totalCount;

    useEffect(() => {
        if (chooseSuccess) {
            refetchDetailedInfo();
            onHide();
        }
    }, [chooseSuccess]);
    const onHideModal = () => {
        onHide();
    };
    const handleCheckboxChange = (event, id) => {
        if (event.target.checked) {
            setSelectedUserIds((prevSelectedUserIds) => [
                ...prevSelectedUserIds,
                id,
            ]);
        } else {
            setSelectedUserIds((prevSelectedUserIds) =>
                prevSelectedUserIds.filter((item) => item !== id)
            );
            setDeletedUserIds((prevDeletedUserIds) => [
                ...prevDeletedUserIds,
                id,
            ]);
        }
    };

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }
    const filterInputsUpdate = (e) => {
        const updateFiltersInput = updateObject(filterInputs, {
            ...filterInputs,
            [e.target.name]: e.target.value,
        });

        setFilterInputs(updateFiltersInput);
        setSkip(0);
        setFilter(true);
    };

    const sendManager = () => {
        const formData = new FormData();
        if (selectedUserIds?.length) {
            for (let i = 0; i < selectedUserIds?.length; i++) {
                formData.append("SiteUserIds", selectedUserIds[i]);
            }
            chooseManager(formData);
        }
        if (deletedUserIds?.length) {
            for (let i = 0; i < deletedUserIds?.length; i++) {
                formData.append("DeletedSiteUserIds", deletedUserIds[i]);
            }
            chooseManager(formData);
        }
    };

    useEffect(() => {
        if (detailedInfo) {
            const ids = userDetailedInfo?.data?.reduce((acc, curr) => {
                if (curr?.isEventManager) {
                    acc.push(curr?.id);
                }
                return acc;
            }, []);
            setSelectedUserIds(ids);
        }
    }, [detailedInfo]);
    const sideList = (side) => (
        <div className={classes.list} role="presentation">
            <div className="col-12 d-flex align-items-center justify-content-between py-4">
                <h1 className="h3 mb-0">Choose Event Manager</h1>
                <CloseIcon className={classes.pointer} onClick={onHideModal} />
            </div>
            <Divider className="bg-dark" />

            <SearchInput
                id="firstName"
                name="firstName"
                label="Name "
                placeholder="Name"
                className="w-100"
                value={filterInputs?.firstName}
                setFilterInputs={setFilterInputs}
                filterInputsUpdate={filterInputsUpdate}
            />
            <RenderIf
                condition={userDetailedInfo && !detailedInfoLoading}
                renderElse={<CircularProgress />}
            >
                <div className="drawer-body">
                    <div className="memberList">
                        {userDetailedInfo?.data?.map((c) => (
                            <div key={c?.id} className="memberListItem">
                                <div className="memberListItemInner">
                                    <div className="d-flex">
                                        <div className="memberListItemLeft">
                                            <Checkbox
                                                {...label}
                                                color="success"
                                                checked={selectedUserIds.includes(
                                                    c?.id
                                                )}
                                                onChange={(e) =>
                                                    handleCheckboxChange(
                                                        e,
                                                        c?.id
                                                    )
                                                }
                                            />
                                            <img
                                                alt="user img"
                                                src={
                                                    c?.profilePhotoUrl
                                                        ? `${REACT_APP_BASE_PHOTO_URL}${c?.profilePhotoUrl}`
                                                        : Member
                                                }
                                            />
                                        </div>
                                        <div className="memberListItemRight">
                                            <h4>
                                                {c?.firstName} {c?.lastName}
                                            </h4>
                                            <p>{c?.position}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <RenderIf condition={count > 10}>
                            <TablePagination
                                rowsPerPageOptions={[
                                    5,
                                    10,
                                    25,
                                    { value: count, label: "All" },
                                ]}
                                component="div"
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    "aria-label": "Previous Page",
                                }}
                                nextIconButtonProps={{
                                    "aria-label": "Next Page",
                                }}
                                labelRowsPerPage="Line count:"
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </RenderIf>
                    </div>
                    <div className="d-flex justify-content-center my-3">
                        <Button
                            onClick={sendManager}
                            className="px-9"
                            background="#057953"
                            type="submit"
                            disabled={chooseLoading}
                        >
                            Save
                            <RenderIf condition={chooseLoading}>
                                <span className="ml-3 spinner spinner-white"></span>
                            </RenderIf>
                        </Button>
                    </div>
                </div>
            </RenderIf>
        </div>
    );

    return (
        <Drawer {...props} anchor="right" className="drawer--fixed">
            {sideList("right")}
        </Drawer>
    );
}
