import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import {
  addPermission,
  removePermission,
} from "../../../../redux/features/Role/RoleSlicer";

const Scope = ({
  name,
  label,
  permissonId,
  parameters,
  changeRadioButton,
  resetPermissionId,
  setPerId,
  perId,
  oneChoice,
  setSelect,
  select,
  chooseScope,
  scopeIdx,
}) => {
  const dispatch = useDispatch();
  return (
    // <div
    //   className={`accordion  w-100 mb-2 ${
    //     chooseScope[name] !== undefined &&
    //     !chooseScope[name].valid &&
    //     chooseScope[name].touch
    //       ? "border border-danger rounded"
    //       : ""
    //   }`}
    //   id={permissonId}
    // >
    //   <div className="card">
    //     <div className="card-header">
    //       <div
    //         className="card-title d-flex justify-content-between"
    //         data-toggle={`collapse${permissonId}`}
    //         aria-expanded="false"
    //         data-target={`#collapseOne${permissonId}`}
    //       >
    //         {label}
    //         {parameters.length ? (
    //           <Button
    //             className={`px-9`}
    //             onClick={() => {
    //               dispatch(removePermission({ id: permissonId }));
    //               resetPermissionId(permissonId);
    //             }}
    //           >
    //             Təmizlə
    //           </Button>
    //         ) : (
    //           ""
    //         )}
    //       </div>
    //     </div>
    //     <div
    //       id={`#collapseOne${permissonId}`}
    //       className="show"
    //       data-parent="#accordionExample1"
    //     >
    //       <div className="card-body">
    //         <div className="form-group mb-0">
    //           <div className="checkbox-list">
    //             {parameters.length ? (
    //               parameters.map(
    //                 ({ id, description, value, checked }, parameterIdx) => (
    //                   <label key={id} className="checkbox">
    //                     <input
    //                       id={value}
    //                       type="radio"
    //                       name={name}
    //                       value={id}
    //                       checked={
    //                         select[scopeIdx]?.parameters[parameterIdx]?.checked
    //                       }
    //                       onChange={(e) => {
    //                         changeRadioButton(
    //                           name,
    //                           true,
    //                           true,
    //                           permissonId,
    //                           value,
    //                           id,
    //                           parameterIdx,
    //                           scopeIdx,
    //                           e
    //                         );
    //                         dispatch(
    //                           addPermission({
    //                             id: permissonId,
    //                             value: value,
    //                             scopeId: id,
    //                           })
    //                         );
    //                       }}
    //                     />
    //                     <span></span>
    //                     {value}
    //                   </label>
    //                 )
    //               )
    //             ) : (
    //               <label className="checkbox">
    //                 <input
    //                   id={label}
    //                   type="radio"
    //                   name={label}
    //                   checked={select[scopeIdx].checked}
    //                   onClick={(e) => {
    //                     changeRadioButton(
    //                       label,
    //                       true,
    //                       true,
    //                       permissonId,
    //                       null,
    //                       null,
    //                       null,
    //                       scopeIdx,
    //                       e
    //                     );
    //                     dispatch(
    //                       addPermission({
    //                         id: permissonId,
    //                       })
    //                     );
    //                   }}
    //                 />
    //                 <span></span>
    //                 {label}
    //               </label>
    //             )}
    //             {chooseScope[name] !== undefined &&
    //             !chooseScope[name].valid &&
    //             chooseScope[name].touch ? (
    //               <div className="fv-plugins-message-container">
    //                 <div className="fv-help-block">
    //                   {chooseScope[name].message}
    //                 </div>
    //               </div>
    //             ) : null}
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className={`card`}>
      <div className="d-flex cardInner align-items-center justify-content-between">
        <div className="d-flex  align-items-center">
          <div className="form-group mb-0">
            <div className="checkbox-list">
              {parameters.length ? (
                parameters.map(
                  ({ id, description, value, checked }, parameterIdx) => (
                    <>
                      <label key={id} className="checkbox">
                        <input
                          id={value}
                          type="radio"
                          name={name}
                          value={id}
                          checked={
                            select[scopeIdx]?.parameters[parameterIdx]?.checked
                          }
                          onChange={(e) => {
                            changeRadioButton(
                              name,
                              true,
                              true,
                              permissonId,
                              value,
                              id,
                              parameterIdx,
                              scopeIdx,
                              e
                            );
                            dispatch(
                              addPermission({
                                id: permissonId,
                                value: value,
                                scopeId: id,
                              })
                            );
                          }}
                        />
                        <span></span>
                      </label>
                    </>
                  )
                )
              ) : (
                <label className="checkbox">
                  <input
                    id={label}
                    type="radio"
                    name={label}
                    checked={select[scopeIdx].checked}
                    onClick={(e) => {
                      changeRadioButton(
                        label,
                        true,
                        true,
                        permissonId,
                        null,
                        null,
                        null,
                        scopeIdx,
                        e
                      );
                      dispatch(
                        addPermission({
                          id: permissonId,
                        })
                      );
                    }}
                  />
                  <span></span>
                  {label}
                </label>
              )}
              {chooseScope[name] !== undefined &&
              !chooseScope[name].valid &&
              chooseScope[name].touch ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {chooseScope[name].message}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div>{label}</div>
        </div>
        <Button
          className={`px-9`}
          onClick={() => {
            dispatch(removePermission({ id: permissonId }));
            resetPermissionId(permissonId);
          }}
        >
          Clear
        </Button>
      </div>
    </div>
  );
};

export default Scope;
