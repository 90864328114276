import React, { useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import InputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import * as Yup from "yup";
import { RenderIf } from "../../../components";
import {
  useGetContactQuery,
  useUpdateContactMutation,
} from "../../../../redux/api/Contact/contactApi";

export default function Edit(props) {
  const { onHide, show, refetch, id } = props;
  const data = useGetContactQuery(id);
  const datas = data?.currentData;

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [
    updateContact,
    { isLoading, error, isSuccess },
  ] = useUpdateContactMutation();

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
    }
  }, [isLoading]);

  const form = useRef(null);

  const EditSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    location: Yup.string().required("LOcation is required"),
    addressLongtitude: Yup.string().required("Longtitude is required"),
    addressLatitude: Yup.string().required("Latitude is required"),
    fax: Yup.string().required("Fax is required"),
    email: Yup.string()
      .email("Wrong email format. E.g. jhon.doe@example.com")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    // phone: Yup.string().required(
    //     "Phone is required"
    // ),
  });

  const getInputClasses = (touched, errors, FieldName) => {
    if (touched[FieldName] && errors[FieldName]) {
      return true;
    }
    if (touched[FieldName] && errors[FieldName]) {
      return false;
    }

    return false;
  };

  const onPutRegionalMunicipality = (values, resetForm, setSubmitting) => {
    const contactInfo = new FormData(form.current);
    contactInfo.append("Id", id);

    updateContact(contactInfo);
  };

  return (
    <Modal
      size="xl"
      onHide={onHide}
      show={show}
      aria-labelledby="edit"
      className="modal-center pr-0"
      centered
    >
      <RenderIf
        condition={
          datas &&
          Object.keys(datas).length !== 0 &&
          datas.constructor === Object
        }
      >
        <Formik
          initialValues={{
            title: datas?.title,
            email: datas?.email,
            fax: datas?.fax,
            location: datas?.location,
            phone: datas?.phone,
            addressLongtitude: datas?.addressLongtitude,
            addressLatitude: datas?.addressLatitude,
          }}
          validationSchema={EditSchema}
          onSubmit={(values, { resetForm, setSubmitting }) => {
            onPutRegionalMunicipality(values, resetForm, setSubmitting);
          }}
        >
          {({
            values,
            errors,
            touched,
            setValues,
            setFieldValue,
            handleSubmit,
            handleChange,
            handleBlur,
            resetForm,
          }) => (
            <>
              <form onSubmit={handleSubmit} ref={form}>
                <Modal.Header closeButton>
                  <Modal.Title id="edit">Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body id="userModal">
                  <div className="col-12 pl-4 pr-4">
                    <div className="row">
                      <div className="col-12 col-md-12 col-lg-6">
                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                          <TextField
                            id="title"
                            name="title"
                            label="Name"
                            placeholder="Name"
                            className="w-100"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={getInputClasses(touched, errors, "title")}
                            onKeyDown={handleEnter}
                          />
                        </div>
                        <RenderIf condition={touched.title && errors.title}>
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.title}</div>
                          </div>
                        </RenderIf>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6">
                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                          <TextField
                            id="email"
                            name="email"
                            label="Email"
                            placeholder="Email"
                            className="w-100"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={getInputClasses(touched, errors, "email")}
                            onKeyDown={handleEnter}
                          />
                        </div>
                        <RenderIf condition={touched.email && errors.email}>
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.email}</div>
                          </div>
                        </RenderIf>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6">
                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                          <TextField
                            id="location"
                            name="location"
                            label="Location"
                            placeholder="Location"
                            className="w-100"
                            value={values.location}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={getInputClasses(touched, errors, "location")}
                            onKeyDown={handleEnter}
                          />
                        </div>
                        <RenderIf
                          condition={touched.location && errors.location}
                        >
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {errors.location}
                            </div>
                          </div>
                        </RenderIf>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6">
                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                          <TextField
                            id="fax"
                            name="fax"
                            label="Fax"
                            placeholder="Fax"
                            className="w-100"
                            value={values.fax}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={getInputClasses(touched, errors, "fax")}
                            onKeyDown={handleEnter}
                          />
                        </div>
                        <RenderIf condition={touched.fax && errors.fax}>
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.fax}</div>
                          </div>
                        </RenderIf>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6">
                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                          <InputMask
                            mask="+994 (99) 999 99 99"
                            // error={getInputClasses(
                            //     touched,
                            //     errors,
                            //     "phone"
                            // )}
                            disabled={false}
                            maskChar=""
                            id="phoneNumber"
                            type="number"
                            defaultValue={values.phone}
                          >
                            {() => (
                              <TextField
                                name="phone"
                                label="Phone"
                                placeholder="Phone"
                                className="w-100"
                                defaultValue={values.phone}
                                // error={getInputClasses(
                                //     touched,
                                //     errors,
                                //     "phone"
                                // )}
                              />
                            )}
                          </InputMask>
                        </div>
                        <RenderIf condition={touched.phone && errors.phone}>
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.phone}</div>
                          </div>
                        </RenderIf>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6">
                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                          <TextField
                            id="addressLatitude"
                            name="addressLatitude"
                            label="AddressLatitude"
                            placeholder="AddressLatitude"
                            className="w-100"
                            value={values.addressLatitude}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={getInputClasses(
                              touched,
                              errors,
                              "addressLatitude"
                            )}
                            onKeyDown={handleEnter}
                          />
                        </div>
                        <RenderIf
                          condition={
                            touched.addressLatitude && errors.addressLatitude
                          }
                        >
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {errors.addressLatitude}
                            </div>
                          </div>
                        </RenderIf>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6">
                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                          <TextField
                            id="addressLongtitude"
                            name="addressLongtitude"
                            label="AddressLongtitude"
                            placeholder="AddressLongtitude"
                            className="w-100"
                            value={values.addressLongtitude}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={getInputClasses(
                              touched,
                              errors,
                              "addressLongtitude"
                            )}
                            onKeyDown={handleEnter}
                          />
                        </div>
                        <RenderIf
                          condition={
                            touched.addressLongtitude &&
                            errors.addressLongtitude
                          }
                        >
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {errors.addressLongtitude}
                            </div>
                          </div>
                        </RenderIf>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="light"
                    onClick={() => {
                      onHide(false);
                      resetForm();
                    }}
                  >
                    Close
                  </Button>
                  <Button type="submit" disabled={isLoading} className={`px-9`}>
                    Save
                    <RenderIf condition={isLoading}>
                      <span className="ml-3 spinner spinner-white"></span>
                    </RenderIf>
                  </Button>
                </Modal.Footer>
              </form>
            </>
          )}
        </Formik>
      </RenderIf>
    </Modal>
  );
}
