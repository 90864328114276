import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../../AxiosBase";
import { setPermissions, setUsers } from "../../../features/Users/usersSlice";

export const backofficeUsersApi = createApi({
  reducerPath: "backofficeUsersApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getBackOfficeUsers: builder.query({
      query(data) {
        const { skip, take, field, order, name } = data;
        return {
          url: `backoffices/filter?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${order}&FirstName=${name?.firstName}&LastName=${name?.lastName}&Email=${name?.email}`,
        };
      },

      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUsers(data.data));
        } catch (error) {}
      },
    }),
    getBackOfficeUsersPermissions: builder.query({
      query(data) {
        return {
          url: `backoffices/permissions`,
        };
      },
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(setPermissions(data));
        } catch (error) {}
      },
    }),
    getChooseBackOfficeUser: builder.query({
      query(id) {
        return {
          url: `backoffices/byId?id=${id}`,
        };
      },
      transformResponse: (result) => result,
    }),
    updateBackOfficeUsers: builder.mutation({
      query(data) {
        return {
          url: "backoffices/update",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
    }),
    updateBackOfficeUserPassword: builder.mutation({
      query(data) {
        return {
          url: "backoffices/changePassword",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
    }),
    updateBackOfficeStatus: builder.mutation({
      query(data) {
        return {
          url: "backoffices/updateStatus",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
    }),
    updatePassword: builder.mutation({
      query(data) {
        return {
          url: "backoffices/updatePassword",
          method: "PUT",
          data: data,
        };
      },

      transformResponse: (result) => result,
    }),
    createBackOfficeUsers: builder.mutation({
      query(form) {
        return {
          url: "backoffices/register",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    deleteBackOfficeUsers: builder.mutation({
      query(id) {
        return {
          url: `backoffices`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),
  }),
});

export const {
  useDeleteBackOfficeUsersMutation,
  useCreateBackOfficeUsersMutation,
  useGetChooseBackOfficeUserQuery,
  useGetBackOfficeUsersQuery,
  useUpdateBackOfficeUserPasswordMutation,
  useUpdatePasswordMutation,
  useUpdateBackOfficeUsersMutation,
  useUpdateBackOfficeStatusMutation,
  useGetBackOfficeUsersPermissionsQuery,
} = backofficeUsersApi;
