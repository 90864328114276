import React from "react";
import { useDispatch } from "react-redux";
import {
  addPermission,
  removePermission,
} from "../../../../redux/features/Role/RoleSlicer";

const Scope = ({
  name,
  label,
  permissonId,
  parameters,
  changeRadioButton,
  chooseScope,
  permissions,
  setSelectedRadioButtons,
  categoryIndex,
}) => {
  const dispatch = useDispatch();

  return (
    <div className={`card ${categoryIndex !== 0 ? "mt-2 mx-2" : ""}`}>
      <div className="d-flex cardInner">
        <div className="form-group mb-0">
          <div className="checkbox-list">
            <label className="checkbox">
              <input
                type="checkbox"
                name={name}
                onChange={(e) => {
                  if (e.target.checked) {
                    dispatch(
                      addPermission({
                        id: permissonId,
                        value: "All",
                        scopeId: permissonId,
                      })
                    );
                  } else {
                    dispatch(
                      removePermission({
                        id: permissonId,
                        scopeId: permissonId,
                      })
                    );
                  }
                  changeRadioButton(name, true, true, permissonId);
                }}
              />
              <span></span>
            </label>
          </div>
        </div>
        <div>{label}</div>
      </div>
    </div>
  );
};

export default Scope;
