import { createApi } from "@reduxjs/toolkit/query/react";
// import { setInterests } from "../../features/Interests/interestsSlice";
import { APIBaseQuery } from "../AxiosBase";

export const contentApi = createApi({
  reducerPath: "contentApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getContents: builder.query({
      query(filter) {
        const { contentType } = filter;
        return {
          url: `content/filter?ContentType=${contentType}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          // dispatch(setInterests(data.data));
        } catch (error) {}
      },
    }),
    getContent: builder.query({
      query(id) {
        return {
          url: `content/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    createContent: builder.mutation({
      query(form) {
        return {
          url: "content",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    updateContent: builder.mutation({
      query(data) {
        return {
          url: "content",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
    }),
    deleteContent: builder.mutation({
      query(id) {
        return {
          url: `content`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),
  }),
});

export const {
  useCreateContentMutation,
  useDeleteContentMutation,
  useGetContentQuery,
  useGetContentsQuery,
  useUpdateContentMutation,
} = contentApi;
