import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField/index";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useCreateRolesMutation } from "../../../../redux/api/Roles/rolesApi";
import { resetPermission } from "../../../../redux/features/Role/RoleSlicer";
import { useGetBackOfficeUsersPermissionsQuery } from "../../../../redux/api/Auth/BackOfficeUsers/backofficeUsersApi";
import RenderIf from "../../../components/RenderIf";
import Scope from "../components/ScopeCreate";

const initialValues = {
  name: "",
  permissions: [],
};
export default function Create(props) {
  const { show, onHide, refetch } = props;
  const [selectedRadioButtons, setSelectedRadioButtons] = useState({});

  const dispatch = useDispatch();
  const [chooseScope, setChooseScope] = useState({});
  const { permissions } = useSelector((state) => state.usersState);

  const [
    createRoles,
    { error, isSuccess, isLoading },
  ] = useCreateRolesMutation();
  const { Pdata } = useGetBackOfficeUsersPermissionsQuery();

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
    }
  }, [isLoading]);

  const CreateSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  const { permissions2 } = useSelector((state) => state.rolesSlicer);

  const getInputClasses = (filedName) => {
    if (formik.touched[filedName] && formik.errors[filedName]) {
      return true;
    }
    if (formik.touched[filedName] && !formik.errors[filedName]) {
      return false;
    }

    return false;
  };
  const onPostHumanResource = (values, resetForm, setSubmitting) => {
    const humanResource = {
      name: values.name,
      permissons: permissions2,
    };

    createRoles(humanResource);
    dispatch(resetPermission());
  };

  const changeRadioButton = (
    name,
    valid,
    touch,
    permissonId,
    value = null,
    scopeId = null
  ) => {
    const newScope = {
      ...chooseScope,
      [name]: {
        valid: valid,
        touch: touch,
        message: "Select the permission type",
        scope: {
          id: permissonId,
          scopeId: scopeId,
          value: value,
        },
      },
    };

    setChooseScope(newScope);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      onPostHumanResource(values, resetForm, setSubmitting);
    },
  });

  const resetForm = () => {
    onHide();
    dispatch(resetPermission());
    formik.resetForm();
  };
  return (
    <Modal show={show} onHide={resetForm} aria-labelledby="edit" centered>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="edit">Add</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="name"
                    label="Ad"
                    placeholder="Ad"
                    className="w-100"
                    error={getInputClasses("name")}
                    {...formik.getFieldProps("name")}
                  />
                </div>
                <RenderIf condition={formik.touched.name && formik.errors.name}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                </RenderIf>
              </div>

              <div className="col-12 col-md-12 col-lg-12">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  {permissions
                    ?.reduce((groups, { id, name, parameters }, index) => {
                      const permissonId = id;
                      const name2 = `Checkboxes[${permissonId}]`;
                      const categoryIndex = Math.floor(index / 4);
                      const categoryNames = [
                        "Xəbərlər",
                        "Fəaliyyət",
                        "Haqqında",
                        "Kontakt",
                        "Discussion",
                        "Community",
                        "Tədbir",
                        "Kitabxana",
                        "Rol",
                        "İstifadəçi",
                      ];
                      if (!groups[categoryIndex]) {
                        groups[categoryIndex] = {
                          name: categoryNames[categoryIndex],
                          permissions: [],
                        };
                      }
                      groups[categoryIndex].permissions.push({
                        id,
                        name,
                        parameters,
                        name2,
                        permissonId,
                      });

                      return groups;
                    }, [])
                    .map((category, categoryIndex) => (
                      <div key={categoryIndex} className="mb-4">
                        <div className="font-weight-bold">{category.name}</div>
                        {category.permissions.map(
                          ({ id, name, parameters, name2, permissonId }) => (
                            <Scope
                              permissions={permissions}
                              key={id}
                              name={name2}
                              permissonId={permissonId}
                              changeRadioButton={changeRadioButton}
                              parameters={parameters}
                              label={name}
                              chooseScope={chooseScope}
                            />
                          )
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              dispatch(resetPermission());
              formik.resetForm();
            }}
          >
            Close
          </Button>
          <Button type="submit" className={isLoading ? "px-9" : ""}>
            <span>Add</span>
            <RenderIf condition={isLoading}>
              <span className="ml-3 spinner spinner-white"></span>
            </RenderIf>
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
