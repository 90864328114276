import { createApi } from "@reduxjs/toolkit/query/react";
import { setInterests } from "../../features/Interests/interestsSlice";
import { APIBaseQuery } from "../AxiosBase";

export const valueAndEthicApi = createApi({
  reducerPath: "valueAndEthicApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getAllValue: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, name } = filter;
        return {
          url: `ValueAndEthic/value/filter?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            name ? `&Name=${name.name}` : ""
          }`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          // dispatch(setInterests(data.data));
        } catch (error) {}
      },
    }),
    getValue: builder.query({
      query(id) {
        return {
          url: `ValueAndEthic/value/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    createValue: builder.mutation({
      query(form) {
        return {
          url: "ValueAndEthic/value",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    updateValue: builder.mutation({
      query(data) {
        return {
          url: "ValueAndEthic/value",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
    }),
    deleteValue: builder.mutation({
      query(id) {
        return {
          url: `ValueAndEthic/value`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),

    getAllEthic: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, name } = filter;
        return {
          url: `ValueAndEthic/ethic/filter?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            name ? `&Name=${name.name}` : ""
          }`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          // dispatch(setInterests(data.data));
        } catch (error) {}
      },
    }),
    getEthic: builder.query({
      query(id) {
        return {
          url: `ValueAndEthic/ethic/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    createEthic: builder.mutation({
      query(data) {
        return {
          url: "ValueAndEthic/ethic",
          method: "POST",
          data: data,
        };
      },
      transformResponse: (result) => result,
    }),
    updateEthic: builder.mutation({
      query(data) {
        return {
          url: "ValueAndEthic/ethic",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
    }),
    deleteEthic: builder.mutation({
      query(id) {
        return {
          url: `ValueAndEthic/ethic`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),
  }),
});

export const {
  useCreateEthicMutation,
  useCreateValueMutation,
  useDeleteEthicMutation,
  useDeleteValueMutation,
  useGetAllEthicQuery,
  useGetAllValueQuery,
  useGetEthicQuery,
  useGetValueQuery,
  useUpdateEthicMutation,
  useUpdateValueMutation,
} = valueAndEthicApi;
