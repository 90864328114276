import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactSelect from "../../UI/ReactSelect";
import { useCreateBackOfficeUsersMutation } from "../../../../redux/api/Auth/BackOfficeUsers/backofficeUsersApi";
import { RenderIf } from "../../../components";

const initialValues = {
  userName: "",
  firstName: "",
  lastName: "",
  email: "",
  roleId: "",
  password: "",
};

export default function Create(props) {
  const { show, onHide, refetch, roles } = props;
  const [disabled, setDisabled] = useState(false);
  const [defaultState, setDefaultState] = useState(false);
  const form = useRef(null);
  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
    showPassword: false,
    showRePassword: false,
  });
  const handleClickShowPassword = (key) => {
    setValues({ ...values, [key]: !values[key] });
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [roleOption, setRoleOption] = React.useState(null);
  const [rolesArray, setRolesArray] = React.useState([]);

  const [
    createUsers,
    { isLoading, error, isSuccess },
  ] = useCreateBackOfficeUsersMutation();

  function handleChangeRole(value) {
    setRoleOption(value);
    formik.setFieldValue("roleId", value.value);
  }

  const rolesOptions = roles.map((suggestion) => ({
    value: suggestion.id,
    label: suggestion.name,
  }));

  function handleRoleFocus() {
    setTimeout(() => {
      document.getElementById("userModal").scrollTop = document
        .getElementById("roleId")
        .getBoundingClientRect().top;
    });
  }

  const CreateSchema = Yup.object().shape({
    userName: Yup.string().required("UserName is required"),
    firstName: Yup.string().required("Name is required."),
    lastName: Yup.string().required("LastName is required"),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        "Password must consist of an uppercase letter, a lowercase letter, a number and a character."
      )
      .required("Password is required"),
    email: Yup.string()
      .email("Wrong email format. E.g. jhon.doe@example.com")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    // phone: Yup.string().required(
    //     "Phone is required"
    // ),
    roleId: Yup.string().required("Role is required"),
  });

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }

    return false;
  };

  const onPostUser = (values, resetForm, setSubmitting) => {
    const humanResource = {
      ...values,
      roleId: values.roleId,
    };

    createUsers(humanResource);

    resetForm();
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      onPostUser(values, resetForm, setSubmitting);
    },
  });
  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
    }
  }, [isLoading]);

  useEffect(() => {
    if (defaultState) {
      setRoleOption(null);
      setDefaultState(false);
    }
  }, [defaultState]);

  return (
    <Modal
      size="xl"
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
      show={show}
      onHide={onHide}
    >
      <form autocomplete="off" onSubmit={formik.handleSubmit} ref={form}>
        <Modal.Header closeButton>
          <Modal.Title id="create">Add</Modal.Title>
        </Modal.Header>
        <Modal.Body id="userModal">
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="userName"
                    label="User Name"
                    placeholder="Username"
                    autocomplete="off"
                    className="w-100"
                    error={getInputClasses("userName")}
                    {...formik.getFieldProps("userName")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={formik.touched.userName && formik.errors.userName}
                  renderElse={null}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.userName}
                    </div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="firstName"
                    name="firstName"
                    label="Name"
                    placeholder="Name"
                    className="w-100"
                    error={getInputClasses("firstName")}
                    {...formik.getFieldProps("firstName")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  renderElse={null}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.firstName}
                    </div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="lastName"
                    label="Lastname"
                    placeholder="Lastname"
                    className="w-100"
                    error={getInputClasses("lastName")}
                    {...formik.getFieldProps("lastName")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={formik.touched.lastName && formik.errors.lastName}
                  renderElse={null}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.lastName}
                    </div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="email"
                    label="Email"
                    placeholder="Email"
                    className="w-100"
                    error={getInputClasses("email")}
                    {...formik.getFieldProps("email")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={formik.touched.email && formik.errors.email}
                  renderElse={null}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                </RenderIf>
              </div>

              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <ReactSelect
                    // isMulti={true}
                    suggestions={rolesOptions}
                    handleChangeSingle={(value) => handleChangeRole(value)}
                    single={roleOption}
                    label="Role"
                    placeholder="Role"
                    inputId="roleId"
                    onFocus={handleRoleFocus}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={formik.touched.roleId && formik.errors.roleId}
                  renderElse={null}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.roleId}</div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="password"
                    className="w-100"
                    type={values.showPassword ? "text" : "password"}
                    label="Password"
                    error={getInputClasses("password")}
                    {...formik.getFieldProps("password")}
                    onKeyDown={handleEnter}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={() =>
                              handleClickShowPassword("showPassword")
                            }
                          >
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <RenderIf
                  condition={formik.touched.password && formik.errors.password}
                  renderElse={null}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.password}
                    </div>
                  </div>
                </RenderIf>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
              setDefaultState(true);
            }}
          >
            Close
          </Button>
          <Button type="submit" disabled={isLoading} className={`px-9`}>
            Add
            <RenderIf condition={isLoading}>
              <span className="ml-3 spinner spinner-white"></span>
            </RenderIf>
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
