import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  useGetChooseBackOfficeUserQuery,
  useUpdateBackOfficeUsersMutation,
} from "../../../../redux/api/Auth/BackOfficeUsers/backofficeUsersApi";
import { RenderIf } from "../../../components";
import ReactSelect from "../../../../app/modules/UI/ReactSelect";

export default function Edit(props) {
  const { onHide, show, roles, refetch, id } = props;

  const { data } = useGetChooseBackOfficeUserQuery(id);

  const [disabled, setDisabled] = useState(false);
  const [pvalues, setPValues] = useState({
    password: "",
    showPassword: false,
  });
  const handleClickShowPassword = (key) => {
    setPValues({ ...pvalues, [key]: !pvalues[key] });
  };
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [roleOption, setRoleOption] = React.useState(null);
  const [rolesOptions, setRolesOptions] = React.useState([]);

  function handleChangeRole(value, setFieldValue) {
    setRoleOption((prevState) => value);
    setFieldValue("roleId", value.value);
  }

  useEffect(() => {
    setRolesOptions(
      roles.map((suggestion) => ({
        value: suggestion.id,
        label: suggestion.name,
      }))
    );
  }, [roles]);

  useEffect(() => {
    setRoleOption((prev) =>
      rolesOptions.find((option) => option.value === data?.roleId)
    );
  }, [data?.roleId, rolesOptions]);

  useEffect(() => {
    if (!show) {
      setRoleOption(null);
    }
  }, [show]);

  const [
    updateUsers,
    { isLoading, error, isSuccess, isFetching },
  ] = useUpdateBackOfficeUsersMutation();

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
    }
  }, [isLoading]);

  const onPutUser = (values, resetForm, setSubmitting) => {
    const userUpdate = {
      id: id,
      userName: values.userName,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      roleId: values.roleId,
    };
    updateUsers(userUpdate);
  };

  const EditSchema = Yup.object().shape({
    userName: Yup.string().required("UserName is required"),
    firstName: Yup.string().required("Name is required."),
    lastName: Yup.string().required("LastName is required"),

    email: Yup.string()
      .email("Wrong email format. E.g. jhon.doe@example.com")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    // phone: Yup.string().required(
    //     "Phone is required"
    // ),
    //   // roleId: Yup.string().required(
    //   //   "Role is required"
    //   // ),
  });

  const getInputClasses = (touched, errors, FieldName) => {
    if (touched[FieldName] && errors[FieldName]) {
      return true;
    }
    if (touched[FieldName] && errors[FieldName]) {
      return false;
    }

    return false;
  };

  return (
    <Modal
      size="xl"
      onHide={onHide}
      show={show}
      aria-labelledby="edit"
      className="modal-center pr-0"
      centered
    >
      <RenderIf
        condition={
          data && Object.keys(data).length !== 0 && data.constructor === Object
        }
      >
        <Formik
          initialValues={{
            id: data?.firstName,
            lastName: data?.lastName,
            firstName: data?.firstName,
            email: data?.email,
            userName: data?.userName,
            roleId: data?.roleId,
          }}
          validationSchema={EditSchema}
          onSubmit={(values, { resetForm, setSubmitting }) => {
            onPutUser(values, resetForm, setSubmitting);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            handleBlur,
            resetForm,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title id="edit">Edit</Modal.Title>
              </Modal.Header>
              <Modal.Body id="userModal">
                <div className="col-12 pl-4 pr-4">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-6">
                      <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                        <TextField
                          id="userName"
                          name="userName"
                          label="UserName"
                          placeholder="UserName"
                          className="w-100"
                          value={values.userName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getInputClasses(touched, errors, "userName")}
                          onKeyDown={handleEnter}
                        />
                      </div>
                      <RenderIf
                        condition={touched.userName && errors.userName}
                        renderElse={null}
                      >
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.userName}</div>
                        </div>
                      </RenderIf>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6">
                      <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                        <TextField
                          id="firstName"
                          name="firstName"
                          label="Name"
                          placeholder="Name"
                          className="w-100"
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getInputClasses(touched, errors, "firstName")}
                          onKeyDown={handleEnter}
                        />
                      </div>
                      <RenderIf
                        condition={touched.firstName && errors.firstName}
                        renderElse={null}
                      >
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.firstName}
                          </div>
                        </div>
                      </RenderIf>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6">
                      <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                        <TextField
                          id="lastName"
                          name="lastName"
                          label="Lastname"
                          placeholder="Lastname"
                          className="w-100"
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getInputClasses(touched, errors, "lastName")}
                          onKeyDown={handleEnter}
                        />
                      </div>
                      <RenderIf
                        condition={touched.lastName && errors.lastName}
                        renderElse={null}
                      >
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.lastName}</div>
                        </div>
                      </RenderIf>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6">
                      <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                        <TextField
                          id="email"
                          name="email"
                          label="Email"
                          placeholder="Email"
                          className="w-100"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getInputClasses(touched, errors, "email")}
                          onKeyDown={handleEnter}
                        />
                      </div>
                      <RenderIf
                        condition={touched.email && errors.email}
                        renderElse={null}
                      >
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.email}</div>
                        </div>
                      </RenderIf>
                    </div>

                    <div className="col-12 col-md-12 col-lg-6">
                      <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                        <ReactSelect
                          // isMulti={true}
                          suggestions={rolesOptions}
                          handleChangeSingle={(value) =>
                            handleChangeRole(value, setFieldValue)
                          }
                          single={roleOption}
                          label="Role"
                          placeholder="Role"
                          inputId="roleId"
                          // onFocus={handleRoleFocus}
                          onKeyDown={handleEnter}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="light"
                  onClick={() => {
                    onHide(false);
                    resetForm();
                  }}
                >
                  Close
                </Button>
                <Button type="submit" disabled={isLoading} className={`px-9`}>
                  Save
                  <RenderIf condition={isLoading}>
                    <span className="ml-3 spinner spinner-white"></span>
                  </RenderIf>
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </RenderIf>
    </Modal>
  );
}
