import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";

export const reviewAndStoryApi = createApi({
  reducerPath: "reviewAndStoryApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getAllReview: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, name } = filter;
        return {
          url: `reviewAndStory/review/filter?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            name ? `&Name=${name.firstName}` : ""
          }`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          // dispatch(setInterests(data.data));
        } catch (error) {}
      },
    }),
    getReview: builder.query({
      query(id) {
        return {
          url: `reviewAndStory/review/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    createReview: builder.mutation({
      query(form) {
        return {
          url: "reviewAndStory/review",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    updateReview: builder.mutation({
      query(data) {
        return {
          url: "reviewAndStory/review",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
    }),
    deleteReview: builder.mutation({
      query(id) {
        return {
          url: `reviewAndStory/review`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),

    getAllStory: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, name } = filter;
        return {
          url: `reviewAndStory/story/filter?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            name ? `&Name=${name.firstName}` : ""
          }`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          // dispatch(setInterests(data.data));
        } catch (error) {}
      },
    }),
    getStory: builder.query({
      query(id) {
        return {
          url: `reviewAndStory/story/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    createStory: builder.mutation({
      query(form) {
        return {
          url: "reviewAndStory/story",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    updateStory: builder.mutation({
      query(data) {
        return {
          url: "reviewAndStory/story",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
    }),
    deleteStory: builder.mutation({
      query(id) {
        return {
          url: `reviewAndStory/story`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),
  }),
});

export const {
  useCreateReviewMutation,
  useCreateStoryMutation,
  useDeleteReviewMutation,
  useDeleteStoryMutation,
  useGetAllReviewQuery,
  useGetAllStoryQuery,
  useGetReviewQuery,
  useGetStoryQuery,
  useUpdateStoryMutation,
  useUpdateReviewMutation,
} = reviewAndStoryApi;
