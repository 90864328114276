import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RenderIf } from "../../../components";
import { useCreateLinkMutation } from "../../../../redux/api/AdditionalLinks/additionalLinks";

export default function Create(props) {
  const { show, onHide, refetch } = props;
  const [isError, setIsError] = useState("");
  const [defaultState, setDefaultState] = useState(false);
  const [file, setFile] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [preview, setPreview] = useState(false);

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };
  const imagePreview = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    setFile(file);
    formik.setFieldValue("file", file);

    reader.onloadend = () => {
      setImageSrc(reader.result);
    };

    reader.readAsDataURL(file);

    setPreview(true);
  };

  const onClick = (e) => {
    setPreview(false);
    setImageSrc("");
    setFile("");
  };
  const initialValues = {
    firstName: "",
    lastName: "",
    file: "",
    position: "",
    description: "",
  };
  const [createLink, { isLoading, isSuccess }] = useCreateLinkMutation();

  const CreateSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    file: Yup.string().required("File is required"),
    url: Yup.string().required("Url is required"),
  });

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }

    return false;
  };

  const form = useRef();
  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values) => {
      const formData = new FormData(form.current);

      formData.append("name", String(values.name));
      formData.append("url", String(values.url));
      formData.append("file", file);
      createLink(formData);
    },
  });

  const { resetForm } = formik;
  const onHideModal = () => {
    onHide();
    resetForm();
    setPreview(false);
    setImageSrc("");
    setFile("");
  };

  useEffect(() => {
    setIsError("");
  }, [show]);

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
      resetForm();
    }
  }, [isLoading]);
  return (
    <Modal
      size="xl"
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
      show={show}
      onHide={onHideModal}
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="create">Add</Modal.Title>
        </Modal.Header>
        <Modal.Body id="userModal">
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="name"
                    name="name"
                    label="Name"
                    placeholder="Name"
                    className="w-100"
                    error={getInputClasses("name")}
                    {...formik.getFieldProps("name")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf condition={formik.errors.name}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                </RenderIf>
              </div>

              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="url"
                    name="url"
                    label="Url"
                    placeholder="Url"
                    className="w-100"
                    error={getInputClasses("url")}
                    {...formik.getFieldProps("url")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf condition={formik.errors.url}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.url}</div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6 mt-3">
                <input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  name="file"
                  label="File"
                  placeholder="File"
                  className="w-100"
                  onChange={imagePreview}
                />
                <RenderIf condition={formik.touched.file && formik.errors.file}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.file}</div>
                  </div>
                </RenderIf>

                <RenderIf condition={imageSrc}>
                  <div className="image-preview mt-4">
                    <div
                      style={{
                        position: "relative",
                        width: "100px",
                        height: "100px",
                      }}
                    >
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          borderRadius: "100%",
                        }}
                        src={imageSrc}
                        alt="img"
                      />
                      <button
                        style={{
                          position: "absolute",
                          right: "-30px",
                        }}
                        className="BtnSvg"
                        onClick={onClick}
                      >
                        <i
                          style={{ fontSize: "10px" }}
                          className="flaticon2-delete"
                        ></i>
                      </button>
                    </div>
                  </div>
                </RenderIf>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
              setDefaultState(true);
            }}
          >
            Close
          </Button>
          <Button type="submit" disabled={isLoading} className={`px-9`}>
            Add
            <RenderIf condition={isLoading}>
              <span className="ml-3 spinner spinner-white"></span>
            </RenderIf>
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
