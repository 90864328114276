import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import * as Yup from "yup";
import { RenderIf, RichTextEditor } from "../../../../components";
import {
  useGetContentQuery,
  useUpdateContentMutation,
} from "../../../../../redux/api/Content/contentApi";

export default function Edit(props) {
  const { onHide, show, refetch, id } = props;

  const data = useGetContentQuery(id);
  const datas = data?.data;

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [
    updateTeamContent,
    { isLoading, error, isSuccess },
  ] = useUpdateContentMutation();

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
    }
  }, [isLoading]);

  const form = useRef(null);

  const EditSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string()
      .required("Description is required")
      .test("rich-text-not-empty", "Description cannot be empty", (value) => {
        if (typeof value !== "string") return false;
        const trimmedValue = value.trim();
        return trimmedValue !== "<p><br></p>" && trimmedValue !== "";
      }),
  });

  const getInputClasses = (touched, errors, FieldName) => {
    if (touched[FieldName] && errors[FieldName]) {
      return true;
    }
    if (touched[FieldName] && errors[FieldName]) {
      return false;
    }

    return false;
  };
  const [content, setContent] = useState();

  const onPutTeamContent = (values, resetForm, setSubmitting) => {
    const contentData = {
      id: id,
      title: values.title,
      description: content,
      contentType: 18,
    };

    updateTeamContent(contentData);
  };

  useEffect(() => {
    if (data) {
      setContent(datas?.description);
    }
  }, [data, id]);
  return (
    <Modal
      size="xl"
      onHide={onHide}
      show={show}
      aria-labelledby="edit"
      className="modal-center pr-0"
      centered
    >
      <RenderIf
        condition={
          Object.keys(datas || {}).length && datas?.constructor === Object
        }
      >
        <Formik
          initialValues={{
            description: datas?.description,
            title: datas?.title,
          }}
          validationSchema={EditSchema}
          onSubmit={(values, { resetForm, setSubmitting }) => {
            onPutTeamContent(values, resetForm, setSubmitting);
          }}
        >
          {({
            values,
            errors,
            touched,
            setValues,
            setFieldValue,
            handleSubmit,
            handleChange,
            handleBlur,
            resetForm,
          }) => (
            <>
              <form onSubmit={handleSubmit} ref={form}>
                <Modal.Header closeButton>
                  <Modal.Title id="edit">Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body id="userModal">
                  <div className="col-12 pl-4 pr-4">
                    <div className="row">
                      <div className="col-12 col-md-12 col-lg-12">
                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                          <TextField
                            id="title"
                            name="title"
                            label="Title"
                            placeholder="Title"
                            className="w-100"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={getInputClasses(touched, errors, "title")}
                            onKeyDown={handleEnter}
                          />
                        </div>
                        <RenderIf condition={touched.title && errors.title}>
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.title}</div>
                          </div>
                        </RenderIf>
                      </div>
                      <div className="col-12 col-md-12 col-lg-12">
                        <div className="w-100 pt-2 pb-3">
                          <RichTextEditor
                            onChange={(newContent) => {
                              const trimmedContent = newContent.trim();
                              const isEmpty = trimmedContent === "<p><br></p>";
                              setContent(isEmpty ? "" : newContent);
                              setFieldValue(
                                "description",
                                isEmpty ? "" : newContent
                              );
                            }}
                            value={content}
                          />
                        </div>
                        <RenderIf
                          condition={touched.description && errors.description}
                        >
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {errors.description}
                            </div>
                          </div>
                        </RenderIf>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="light"
                    onClick={() => {
                      onHide(false);
                      resetForm();
                    }}
                  >
                    Close
                  </Button>
                  <Button type="submit" disabled={isLoading} className={`px-9`}>
                    Save
                    <RenderIf condition={isLoading}>
                      <span className="ml-3 spinner spinner-white"></span>
                    </RenderIf>
                  </Button>
                </Modal.Footer>
              </form>
            </>
          )}
        </Formik>
      </RenderIf>
    </Modal>
  );
}
