import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import * as Yup from "yup";
import { RenderIf } from "../../../../components";
import {
  useGetEventTypeQuery,
  useUpdateEventTypeMutation,
} from "../../../../../redux/api/Events/eventsApi";

const { REACT_APP_BASE_PHOTO_URL_MAIN } = process.env;
export default function Edit(props) {
  const { onHide, show, refetch, id } = props;

  const [disabled, setDisabled] = useState(false);
  const data = useGetEventTypeQuery(id);
  const datas = data?.currentData;

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [
    updateEventType,
    { isLoading, error, isSuccess },
  ] = useUpdateEventTypeMutation();

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
    }
  }, [isLoading]);

  const form = useRef(null);

  const EditSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
  });

  const getInputClasses = (touched, errors, FieldName) => {
    if (touched[FieldName] && errors[FieldName]) {
      return true;
    }
    if (touched[FieldName] && errors[FieldName]) {
      return false;
    }

    return false;
  };

  const onPutType = (values, resetForm, setSubmitting) => {
    const type = new FormData(form.current);
    type.append("Id", id);

    updateEventType(type);
  };

  return (
    <Modal
      size="xl"
      onHide={onHide}
      show={show}
      aria-labelledby="edit"
      className="modal-center pr-0"
      centered
    >
      <RenderIf
        condition={
          datas &&
          Object.keys(datas).length !== 0 &&
          datas.constructor === Object
        }
      >
        <Formik
          initialValues={{
            firstName: datas?.firstName,
            lastName: datas?.lastName,
            position: datas?.position,
            description: datas?.description,
            title: datas?.title,
            file: datas?.fileUrl,
          }}
          validationSchema={EditSchema}
          onSubmit={(values, { resetForm, setSubmitting }) => {
            onPutType(values, resetForm, setSubmitting);
          }}
        >
          {({
            values,
            errors,
            touched,
            setValues,
            setFieldValue,
            handleSubmit,
            handleChange,
            handleBlur,
            resetForm,
          }) => (
            <>
              <form onSubmit={handleSubmit} ref={form}>
                <Modal.Header closeButton>
                  <Modal.Title id="edit">Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body id="userModal">
                  <div className="col-12 pl-4 pr-4">
                    <div className="row">
                      <div className="col-12 col-md-12 col-lg-6">
                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                          <TextField
                            id="title"
                            name="title"
                            label="Title"
                            placeholder="Title"
                            className="w-100"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={getInputClasses(touched, errors, "title")}
                            onKeyDown={handleEnter}
                          />
                        </div>
                        <RenderIf
                          condition={touched.title && errors.title}
                          renderElse={null}
                        >
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.title}</div>
                          </div>
                        </RenderIf>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="light"
                    onClick={() => {
                      onHide(false);
                      resetForm();
                    }}
                  >
                    Close
                  </Button>
                  <Button type="submit" disabled={isLoading} className={`px-9`}>
                    Save
                    <RenderIf condition={isLoading}>
                      <span className="ml-3 spinner spinner-white"></span>
                    </RenderIf>
                  </Button>
                </Modal.Footer>
              </form>
            </>
          )}
        </Formik>
      </RenderIf>
    </Modal>
  );
}
