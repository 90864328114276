import React, { useMemo } from "react";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import crocusoft from "./image.png";

export function Footer() {
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true),
    };
  }, [uiService]);

  return (
    <div
      className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div
        className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className="text-dark order-2 order-md-1">
          <a
            href="https://crocusoft.com/"
            target="_blank"
            style={{ paddingTop: "5px", textDecoration: "none" }}
            rel="noreferrer"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  color: "#0293A6",
                  fontSize: "16px",
                  paddingRight: "2px",
                }}
              >
                Designed & Developed by
              </span>
              <img
                src={crocusoft}
                alt="Crocusoft-logo"
                style={{
                  width: "25px",
                  height: "25px",
                  objectFit: "cover",
                }}
              />
              <span
                style={{
                  color: "#0293A6",
                  fontSize: "16px",
                  paddingRight: "2px",
                  fontWeight: "bold",
                }}
              >
                Crocusoft
              </span>
            </div>
          </a>
        </div>
        {/* <div className="nav nav-dark order-1 order-md-2">
          <a
            href="http://keenthemes.com/metronic"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link pr-3 pl-0"
          >
            About
          </a>
          <a
            href="http://keenthemes.com/metronic"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link px-3"
          >
            Team
          </a>
          <a
            href="http://keenthemes.com/metronic"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link pl-3 pr-0"
          >
            Contact
          </a>
        </div> */}
      </div>
    </div>
  );
}
