import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDeleteContentMutation } from "../../../../redux/api/Content/contentApi";
import { RenderIf } from "../../../components";

export default function Remove(props) {
  const { onHide, show, id, refetch } = props;

  const [
    deleteTeamContent,
    { isLoading, isSuccess },
  ] = useDeleteContentMutation();

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
    }
  }, [isLoading]);

  const onDeleteTeamContent = (e) => {
    e.preventDefault();
    deleteTeamContent(id);
  };

  return (
    <Modal show={show} onHide={onHide} aria-labelledby="delete" centered>
      <form onSubmit={onDeleteTeamContent}>
        <Modal.Header closeButton>
          <Modal.Title id="delete">Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 pl-4 pr-4">
            <div className="row">Are you sure to delete?</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => onHide(false)}>
            Cancel
          </Button>
          <Button type="submit">
            Delete
            <RenderIf condition={isLoading}>
              <span className="ml-3 spinner spinner-white"></span>
            </RenderIf>
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
