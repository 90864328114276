import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";

export const communityApi = createApi({
  reducerPath: "communityApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getCommunities: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, title } = filter;
        return {
          url: `community/filter?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            title ? `&Title=${title.title}` : ""
          }`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          // dispatch(setInterests(data.data));
        } catch (error) {}
      },
    }),
    getCommunitiesWithoutFilter: builder.query({
      query(filter) {
        return {
          url: `community/filter`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    getCommunity: builder.query({
      query(id) {
        return {
          url: `community/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    createCommunity: builder.mutation({
      query(form) {
        return {
          url: "community",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    updateCommunity: builder.mutation({
      query(form) {
        return {
          url: "community",
          method: "PUT",
          data: form,
        };
      },
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {}
      },
    }),
    deleteCommunity: builder.mutation({
      query(id) {
        return {
          url: `community`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),
    rejectCommunity: builder.mutation({
      query(form) {
        return {
          url: "community/reject",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    acceptCommunity: builder.mutation({
      query(form) {
        return {
          url: "community/accept",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),

    getAllCommunityEventType: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, title } = filter;
        return {
          url: `community/event/filterType?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            title ? `&Title=${title.title}` : ""
          }`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    getAllCommunityEventTypeWithoutFilter: builder.query({
      query(filter) {
        return {
          url: `community/event/filterType`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    getCommunityEventType: builder.query({
      query(id) {
        return {
          url: `community/event/type/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    createCommunityEventType: builder.mutation({
      query(form) {
        return {
          url: "community/event/type",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    updateCommunityEventType: builder.mutation({
      query(data) {
        return {
          url: "community/event/type",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
    }),
    deleteCommunityEventType: builder.mutation({
      query(id) {
        return {
          url: `community/event/type`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),

    getAllCommunityEvent: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, title, communityId } = filter;
        return {
          url: `community/event/filter?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            title ? `&Title=${title.title}` : ""
          }${communityId ? `&CommunityId=${communityId.communityId}` : ""}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    getCommunityEvent: builder.query({
      query(id) {
        return {
          url: `community/event/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    createCommunityEvent: builder.mutation({
      query(form) {
        return {
          url: "community/event",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    updateCommunityEvent: builder.mutation({
      query(data) {
        return {
          url: "community/event",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
    }),
    deleteCommunityEvent: builder.mutation({
      query(id) {
        return {
          url: `community/event`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),

    updateCommunityMemberRole: builder.mutation({
      query(data) {
        return {
          url: "community/member/Role",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
    }),
    deleteCommunityDiscussion: builder.mutation({
      query(id) {
        return {
          url: `community/discussion/discussion`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),
    deleteCommunityDiscussionReport: builder.mutation({
      query(id) {
        return {
          url: `community/discussion/report`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),
  }),
});

export const {
  useDeleteCommunityDiscussionReportMutation,
  useDeleteCommunityDiscussionMutation,
  useRejectCommunityMutation,
  useAcceptCommunityMutation,
  useUpdateCommunityMemberRoleMutation,
  useGetAllCommunityEventQuery,
  useCreateCommunityEventMutation,
  useDeleteCommunityEventMutation,
  useGetCommunityEventQuery,
  useUpdateCommunityEventMutation,

  useCreateCommunityEventTypeMutation,
  useDeleteCommunityEventTypeMutation,
  useGetAllCommunityEventTypeQuery,
  useGetAllCommunityEventTypeWithoutFilterQuery,
  useGetCommunityEventTypeQuery,
  useUpdateCommunityEventTypeMutation,
  useCreateCommunityMutation,
  useDeleteCommunityMutation,
  useGetCommunitiesQuery,
  useGetCommunityQuery,
  useUpdateCommunityMutation,
  useGetCommunitiesWithoutFilterQuery,
} = communityApi;
