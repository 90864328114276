import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";

export const activityApi = createApi({
  reducerPath: "activityApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getActivities: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, name } = filter;
        return {
          url: `activity/filter?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            name ? `&Name=${name.name}` : ""
          }`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          // dispatch(setInterests(data.data));
        } catch (error) {}
      },
    }),
    getActivity: builder.query({
      query(id) {
        return {
          url: `activity/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    createActivity: builder.mutation({
      query(form) {
        return {
          url: "activity",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    updateActivity: builder.mutation({
      query(form) {
        return {
          url: "activity",
          method: "PUT",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    deleteActivity: builder.mutation({
      query(id) {
        return {
          url: `activity`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),
    getReports: builder.query({
      query() {
        return {
          url: `discussion/reports`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    getCommunityReports: builder.query({
      query() {
        return {
          url: `community/reports`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
  }),
});

export const {
  useGetCommunityReportsQuery,
  useGetReportsQuery,
  useCreateActivityMutation,
  useDeleteActivityMutation,
  useGetActivitiesQuery,
  useGetActivityQuery,
  useUpdateActivityMutation,
} = activityApi;
