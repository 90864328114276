import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import * as Yup from "yup";
import { ReactSelect, RenderIf } from "../../../components";
import {
  useGetAllComplainReasonWithoutFilterQuery,
  useGetSuggestQuery,
  useUpdateSuggestMutation,
} from "../../../../redux/api/Suggest/suggestApi";

export default function Edit(props) {
  const { onHide, show, refetch, id } = props;

  const [disabled, setDisabled] = useState(false);
  const [complainReasons, setComplainReasons] = useState([]);

  const [complainReason, setComplainReason] = React.useState(null);

  const { data } = useGetSuggestQuery(id);

  const { data: allReason } = useGetAllComplainReasonWithoutFilterQuery();

  function handleChangeRCenter(value, setFieldValue) {
    setComplainReason((prevState) => value);
    setFieldValue("complaintReasonId", value.value);
  }
  useEffect(() => {
    setComplainReasons(
      allReason?.data?.map((suggestion) => ({
        value: suggestion.id,
        label: suggestion.title,
      }))
    );
  }, [allReason]);
  useEffect(() => {
    setComplainReason((prev) =>
      complainReasons?.find(
        (option) => option.value === data?.complaintReasonId
      )
    );
  }, [data?.complaintReasonId, complainReasons]);

  useEffect(() => {
    if (!show) {
      setComplainReasons(null);
    }
  }, [show]);

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };
  const [
    updateSuggest,
    { isLoading, error, isSuccess },
  ] = useUpdateSuggestMutation();
  const form = useRef(null);

  const EditSchema = Yup.object().shape({
    firstName: Yup.string().required("Name is required"),
    lastName: Yup.string().required("LastName is required"),
    description: Yup.string().required("Description is required"),
    file: Yup.string().required("File is required"),
    position: Yup.string().required("Position is required"),
    title: Yup.string().required("Title is required"),
  });

  const getInputClasses = (touched, errors, FieldName) => {
    if (touched[FieldName] && errors[FieldName]) {
      return true;
    }
    if (touched[FieldName] && errors[FieldName]) {
      return false;
    }

    return false;
  };

  const onPutSuggest = (values, resetForm, setSubmitting) => {
    const suggest = new FormData(form.current);
    suggest.append("Id", id);
    suggest.append("complaintReasonId", values.complaintReasonId);

    updateSuggest(suggest);
  };
  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
    }
  }, [isLoading]);

  return (
    <Modal
      size="xl"
      onHide={onHide}
      show={show}
      aria-labelledby="edit"
      className="modal-center pr-0"
      centered
    >
      <RenderIf
        condition={
          data && Object.keys(data).length !== 0 && data.constructor === Object
        }
      >
        <Formik
          initialValues={{
            text: data?.text,
            email: data?.email,
            phone: data?.phone,
            complaintReasonId: data?.complaintReasonId,
          }}
          validationSchema={EditSchema}
          onSubmit={(values, { resetForm, setSubmitting }) => {
            onPutSuggest(values, resetForm, setSubmitting);
          }}
        >
          {({
            values,
            errors,
            touched,
            setValues,
            setFieldValue,
            handleSubmit,
            handleChange,
            handleBlur,
            resetForm,
          }) => (
            <>
              <form onSubmit={handleSubmit} ref={form}>
                <Modal.Header closeButton>
                  <Modal.Title id="edit">Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body id="userModal">
                  <div className="col-12 pl-4 pr-4">
                    <div className="row">
                      <div className="col-12 col-md-12 col-lg-6">
                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                          <TextField
                            id="email"
                            name="email"
                            label="Email"
                            placeholder="Email"
                            className="w-100"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={getInputClasses(touched, errors, "email")}
                            onKeyDown={handleEnter}
                          />
                        </div>
                        <RenderIf
                          condition={touched.email && errors.email}
                          renderElse={null}
                        >
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.email}</div>
                          </div>
                        </RenderIf>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6">
                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                          <TextField
                            id="phone"
                            name="phone"
                            label="Phone"
                            placeholder="Phone"
                            className="w-100"
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={getInputClasses(touched, errors, "phone")}
                            onKeyDown={handleEnter}
                          />
                        </div>
                        <RenderIf
                          condition={touched.phone && errors.phone}
                          renderElse={null}
                        >
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.phone}</div>
                          </div>
                        </RenderIf>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6">
                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                          <TextField
                            id="text"
                            name="text"
                            label="Text"
                            placeholder="Text"
                            className="w-100"
                            value={values.text}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={getInputClasses(touched, errors, "text")}
                            onKeyDown={handleEnter}
                          />
                        </div>
                        <RenderIf
                          condition={touched.text && errors.text}
                          renderElse={null}
                        >
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.text}</div>
                          </div>
                        </RenderIf>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6">
                        <ReactSelect
                          options={complainReasons}
                          onChange={(value) =>
                            handleChangeRCenter(value, setFieldValue)
                          }
                          single={complainReason}
                          // label="Complain Reason"
                          placeholder="Complain Reason"
                          instanceId="complaintReasonId"
                          onKeyDown={handleEnter}
                        />
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="light"
                    onClick={() => {
                      onHide(false);
                      resetForm();
                    }}
                  >
                    Close
                  </Button>
                  <Button type="submit" disabled={isLoading} className={`px-9`}>
                    Save
                    {isLoading && (
                      <span className="ml-3 spinner spinner-white"></span>
                    )}
                  </Button>
                </Modal.Footer>
              </form>
            </>
          )}
        </Formik>
      </RenderIf>
    </Modal>
  );
}
