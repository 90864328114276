export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const updateArray = (oldArray, updatedProperties) => {
  return [...oldArray, ...updatedProperties];
};
export const getTitleByComplexity = (complexity) => {
  let title;

  if (complexity === 1) {
    title = "Easy";
  } else if (complexity === 2) {
    title = "Medium";
  } else if (complexity === 3) {
    title = "Hard";
  } else if (complexity === 4) {
    title = "Very Hard";
  }

  return title;
};
