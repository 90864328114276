import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles/index";
import Drawer from "@material-ui/core/Drawer/index";
import Divider from "@material-ui/core/Divider/index";
import CloseIcon from "@material-ui/icons/Close";
import Card from "@material-ui/core/Card/index";
import CardHeader from "@material-ui/core/CardHeader/index";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import "../../../../_metronic/_assets/plugins/flaticon2/flaticon.css";
import { useGetStoryQuery } from "../../../../redux/api/ReviewAndStory/reviewAndStoryApi";
const { REACT_APP_BASE_PHOTO_URL_MAIN } = process.env;

const useStyles = makeStyles({
  list: {
    width: 400,
    height: "100vh",
  },
  card: {
    margin: "30px",
  },
  pointer: {
    cursor: "pointer",
  },
});

export default function View(props) {
  const { onClose, id } = props;

  const { data } = useGetStoryQuery(id);

  const classes = useStyles();
  const sideList = (side) => (
    <div className={classes.list} role="presentation">
      <div className="col-12 d-flex align-items-center justify-content-between py-4">
        <h1 className="h3 mb-0">Story</h1>
        <CloseIcon className={classes.pointer} onClick={onClose} />
      </div>
      <Divider className="bg-dark" />
      <div className="drawer-body">
        <Card className={classes.card}>
          <CardHeader
            title="General information"
            className="bg-secondary text-dark h6 p-3"
            disableTypography
          />

          <CardHeader
            avatar={
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Address-card.svg"
                )}
                className="icon-primary"
              />
            }
            title={data?.firstName}
            subheader="Name"
            className="p-3"
          />
          <CardHeader
            avatar={
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Address-card.svg"
                )}
                className="icon-primary"
              />
            }
            title={data?.lastName}
            subheader="Lastname"
            className="p-3"
          />
          <CardHeader
            avatar={
              <div className="my-2">
                <img
                  style={{
                    width: "40px",
                    height: "40px",
                    objectFit: "cover",
                    borderRadius: "100%",
                  }}
                  src={`${REACT_APP_BASE_PHOTO_URL_MAIN}${data?.fileUrl}`}
                  alt="contributor"
                />
              </div>
            }
            subheader="Photo"
            className="p-3"
          />
        </Card>

        <Card className={classes.card}>
          <CardHeader
            title="Additional information"
            className="bg-secondary text-dark h6 p-3"
            disableTypography
          />
          <CardHeader
            avatar={
              <i className="icon-flaticon flaticon2-shield text-primary"></i>
            }
            title={data?.position}
            subheader="Position"
            className="p-3"
          />
          <CardHeader
            avatar={<i class="flaticon2-sheet icon-flaticon"></i>}
            title={
              <p
                dangerouslySetInnerHTML={{
                  __html: data?.description,
                }}
              ></p>
            }
            subheader="Description"
            className="p-3"
          />
          <CardHeader
            avatar={<i class="flaticon-menu-button"></i>}
            title={data?.title}
            subheader="Title"
            className="p-3"
          />
        </Card>
      </div>
    </div>
  );

  return (
    <Drawer {...props} anchor="right" className="drawer--fixed">
      {sideList("right")}
    </Drawer>
  );
}
