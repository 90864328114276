import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  progress: {},
};

export const progressSlice = createSlice({
  initialState,
  name: "progressSlice",
  reducers: {
    setProgressData: (state, action) => {
      state.progress = action.payload;
    },
  },
});

export default progressSlice.reducer;
export const { setProgressData } = progressSlice.actions;
