import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import AlertComponent from "../../../../_metronic/_helpers/AlertComponent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import EyeIcon from "@material-ui/icons/RemoveRedEye";
import PlusIcon from "@material-ui/icons/Add";
import { Person } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress/index";
import { RenderIf } from "../../../components";

const { REACT_APP_BASE_PHOTO_URL_MAIN } = process.env;

const headRows = [
  {
    id: "Name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },

  {
    id: "tools",
    numeric: false,
    disablePadding: true,
    label: "Tools",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    Permissions_Users_Delete,
  } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <RenderIf condition={Permissions_Users_Delete}>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "Select all desserts" }}
            />
          </RenderIf>
        </TableCell>
        {headRows.map((row) => (
          <TableCell
            key={row.id}
            align="left"
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={false}
          >
            <RenderIf
              condition={row.id == "tools"}
              renderElse={
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order ? "asc" : "desc"}
                  onClick={() => {
                    return row.id !== "tools" ? onRequestSort(row.id) : null;
                  }}
                >
                  {row.label}
                </TableSortLabel>
              }
            >
              {row.label}
            </RenderIf>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.bool,
  orderBy: PropTypes.string,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    flexWrap: "wrap",
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
  label: {
    fontSize: ".875rem",
    width: "100%",
  },
  toolbar: {
    zIndex: "10",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    setOpenCreate,
    setOpenRemoveAll,
    Activity_Add,
    tableHeaderRef,
  } = props;

  return (
    <Toolbar
      className={clsx(classes.root, classes.toolbar, {
        [classes.highlight]: numSelected > 0,
      })}
      ref={tableHeaderRef}
    >
      <div className="d-flex w-100">
        <div className={classes.title}>
          <RenderIf condition={numSelected > 0}>
            <Typography color="inherit" variant="subtitle1">
              {numSelected} selected
            </Typography>
          </RenderIf>
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          <RenderIf condition={numSelected > 0}>
            <Tooltip title="Delete">
              <IconButton
                aria-label="Delete"
                onClick={() => {
                  setOpenRemoveAll(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </RenderIf>
        </div>
        <RenderIf condition={Activity_Add}>
          <Tooltip title="Add" placement="top">
            <button
              className="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary mr-2 h5 rounded-circle flex-shrink-0"
              onClick={() => setOpenCreate(true)}
            >
              <PlusIcon />
            </button>
          </Tooltip>
        </RenderIf>
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  height: {
    height: "calc(100% - 65px)",
  },
  root: {
    width: "100%",
    height: "100%",
    padding: "24px 0",
  },
  paper: {
    width: "100%",
    height: "100%",
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  toolbar: {
    minHeight: theme.spacing(2),
  },
  progresRoot: {
    width: "100%",
    padding: "32px 16px",
  },
  progress: {
    margin: "auto",
    display: "block",
    color: "#e2241b",
  },
}));

export default function EnhancedTable(props) {
  const {
    sortField,
    orderBy,
    setOpenEdit,
    setOpenView,
    setOpenRemove,
    setOpenRemoveAll,
    setOpenCreate,
    isLoaded,
    setOpenMembers,
    error,
    rows,

    permissions,
    count,
    setBadgeId,
    setDeleteUsersID,
    deleteUsersIds,
    setPage,
    setRowsPerPage,
    page,
    rowsPerPage,
    filterInputsUpdate,
    filterInputs,
    setFilterInputs,
    filterStatusesUpdate,
    handleChangeRowsPerPage,
    handleChangePage,
  } = props;
  const classes = useStyles();

  function handleRequestSort(property) {
    const isDesc = sortField === property && !orderBy;
    setRowsPerPage(10);
    setPage(0);
  }
  const {
    Activity_Update,
    Activity_Delete,
    Activity_View,
    Activity_Add,
  } = permissions;

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);

      setDeleteUsersID(newSelecteds);
      return;
    }

    setDeleteUsersID([]);
  }

  const isSelected = (id) => deleteUsersIds.indexOf(id) !== -1;

  const { pathname } = useLocation();

  const content = useRef(null);
  const tableHeader = useRef(null);
  const tableWrapperHeader = useRef(null);
  const [tableWrapperHeight, setTableWrapperHeight] = useState(0);

  useEffect(() => {
    let paperHeigth = content?.current?.offsetHeight;
    let tableHeaderHeight = tableHeader?.current?.offsetHeight;
    setTableWrapperHeight(paperHeigth - tableHeaderHeight - 52);
  }, [pathname]);

  return (
    <div className={`col-12 ${classes.height}`}>
      <div className={classes.root}>
        <Paper className={classes.paper} ref={content}>
          <RenderIf condition={isLoaded && !rows.length}>
            <div className={classes.progresRoot}>
              <CircularProgress className={classes.progress} />
            </div>
          </RenderIf>

          <EnhancedTableToolbar
            numSelected={deleteUsersIds.length}
            setOpenCreate={setOpenCreate}
            setOpenRemoveAll={setOpenRemoveAll}
            Activity_Add={Activity_Add}
            filterInputsUpdate={filterInputsUpdate}
            filterInputs={filterInputs}
            setFilterInputs={setFilterInputs}
            filterStatusesUpdate={filterStatusesUpdate}
            tableHeaderRef={tableHeader}
          />
          <div
            className={classes.tableWrapper}
            ref={tableWrapperHeader}
            style={{ maxHeight: tableWrapperHeight + "px" }}
          >
            <RenderIf condition={rows.length && Activity_View}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                stickyHeader
              >
                <EnhancedTableHead
                  numSelected={deleteUsersIds.length}
                  order={orderBy}
                  orderBy={sortField}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {rows.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <div className="my-2">
                            <img
                              style={{
                                width: "40px",
                                height: "40px",
                                objectFit: "cover",
                                borderRadius: "100%",
                              }}
                              src={`${REACT_APP_BASE_PHOTO_URL_MAIN}${row?.fileUrl}`}
                              alt="contributor"
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          className="pr-4 p-2"
                        >
                          {row.name}
                        </TableCell>

                        <TableCell padding="none" className="pr-4">
                          <Tooltip title="Show all" placement="top">
                            <button
                              aria-label="view"
                              className="btn btn-sm btn-icon btn-bg-light btn-text-info btn-hover-info mr-2"
                              onClick={(e) => {
                                setOpenView(e);
                                setBadgeId(row.id);
                              }}
                            >
                              <EyeIcon />
                            </button>
                          </Tooltip>

                          <RenderIf condition={Activity_Update}>
                            <Tooltip title="Edit" placement="top">
                              <button
                                aria-label="edit"
                                className="btn btn-sm btn-icon btn-bg-light btn-text-dark btn-hover-dark mr-2"
                                onClick={() => {
                                  setOpenEdit(true);
                                  setBadgeId(row.id);
                                }}
                              >
                                <EditIcon />
                              </button>
                            </Tooltip>
                          </RenderIf>
                          <Tooltip title="Members" placement="top">
                            <button
                              aria-label="edit"
                              className="btn btn-sm btn-icon btn-bg-light btn-text-dark btn-hover-dark mr-2"
                              onClick={() => {
                                setOpenMembers(true);
                                setBadgeId(row.id);
                              }}
                            >
                              <Person />
                            </button>
                          </Tooltip>

                          <RenderIf condition={Activity_Delete}>
                            <Tooltip title="Delete" placement="top">
                              <button
                                aria-label="Delete"
                                className="btn btn-sm btn-icon btn-bg-light btn-text-danger btn-hover-danger"
                                onClick={() => {
                                  setOpenRemove(true);
                                  setBadgeId(row.id);
                                }}
                              >
                                <DeleteIcon />
                              </button>
                            </Tooltip>
                          </RenderIf>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </RenderIf>
            <RenderIf condition={!Activity_View}>
              <AlertComponent variant="info" message="Not Found" />
            </RenderIf>
            <RenderIf condition={!rows.length && !isLoaded && !error}>
              <AlertComponent variant="info" message="Not Found" />
            </RenderIf>
            <RenderIf condition={!isLoaded && error}>
              <AlertComponent
                variant="danger"
                message="We've lost the connection"
              />
            </RenderIf>
          </div>
          <RenderIf condition={count > 10}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { value: count, label: "All" }]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              labelRowsPerPage="Line count:"
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </RenderIf>
        </Paper>
      </div>
    </div>
  );
}
