import * as Yup from "yup";

export const initialValues = {
  title: "",
  description: "",
  iconFile: "",
  lenghtInMinutes: "",
  hubDevCourseTypeId: "",
  hubDevCourseOrganizationId: "",
  categories: "",
  experts: [],
  complexity: "",
  url: "",
  scrollCourseId: "",
  orderingId: "",
};
export const CreateSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  url: Yup.string().required("Url is required"),
  scrollCourseId: Yup.number()
    .min(1, "Min. value must be greater than 0")
    .required("Scroll Course Id is required"),
  description: Yup.string()
    .required("Description is required")
    .test("rich-text-not-empty", "Description cannot be empty", (value) => {
      if (typeof value !== "string") return false;
      const trimmedValue = value.trim();
      return trimmedValue !== "<p><br></p>" && trimmedValue !== "";
    }),
  lenghtInMinutes: Yup.number()
    .min(1, "Min. value must be greater than 0")
    .required("Duration is required"),
  orderingId: Yup.number()
    .min(1, "Min. value must be greater than 0")
    .required("Order Id is required"),
  hubDevCourseTypeId: Yup.string().required("Type is required"),
  hubDevCourseOrganizationId: Yup.string().required("Organization is required"),
  categories: Yup.string().required("Category is required"),
  complexity: Yup.string().required("Complexity is required"),
  // experts: Yup.array().min(1, "Expert is required"),
  iconFile: Yup.string().required("File is required"),
});
