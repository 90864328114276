import React from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField/index";

export default function Filter(props) {
  const {
    onHide,
    show,
    filterInputsUpdate,
    filterInputs,
    setFilterInputs,
  } = props;

  const onResetFilter = () => {
    setFilterInputs({
      url: "",
    });
  };
  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
    >
      <Modal.Header closeButton>
        <Modal.Title id="create">Filter</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12 pl-4 pr-4">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                <TextField
                  id="url"
                  name="url"
                  label="Search by name"
                  placeholder="Search by name"
                  className="w-100"
                  value={filterInputs.url}
                  onChange={(e) => filterInputsUpdate(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => {
            onResetFilter();
          }}
        >
          Reset
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
