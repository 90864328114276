import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";
import { setToken } from "../../features/User/userSlice";
import { userApi } from "./userApi";

export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery: APIBaseQuery,
    endpoints: (builder) => ({
        loginUser: builder.mutation({
            query(data) {
                return {
                    url: "backoffices/token",
                    method: "POST",
                    data: data,
                };
            },
            transformResponse: (result) => result,

            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    await dispatch(setToken(data));
                    await dispatch(
                        userApi.endpoints.getMe.initiate(data?.token)
                    );
                } catch (error) {}
            },
        }),
    }),
});

export const { useLoginUserMutation } = authApi;
