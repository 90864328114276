import React from "react";
import { makeStyles } from "@material-ui/core/styles/index";
import Drawer from "@material-ui/core/Drawer/index";
import Divider from "@material-ui/core/Divider/index";
import CloseIcon from "@material-ui/icons/Close";
import Card from "@material-ui/core/Card/index";
import CardHeader from "@material-ui/core/CardHeader/index";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";

import "../../../_assets/plugins/flaticon2/flaticon.css";
import { useGetChooseUserQuery } from "../../../../redux/api/Auth/Users/usersApi";
import { RenderIf } from "../../../../app/components";
const useStyles = makeStyles({
  list: {
    width: 400,
    height: "100vh",
  },
  card: {
    margin: "30px",
  },
  pointer: {
    cursor: "pointer",
  },
});

export default function View(props) {
  const { onClose, id } = props;

  const { data } = useGetChooseUserQuery(id);

  const classes = useStyles();
  const sideList = () => (
    <div className={classes.list} role="presentation">
      <div className="col-12 d-flex align-items-center justify-content-between py-4">
        <h1 className="h3 mb-0">Site Users</h1>
        <CloseIcon className={classes.pointer} onClick={onClose} />
      </div>
      <Divider className="bg-dark" />
      <div className="drawer-body">
        <Card className={classes.card}>
          <CardHeader
            title="General information"
            className="bg-secondary text-dark h6 p-3"
            disableTypography
          />
          <CardHeader
            avatar={
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Address-card.svg"
                )}
                className="icon-primary"
              />
            }
            title={data?.firstName}
            subheader="Name"
            className="p-3"
          />
          <CardHeader
            avatar={
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Adress-book2.svg"
                )}
                className="icon-primary"
              />
            }
            title={data?.lastName}
            subheader="Lastname"
            className="p-3"
          />
          <CardHeader
            avatar={
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Mail.svg")}
                className="icon-primary"
              />
            }
            title={data?.email}
            subheader="Email"
            className="p-3"
          />
        </Card>
        <Card className={classes.card}>
          <CardHeader
            title="Additional information"
            className="bg-secondary text-dark h6 p-3"
            disableTypography
          />

          <CardHeader
            avatar={
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Lock-circle.svg")}
                className="icon-primary"
              />
            }
            title={data?.locked ? "Inactive" : "Active"}
            subheader="Status"
            className="p-3"
          />
        </Card>
        <Card className={classes.card}>
          <CardHeader
            title="Contact information"
            className="bg-secondary text-dark h6 p-3"
            disableTypography
          />
          <CardHeader
            avatar={
              <i className="icon-flaticon flaticon-support text-primary"></i>
            }
            title={data?.siteUserContact?.phoneNumber}
            subheader="Phone Number"
            className="p-3"
          />

          <CardHeader
            avatar={
              <i className="icon-flaticon flaticon2-calendar-1 text-primary"></i>
            }
            title={data?.siteUserContact?.birthday?.slice(0, 10)}
            subheader="Birthday"
            className="p-3"
          />
          <CardHeader
            avatar={
              <i className="icon-flaticon flaticon-home text-successy"></i>
            }
            title={data?.siteUserContact?.department}
            subheader="Department"
            className="p-3"
          />
          <CardHeader
            avatar={
              <i className="icon-flaticon flaticon-home text-successy"></i>
            }
            title={data?.siteUserContact?.company}
            subheader="Company"
            className="p-3"
          />
          <CardHeader
            avatar={
              <i className="icon-flaticon flaticon2-shield text-primary"></i>
            }
            title={data?.position}
            subheader="Position"
            className="p-3"
          />
        </Card>
        <RenderIf condition={data?.siteUserEducations?.length}>
          <Card className={classes.card}>
            <CardHeader
              title="Education information"
              className="bg-secondary text-dark h6 p-3"
              disableTypography
            />

            {data?.siteUserEducations?.map((c) => (
              <CardHeader
                key={c?.id}
                avatar={
                  <i className="icon-flaticon flaticon-home text-successy"></i>
                }
                title={`${c?.school}/${c?.degree}/${c?.fieldOfStudy}`}
                subheader={`School/Degree/Field of study`}
                className="p-3"
              />
            ))}
          </Card>
        </RenderIf>
        <RenderIf condition={data?.siteUserLanguages?.length}>
          <Card className={classes.card}>
            <CardHeader
              title="Language information"
              className="bg-secondary text-dark h6 p-3"
              disableTypography
            />
            {data?.siteUserLanguages?.map((c) => (
              <CardHeader
                key={c?.id}
                avatar={
                  <i className="icon-flaticon flaticon-attachment text-dark"></i>
                }
                title={`${c?.language},${c?.proficiency}`}
                subheader={`Language,Proficiency`}
                className="p-3"
              />
            ))}
          </Card>
        </RenderIf>
        <RenderIf condition={data?.siteUserTrainings?.length}>
          <Card className={classes.card}>
            <CardHeader
              title="Training information"
              className="bg-secondary text-dark h6 p-3"
              disableTypography
            />
            {data?.siteUserTrainings?.map((c) => (
              <CardHeader
                key={c?.id}
                avatar={
                  <i className="icon-flaticon flaticon-graphic text-info"></i>
                }
                title={`${c?.name}`}
                subheader={`Training`}
                className="p-3"
              />
            ))}
          </Card>
        </RenderIf>
        <RenderIf condition={data?.siteUserSkills?.length}>
          <Card className={classes.card}>
            <CardHeader
              title="Skills"
              className="bg-secondary text-dark h6 p-3"
              disableTypography
            />
            {data?.siteUserSkills?.map((c) => (
              <CardHeader
                key={c?.id}
                avatar={
                  <i className="icon-flaticon flaticon-like text-warning"></i>
                }
                title={`${c?.name}`}
                subheader={`Skill`}
                className="p-3"
              />
            ))}
          </Card>
        </RenderIf>
      </div>
    </div>
  );

  return (
    <Drawer {...props} anchor="right" className="drawer--fixed">
      {sideList("right")}
    </Drawer>
  );
}
