import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";

export const notificationApi = createApi({
    reducerPath: "notificationApi",
    baseQuery: APIBaseQuery,
    keepUnusedDataFor: 1,
    endpoints: (builder) => ({
        getNotifications: builder.query({
            query(filter) {
                const { skip, take, field, orderBy, name } = filter;
                return {
                    url: `popup/filter?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
                        name ? `&Name=${name.title}` : ""
                    }`,
                };
            },
            keepUnusedDataFor: 1,
            transformResponse: (result) => result,
        }),
        getNotification: builder.query({
            query(id) {
                return {
                    url: `popup/byId?id=${id}`,
                };
            },
            keepUnusedDataFor: 1,
            transformResponse: (result) => result,
        }),
        createNotification: builder.mutation({
            query(form) {
                return {
                    url: "popup",
                    method: "POST",
                    data: form,
                };
            },
            transformResponse: (result) => result,
        }),
        updateNotification: builder.mutation({
            query(form) {
                return {
                    url: "popup",
                    method: "PUT",
                    data: form,
                };
            },
            transformResponse: (result) => result,
        }),
        deleteNotification: builder.mutation({
            query(id) {
                return {
                    url: `popup`,
                    method: "DELETE",
                    data: { id: id },
                };
            },
        }),
    }),
});

export const {
    useCreateNotificationMutation,
    useDeleteNotificationMutation,
    useGetNotificationQuery,
    useGetNotificationsQuery,
    useUpdateNotificationMutation,
} = notificationApi;
