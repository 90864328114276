import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { useDeleteContentMutation } from "../../../../../redux/api/Content/contentApi";

const useStyles = makeStyles((theme) => ({
  textarea: {
    width: "100% !important",
    height: "100px !important",
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
}));

const initialValues = {
  deleteReason: "",
};

export default function Remove(props) {
  const classes = useStyles();
  const { onHide, show, id, refetch } = props;

  const [
    deleteTeamContent,
    { isLoading, error, isSuccess },
  ] = useDeleteContentMutation();

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
    }
  }, [isLoading]);

  const onDeleteTeamContent = (e) => {
    e.preventDefault();
    deleteTeamContent(id);
  };

  return (
    <Modal show={show} onHide={onHide} aria-labelledby="delete" centered>
      <form onSubmit={onDeleteTeamContent}>
        <Modal.Header closeButton>
          <Modal.Title id="delete">Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 pl-4 pr-4">
            <div className="row">Are you sure to delete?</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => onHide(false)}>
            Cancel
          </Button>
          <Button type="submit">Delete</Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
