import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RenderIf, ReactSelect, RichTextEditor } from "../../../../components";
import { formatDate } from "../../../../../Helpers/formatDate";
import { dateConvertItemOther } from "../../../../../Helpers/dateConvertOther";
import DatePickers from "../../../../components/ReactDatePicker/DatePicker";
import {
  useCreateCommunityEventMutation,
  useGetAllCommunityEventTypeWithoutFilterQuery,
  useGetCommunitiesWithoutFilterQuery,
} from "../../../../../redux/api/Community/communityApi";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function Create(props) {
  const { show, onHide, refetch } = props;
  const [isError, setIsError] = useState("");
  const [defaultState, setDefaultState] = useState(false);
  const [file, setFile] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [preview, setPreview] = useState(false);
  const [fromClock, setFromClock] = useState(null);
  const [toClock, setToClock] = useState(null);
  const [date, setDate] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const { data: eventType } = useGetAllCommunityEventTypeWithoutFilterQuery();
  const { data: communityType } = useGetCommunitiesWithoutFilterQuery();
  const eventTypes = eventType?.data;
  const communityTypes = communityType?.data;
  const [eventTypeOption, setEventTypeOption] = React.useState(null);
  const [communityTypeOption, setCommunityTypeOption] = React.useState(null);

  const communityTypeOptions = communityTypes?.map((type) => ({
    value: type.id,
    label: type.title,
  }));

  const eventTypeOptions = eventTypes?.map((type) => ({
    value: type.id,
    label: type.title,
  }));
  function handleChangeReason(value) {
    setEventTypeOption(value);
    formik.setFieldValue("communityEventTypeId", value.value);
  }
  function handleChangeCommunityOption(value) {
    setCommunityTypeOption(value);
    formik.setFieldValue("communityId", value.value);
  }

  const options = [
    { value: 1, label: "Online" },
    { value: 2, label: "Offline" },
  ];
  const [selectedValue, setSelectedValue] = useState(false);
  const selectedValues = options?.map((type) => ({
    value: type.value,
    label: type.label,
  }));
  function handleChangeValue(value) {
    setSelectedValue(value);
    formik.setFieldValue("eventFormat", value.value);
  }
  const initialValues = {
    title: "",
    file: "",
    communityId: "",
    description: "",
    communityEventTypeId: "",
    eventFormat: "",
    promoted: false,
    fromClock: "",
    toClock: "",
    date: "",
    link: "",
  };
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };
  const imagePreview = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    setFile(file);
    formik.setFieldValue("file", file);

    reader.onloadend = () => {
      setImageSrc(reader.result);
    };

    reader.readAsDataURL(file);

    setPreview(true);
  };

  const onClick = (e) => {
    setPreview(false);
    setImageSrc("");
    setFile("");
  };
  const [
    createEvent,
    { isLoading, error, isSuccess },
  ] = useCreateCommunityEventMutation();

  const CreateSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string()
      .required("Description is required")
      .test("rich-text-not-empty", "Description cannot be empty", (value) => {
        if (typeof value !== "string") return false;
        const trimmedValue = value.trim();
        return trimmedValue !== "<p><br></p>" && trimmedValue !== "";
      }),
    eventFormat: Yup.string().required("Event format is required"),
    link: Yup.string().required("Link format is required"),
    communityEventTypeId: Yup.string().required("Information is required"),
    communityId: Yup.string().required("Information is required"),
    fromClock: Yup.string().required("Clock is required"),
    toClock: Yup.string().required("Clock is required"),
    date: Yup.string().required("Date is required"),
  });

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }

    return false;
  };

  const form = useRef();
  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values) => {
      const formData = new FormData(form.current);
      formData.append("title", String(values.title));
      formData.append("description", String(values.description));
      formData.append("link", values.link);
      const selectedTimeFrom = values.fromClock
        ? values.fromClock.toLocaleTimeString()
        : null;
      const selectedTimeTo = values.toClock
        ? values.toClock.toLocaleTimeString()
        : null;

      formData.append("fromClock", selectedTimeFrom);
      formData.append("toClock", selectedTimeTo);
      formData.append("promoted", isChecked);

      formData.append("eventFormat", String(values.eventFormat));
      formData.append(
        "communityEventTypeId",
        String(values.communityEventTypeId)
      );
      formData.append("communityId", String(values.communityId));

      formData.append("date", dateConvertItemOther(values.date));
      formData.append("file", file);
      createEvent(formData);
    },
  });

  const { setValues, resetForm, values } = formik;
  const onHideModal = () => {
    onHide();
    resetForm();
    setPreview(false);
    setImageSrc("");
    setFile("");
  };

  useEffect(() => {
    setIsError("");
  }, [show]);

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
      resetForm();
    }
  }, [isLoading]);

  return (
    <Modal
      size="xl"
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
      show={show}
      onHide={onHideModal}
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="create">Add</Modal.Title>
        </Modal.Header>
        <Modal.Body id="userModal">
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="title"
                    name="title"
                    label="Name"
                    placeholder="Name"
                    className="w-100"
                    error={getInputClasses("title")}
                    {...formik.getFieldProps("title")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={formik.touched.title && formik.errors.title}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.title}</div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="link"
                    name="link"
                    label="Link"
                    placeholder="Link"
                    className="w-100"
                    error={getInputClasses("link")}
                    {...formik.getFieldProps("link")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf condition={formik.touched.link && formik.errors.link}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.link}</div>
                  </div>
                </RenderIf>
              </div>

              <div className="col-12 col-md-12 col-lg-6">
                <ReactSelect
                  options={eventTypeOptions}
                  onChange={handleChangeReason}
                  single={eventTypeOption}
                  placeholder="Event Type"
                  inputId="communityEventTypeId"
                />
                <RenderIf
                  condition={
                    formik.touched.communityEventTypeId &&
                    formik.errors.communityEventTypeId
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.communityEventTypeId}
                    </div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <DatePicker
                  selected={fromClock}
                  id="fromClock"
                  name="fromClock"
                  dateFormat="h:mm aa"
                  onChange={(value) => {
                    setFromClock(value);
                    formik.setFieldValue("fromClock", value);
                  }}
                  placeholderText="From Clock"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={5}
                  timeCaption="Time"
                />
                <RenderIf
                  condition={
                    formik.touched.fromClock && formik.errors.fromClock
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.fromClock}
                    </div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <ReactSelect
                  options={selectedValues}
                  onChange={handleChangeValue}
                  single={selectedValue}
                  placeholder="Event Format"
                  inputId="eventFormat"
                />
                <RenderIf
                  condition={
                    formik.touched.eventFormat && formik.errors.eventFormat
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.eventFormat}
                    </div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <DatePicker
                  selected={toClock}
                  id="toClock"
                  name="toClock"
                  dateFormat="h:mm aa"
                  onChange={(value) => {
                    setToClock(value);
                    formik.setFieldValue("toClock", value);
                  }}
                  placeholderText="To Clock"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={5}
                  timeCaption="Time"
                />
                <RenderIf
                  condition={formik.touched.toClock && formik.errors.toClock}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.toClock}</div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <ReactSelect
                  options={communityTypeOptions}
                  onChange={handleChangeCommunityOption}
                  single={communityTypeOption}
                  placeholder="Community Type"
                  inputId="communityId"
                />
                <RenderIf
                  condition={
                    formik.touched.communityId && formik.errors.communityId
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.communityId}
                    </div>
                  </div>
                </RenderIf>
              </div>

              <div className="col-12 col-md-12 col-lg-6">
                <DatePickers
                  selected={date}
                  id="date"
                  name="date"
                  dateFormat="dd-MM-yyyy"
                  onChange={(value) => {
                    setDate(value);
                    formik.setFieldValue("date", formatDate(value));
                  }}
                  placeholderText="Date"
                />
                <RenderIf condition={formik.touched.date && formik.errors.date}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.date}</div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6 mt-3">
                <input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  name="file"
                  label="File"
                  placeholder="File"
                  className="w-100"
                  onChange={imagePreview}
                />

                <RenderIf condition={imageSrc}>
                  <div className="image-preview mt-4">
                    <div
                      style={{
                        position: "relative",
                        width: "100px",
                        height: "100px",
                      }}
                    >
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          borderRadius: "100%",
                        }}
                        src={imageSrc}
                        alt="img"
                      />
                      <button
                        style={{
                          position: "absolute",
                          right: "-30px",
                        }}
                        className="BtnSvg"
                        onClick={onClick}
                      >
                        <i
                          style={{ fontSize: "10px" }}
                          className="flaticon2-delete"
                        ></i>
                      </button>
                    </div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="d-flex align-items-center">
                  <Checkbox
                    {...label}
                    color="success"
                    onChange={handleChange}
                    checked={isChecked}
                  />
                  <h6 className="mb-0">Promoted</h6>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-12 mt-3">
                <RichTextEditor
                  onChange={(newContent) => {
                    const trimmedContent = newContent.trim();
                    const isEmpty = trimmedContent === "<p><br></p>";
                    formik.setFieldValue(
                      "description",
                      isEmpty ? "" : newContent
                    );
                  }}
                  value={formik?.values?.description}
                />
                <RenderIf
                  condition={
                    formik.touched.description && formik.errors.description
                  }
                  renderElse={null}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.description}
                    </div>
                  </div>
                </RenderIf>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
              setDefaultState(true);
            }}
          >
            Close
          </Button>
          <Button type="submit" disabled={isLoading} className={`px-9`}>
            Add
            <RenderIf condition={isLoading}>
              <span className="ml-3 spinner spinner-white"></span>
            </RenderIf>
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
