import React from "react";
import PowerIcon from "@material-ui/icons/PowerSettingsNew";
import Tooltip from "@material-ui/core/Tooltip/index";

export default function PowerButton({ id, status, onUpdateUserStatus }) {
    return (
        <Tooltip title={status ? "Activate" : "Deactivate"} placement="top">
            <button
                aria-label={status ? "Activate" : "Deactivate"}
                className={`btn btn-sm btn-icon btn-bg-light ${
                    status ? "btn-text-danger" : "btn-text-success"
                }`}
                onClick={() => onUpdateUserStatus(id, status)}
            >
                <PowerIcon />
            </button>
        </Tooltip>
    );
}
