import { createApi } from "@reduxjs/toolkit/query/react";
// import { setInterests } from "../../features/Interests/interestsSlice";
import { APIBaseQuery } from "../AxiosBase";

export const contributorsApi = createApi({
  reducerPath: "contributorsApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getContributors: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, name } = filter;
        return {
          url: `contributor/filter?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            name ? `&Name=${name.firstName}` : ""
          }`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          // dispatch(setInterests(data.data));
        } catch (error) {}
      },
    }),
    getContributor: builder.query({
      query(id) {
        return {
          url: `contributor/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    createContributor: builder.mutation({
      query(form) {
        return {
          url: "contributor",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    updateContributor: builder.mutation({
      query(form) {
        return {
          url: "contributor",
          method: "PUT",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    deleteContributor: builder.mutation({
      query(id) {
        return {
          url: `contributor`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),
  }),
});

export const {
  useCreateContributorMutation,
  useDeleteContributorMutation,
  useGetContributorQuery,
  useGetContributorsQuery,
  useUpdateContributorMutation,
} = contributorsApi;
