import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { RenderIf } from "../../../components";
import { useDeleteCommunityDiscussionReportMutation } from "../../../../redux/api/Community/communityApi";
import { useDeleteDiscussionReportMutation } from "../../../../redux/api/Discussions/discussionsApi";

export default function RemoveReport(props) {
  const { onHide, show, id, refetch, communityDataRefetch } = props;
  const [
    deleteCommunityReport,
    { isLoading, error, isSuccess },
  ] = useDeleteCommunityDiscussionReportMutation();
  const [
    deleteDiscussionReport,
    { isLoading: reportLoading, error: reportError, isSuccess: reportSuccess },
  ] = useDeleteDiscussionReportMutation();

  useEffect(() => {
    if (isSuccess || reportSuccess) {
      refetch();
      onHide();
      communityDataRefetch();
    }
  }, [isLoading || reportLoading]);

  // const onDeleteMember = (e) => {
  //   e.preventDefault();
  //   deleteReview(id?.id);
  // };
  const onDeleteMember = (e) => {
    e.preventDefault();
    if (id?.type === "discussion") {
      deleteDiscussionReport(id?.id);
    } else {
      deleteCommunityReport(id?.id);
    }
  };
  return (
    <Modal show={show} onHide={onHide} aria-labelledby="delete" centered>
      <form onSubmit={onDeleteMember}>
        <Modal.Header closeButton>
          <Modal.Title id="delete">Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 pl-4 pr-4">
            <div className="row">Are you sure to delete?</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => onHide(false)}>
            Cancel
          </Button>
          <Button type="submit">
            Delete
            <RenderIf condition={isLoading}>
              <span className="ml-3 spinner spinner-white"></span>
            </RenderIf>
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
