import { createApi } from "@reduxjs/toolkit/query/react";
import { setInterests } from "../../features/Interests/interestsSlice";
import { APIBaseQuery } from "../AxiosBase";

export const teamApi = createApi({
  reducerPath: "teamApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getAllTeam: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, name } = filter;
        return {
          url: `team/filter?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            name ? `&Name=${name.firstName}` : ""
          }`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          // dispatch(setInterests(data.data));
        } catch (error) {}
      },
    }),
    getDetailedInfoTeam: builder.query({
      query(id) {
        return {
          url: `team/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    createTeamMember: builder.mutation({
      query(form) {
        return {
          url: "team",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    updateTeamMember: builder.mutation({
      query(data) {
        return {
          url: "team",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
    }),
    deleteTeamMember: builder.mutation({
      query(id) {
        return {
          url: `team`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),
  }),
});

export const {
  useGetAllTeamQuery,
  useGetDetailedInfoTeamQuery,
  useCreateTeamMemberMutation,
  useUpdateTeamMemberMutation,
  useDeleteTeamMemberMutation,
} = teamApi;
