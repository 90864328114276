import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import BackOfficeUsersPage from "./pages/BackOfficeUsersPage";
import { DashboardPage } from "./pages/DashboardPage";
import InterestsPage from "./pages/InterestsPage";
import RolesPage from "./pages/RolesPage";
import ContributorsPage from "./pages/Contributors";
import TeamPage from "./pages/TeamPage";
import ReviewPage from "./pages/ReviewPage";
import StoryPage from "./pages/StoryPage";
import ValueOfUsPage from "./pages/ValueOfUsPage";
import EthicPage from "./pages/EthicPage";
import TagsPage from "./pages/TagsPage";
import NewsPage from "./pages/NewsPage";
import TeamContentPage from "./pages/TeamContentPage";
import ContactPage from "./pages/ContactPage";
import LibraryPage from "./pages/LibraryPage";
import LibraryCategoryPage from "./pages/LibraryCategoryPage";
import SuggestPage from "./pages/SuggestPage";
import ComplainReasonPage from "./pages/ComplainReasonPage";
import EventsPage from "./pages/EventsPage";
import EventTypePage from "./pages/EventTypePage";
import ReviewContentPage from "./pages/ReviewContentPage";
import ActivityPage from "./pages/ActivityPage";
import PartnersPage from "./pages/PartnersPage";
import CommunityPage from "./pages/CoomunityPage";
import CommunityEventTypePage from "./pages/CommunityEventTypePage";
import CommunityEventsPage from "./pages/CommunityEventsPage";
import StoryContentPage from "./pages/StoryContentPage";
import ContributorsContentPage from "./pages/ContributorsContentPage";
import EthicContentPage from "./pages/EthicContentPage";
import ValueOfUsContentPage from "./pages/ValueOfUsContentPage";
import PartnersContentPage from "./pages/PartnersContentPage";
import ContactContentPage from "./pages/ContactContentPage";
import SuggestContentPage from "./pages/SuggestContentPage";
import ActivityContentPage from "./pages/ActivityContentPage";
import PolicyContentPage from "./pages/PolicyPage";
import LibraryContentPage from "./pages/LibraryContentPage";
import AboutUsPage from "./pages/AboutUsPage";
import AdditionalLinksPage from "./pages/AdditionalLinksPage";
import NotificationPage from "./pages/NotificationPage";
import BadgePage from "./pages/BadgePage";
import ReportsPage from "./pages/ReportsPage";
import OnBoardingPage from "./pages/OnBoardingPage";
import StepFirstPage from "./pages/StepFirstPage";
import StepSecondPage from "./pages/StepSecondPage";
import StepThirdPage from "./pages/StepThirdPage";
import HubdevBannerPage from "./pages/HubdevBannerPage";
import HubDevCourseTypePage from "./pages/HubDevCourseTypePage";
import HubdevCourseOrganization from "./pages/HubDevCourseOrganization";
import HubdevProgressPage from "./pages/HubdevProgressPage";
import DepartmentsPage from "./pages/DepartmentsPage";
import PositionsPage from "./pages/PositionsPage";
import CompanyPage from "./pages/CompanyPage";
import DomainsPage from "./pages/DomainsPage";

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {<Redirect exact from="/" to="/dashboard" />}
        <ContentRoute path="/dashboard" component={DashboardPage} exact />
        <ContentRoute
          path="/dashboard/departments"
          component={DepartmentsPage}
        />
        <ContentRoute path="/dashboard/domains" component={DomainsPage} />
        <ContentRoute path="/dashboard/positions" component={PositionsPage} />
        <ContentRoute path="/dashboard/companies" component={CompanyPage} />

        <ContentRoute
          path="/backoffice-users"
          component={BackOfficeUsersPage}
        />
        <ContentRoute path="/roles" component={RolesPage} />
        <ContentRoute path="/interests" component={InterestsPage} />
        <ContentRoute path="/contributors" component={ContributorsPage} exact />
        <ContentRoute
          path="/contributors/content"
          component={ContributorsContentPage}
        />
        <ContentRoute path="/team" component={TeamPage} exact />
        <ContentRoute path="/review" component={ReviewPage} exact />
        <ContentRoute path="/review/content" component={ReviewContentPage} />
        <ContentRoute path="/story" component={StoryPage} exact />
        <ContentRoute path="/story/content" component={StoryContentPage} />
        <ContentRoute path="/valueOfUs" component={ValueOfUsPage} exact />
        <ContentRoute
          path="/valueOfUs/content"
          component={ValueOfUsContentPage}
        />

        <ContentRoute path="/ethic" component={EthicPage} exact />
        <ContentRoute path="/ethic/content" component={EthicContentPage} />

        <ContentRoute path="/tags" component={TagsPage} />
        <ContentRoute path="/news" component={NewsPage} />
        <ContentRoute path="/onboarding" component={OnBoardingPage} exact />
        <ContentRoute path="/onboarding/stepfirst" component={StepFirstPage} />
        <ContentRoute
          path="/onboarding/stepsecond"
          component={StepSecondPage}
        />
        <ContentRoute path="/onboarding/stepthird" component={StepThirdPage} />

        <ContentRoute path="/team/content" component={TeamContentPage} />
        <ContentRoute path="/contact" component={ContactPage} exact />
        <ContentRoute path="/contact" component={ContactContentPage} />

        <ContentRoute path="/hubdev" component={LibraryPage} exact />
        <ContentRoute path="/hubdev/category" component={LibraryCategoryPage} />
        <ContentRoute path="/hubdev/banner" component={HubdevBannerPage} />
        <ContentRoute
          path="/hubdev/courseType"
          component={HubDevCourseTypePage}
        />
        <ContentRoute
          path="/hubdev/courseOrganization"
          component={HubdevCourseOrganization}
        />
        <ContentRoute
          path="/hubdev/courseProgress"
          component={HubdevProgressPage}
        />

        <ContentRoute path="/hubdev/content" component={LibraryContentPage} />
        <ContentRoute path="/suggest" component={SuggestPage} exact />
        <ContentRoute path="/suggest/content" component={SuggestContentPage} />

        <ContentRoute path="/activity" component={ActivityPage} exact />
        <ContentRoute path="/policy" component={PolicyContentPage} exact />

        <ContentRoute
          path="/activity/content"
          component={ActivityContentPage}
        />
        <ContentRoute path="/partners" component={PartnersPage} exact />
        <ContentRoute
          path="/partners/content"
          component={PartnersContentPage}
        />

        <ContentRoute path="/community" component={CommunityPage} exact />
        <ContentRoute
          path="/community/eventType"
          component={CommunityEventTypePage}
        />
        <ContentRoute
          path="/community/events"
          component={CommunityEventsPage}
        />

        <ContentRoute
          path="/suggest/complainReason"
          component={ComplainReasonPage}
        />
        <ContentRoute path="/events" component={EventsPage} exact />
        <ContentRoute path="/events/eventType" component={EventTypePage} />
        <ContentRoute path="/aboutUs" component={AboutUsPage} />
        <ContentRoute path="/additionalLinks" component={AdditionalLinksPage} />
        {/* <ContentRoute path="/popup" component={NotificationPage} /> */}
        <ContentRoute path="/badge" component={BadgePage} />
        <ContentRoute path="/reports" component={ReportsPage} />

        <Redirect to="error/error-v5" />
      </Switch>
    </Suspense>
  );
}
