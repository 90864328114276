import React from "react";
import clsx from "clsx";
import Select, { createFilter, components } from "react-select";
import { emphasize, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import NoSsr from "@material-ui/core/NoSsr";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import PropTypes from "prop-types";
import { List } from "react-virtualized";

const CustomOption = ({ children, ...props }) => {
  // eslint-disable-next-line no-unused-vars
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };
  return (
    <components.Option {...newProps} className="custom-option">
      {children}
    </components.Option>
  );
};

const VirtualList = (props) => {
  const rows = props.children;
  const rowRenderer = ({ key, index, isScrolling, isVisible, style }) => (
    <div key={key} style={style}>
      {rows[index]}
    </div>
  );

  return (
    <List
      style={{ width: "100%" }}
      width={300}
      height={300}
      rowHeight={30}
      rowCount={rows.length ? rows.length : 0}
      rowRenderer={rowRenderer}
    />
  );
};

CustomOption.propTypes = {
  innerProps: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
};

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        color: props.isSelected ? "#EE2D41" : "#3F4254",
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
};

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
};

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  removeProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
};

export default function IntegrationReactSelect(props) {
  const {
    suggestions,
    handleChangeSingle,
    single,
    label,
    placeholder,
    inputId,
    name,
    isMulti,
    onFocus,
    isClearable,
    disabled,
    error,
    autoFocus,
    onKeyDown,
    menuIsOpen,
  } = props;

  const useStyles = makeStyles((theme) => ({
    // "& .MuiInputBase-input": {
    //   color:  "red",
    // },
    root: {
      flexGrow: 1,
      // height: 250,
    },
    input: {
      display: "flex",
      padding: 0,
      height: "auto",
      color: disabled ? "rgb(185,185,185)" : "inherit",
    },
    valueContainer: {
      display: "flex",
      flexWrap: "wrap",
      flex: 1,
      alignItems: "center",
      overflow: "hidden",
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === "light"
          ? theme.palette.grey[300]
          : theme.palette.grey[700],
        0.08
      ),
    },
    noOptionsMessage: {
      padding: theme.spacing(1, 2),
    },
    singleValue: {
      fontSize: 14,
    },
    placeholder: {
      position: "absolute",
      left: 2,
      bottom: 6,
      fontSize: 14,
    },
    paper: {
      position: "absolute",
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    divider: {
      height: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <NoSsr>
        <Select
          aria-labelledby="aria-label"
          classes={classes}
          // styles={selectStyles}
          inputId={inputId}
          name={name}
          error={error}
          TextFieldProps={{
            label: `${label}`,
            InputLabelProps: {
              htmlFor: `${inputId}`,
              shrink: true,
            },
            placeholder: `${placeholder}`,
          }}
          options={suggestions}
          // components={componentsObject}
          captureMenuScroll={false}
          components={{
            Option: Option,
            MultiValue: MultiValue,
            ValueContainer: ValueContainer,
            SingleValue: SingleValue,
            Placeholder: Placeholder,
            Control: Control,
            MenuList: VirtualList,
            NoOptionsMessage: NoOptionsMessage,
          }}
          classNamePrefix="custom-select"
          value={single}
          onChange={handleChangeSingle}
          placeholder={placeholder}
          isMulti={isMulti}
          onFocus={onFocus}
          isClearable={isClearable}
          isDisabled={disabled}
          autoFocus={autoFocus}
          // filterOption={customFilter}
          onKeyDown={onKeyDown}
          // isReadOnly={isReadOnly}
          menuIsOpen={!menuIsOpen ? undefined : false}
          // {...selectIsDisabled}
        />
      </NoSsr>
    </div>
  );
}
