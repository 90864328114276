import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { RenderIf } from "../../../components";
import { useDeleteDiscussionMutation } from "../../../../redux/api/Discussions/discussionsApi";
import { useDeleteCommunityDiscussionMutation } from "../../../../redux/api/Community/communityApi";

export default function Remove(props) {
  const { onHide, show, id, refetch, communityDataRefetch } = props;
  const [
    deleteDiscussion,
    { isLoading, isSuccess },
  ] = useDeleteDiscussionMutation();
  const [
    deleteCommunityDiscussion,
    { isLoading: communityLoading, isSuccess: communitySuccess },
  ] = useDeleteCommunityDiscussionMutation();

  useEffect(() => {
    if (isSuccess || communitySuccess) {
      refetch();
      onHide();
      communityDataRefetch();
    }
  }, [isLoading || communityLoading]);

  const onDeleteUser = (e) => {
    e.preventDefault();
    if (id?.type === "discussion") {
      deleteDiscussion(id?.id);
    } else {
      deleteCommunityDiscussion(id?.id);
    }
  };
  return (
    <Modal show={show} onHide={onHide} aria-labelledby="delete" centered>
      <form onSubmit={onDeleteUser}>
        <Modal.Header closeButton>
          <Modal.Title id="delete">Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 pl-4 pr-4 mb-3">
            <div className="row">Are you sure you want to delete?</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
            }}
          >
            Close
          </Button>
          <Button type="submit" variant="danger">
            Delete
            <RenderIf condition={isLoading || communityLoading}>
              <span className="ml-3 spinner spinner-white"></span>
            </RenderIf>
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
