import React, { useState } from "react";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress/index";
import { Box } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Tab } from "@mui/material";
import Delete from "./modals/Remove";
import ReportsTable from "./components/ReportsTable";
import { updateObject } from "../../../Helpers/updateObject";
import { RenderIf } from "../../components";
import {
  useGetCommunityReportsQuery,
  useGetReportsQuery,
} from "../../../redux/api/Activity/activityApi";
import RemoveReport from "./modals/RemoveReport";

export function Reports() {
  const { user } = useSelector((state) => state.user);
  const permissions = user?.user?.permissions;
  const [page, setPage] = React.useState(0);
  const [value, setValue] = useState(1);
  const [discussionId, setDiscussionId] = useState({});
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [deleteUsersIds, setDeleteUsersIds] = useState([]);
  const [showRemove, setShowRemove] = useState(false);
  const [showRemoveReport, setShowRemoveReport] = useState(false);

  const [filterInputs, setFilterInputs] = useState({
    firstName: "",
  });
  const handleChangeValue = (event, newValue) => {
    setValue(newValue);
    setPage(1);
  };

  const { data, isLoading, refetch } = useGetReportsQuery({});
  const {
    data: communityData,
    isLoading: communityLoading,
    refetch: communityDataRefetch,
  } = useGetCommunityReportsQuery({});
  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  const filterInputsUpdate = (e) => {
    const updateFiltersInput = updateObject(filterInputs, {
      [e.target.name]: e.target.value,
    });
    setFilterInputs(updateFiltersInput);
  };
  const filterStatusesUpdate = (key, value) => {
    const updateFiltersInput = updateObject(filterInputs, {
      [key]: value,
    });

    setFilterInputs(updateFiltersInput);
  };
  return (
    <div className="row bg-white rounded d-flex  justify-content-center">
      <div className="col-12 d-flex align-items-center py-4 height-65">
        <h1 className="display-4 mb-0">Reports</h1>
      </div>

      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={String(value)}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <TabList
              onChange={handleChangeValue}
              aria-label="lab API tabs example"
            >
              <Tab className="mx-3" label="Discussion Reports" value="1" />
              <Tab
                className="mx-3"
                label="Community Discussuion Reports"
                value="2"
              />
            </TabList>
          </Box>
          <TabPanel className="text-center" value="1">
            <RenderIf condition={isLoading}>
              <div>
                <CircularProgress />
              </div>
            </RenderIf>
            <RenderIf condition={data && !isLoading}>
              <ReportsTable
                rows={data}
                count={data}
                rowsPerPage={rowsPerPage}
                page={page}
                setShowRemove={setShowRemove}
                setShowRemoveReport={setShowRemoveReport}
                setDiscussionId={setDiscussionId}
                discussionId={discussionId}
                permissions={permissions}
                setRowsPerPage={setRowsPerPage}
                setPage={setPage}
                setDeleteUsersID={setDeleteUsersIds}
                deleteUsersIds={deleteUsersIds}
                filterInputsUpdate={filterInputsUpdate}
                filterInputs={filterInputs}
                setFilterInputs={setFilterInputs}
                filterStatusesUpdate={filterStatusesUpdate}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </RenderIf>
          </TabPanel>
          <TabPanel className="text-center" value="2">
            <RenderIf condition={communityLoading}>
              <div>
                <CircularProgress />
              </div>
            </RenderIf>
            <RenderIf condition={communityData && !communityLoading}>
              <ReportsTable
                rows={communityData}
                count={communityData}
                rowsPerPage={rowsPerPage}
                page={page}
                setShowRemove={setShowRemove}
                setShowRemoveReport={setShowRemoveReport}
                setDiscussionId={setDiscussionId}
                discussionId={discussionId}
                permissions={permissions}
                setRowsPerPage={setRowsPerPage}
                setPage={setPage}
                setDeleteUsersID={setDeleteUsersIds}
                deleteUsersIds={deleteUsersIds}
                filterInputsUpdate={filterInputsUpdate}
                filterInputs={filterInputs}
                setFilterInputs={setFilterInputs}
                filterStatusesUpdate={filterStatusesUpdate}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </RenderIf>
          </TabPanel>
        </TabContext>
      </Box>

      <RenderIf condition={showRemove}>
        <Delete
          id={discussionId}
          show={showRemove}
          onHide={setShowRemove}
          refetch={refetch}
          communityDataRefetch={communityDataRefetch}
          filterInputs={filterInputs}
        />
      </RenderIf>
      <RenderIf condition={showRemoveReport}>
        <RemoveReport
          id={discussionId}
          show={showRemoveReport}
          onHide={setShowRemoveReport}
          refetch={refetch}
          communityDataRefetch={communityDataRefetch}
          filterInputs={filterInputs}
        />
      </RenderIf>
    </div>
  );
}
