import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";

export const domainsApi = createApi({
  reducerPath: "domainsApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getDomains: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, name } = filter;
        return {
          url: `acceptabledomain/filter?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            name ? `&Name=${name.name}` : ""
          }`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          // dispatch(setInterests(data.data));
        } catch (error) {}
      },
    }),
    getDomain: builder.query({
      query(id) {
        return {
          url: `acceptabledomain/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    createDomain: builder.mutation({
      query(form) {
        return {
          url: "acceptabledomain",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    updateDomain: builder.mutation({
      query(form) {
        return {
          url: "acceptabledomain",
          method: "PUT",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    deleteDomain: builder.mutation({
      query(id) {
        return {
          url: `acceptabledomain`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),
  }),
});

export const {
  useCreateDomainMutation,
  useGetDomainQuery,
  useDeleteDomainMutation,
  useGetDomainsQuery,
  useUpdateDomainMutation,
} = domainsApi;
