import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    interests: [],
};

export const interestsSlice = createSlice({
    initialState,
    name: "interestsSlice",

    reducers: {
        setInterests: (state, action) => {
            state.interests = action.payload;
        },
    },
});

export default interestsSlice.reducer;
export const { setInterests } = interestsSlice.actions;
