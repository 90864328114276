import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField/index";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useUpdateBackOfficeUserPasswordMutation } from "../../../../../../redux/api/Auth/BackOfficeUsers/backofficeUsersApi";
import { RenderIf } from "../../../../../../app/components";

const initialValues = {
  oldPassword: "",
  newPassword: "",
  newPasswordConfirm: "",
};

export default function ChangePassword(props) {
  const { onHide, id, show, setMessage } = props;
  const [isError, setIsError] = useState("");
  const [disabled, setDisabled] = useState(false);

  const [values, setValues] = useState({
    oldPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
    showOldPassword: false,
    showNewPassword: false,
    showRePassword: false,
  });

  const handleClickShowPassword = (key) => {
    setValues({ ...values, [key]: !values[key] });
  };

  const [
    updateUserPassword,
    { isLoading, error, isSuccess, status },
  ] = useUpdateBackOfficeUserPasswordMutation();

  const PasswordSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,

        "The password must contain upper and lower case letters, numbers and symbols"
      )
      .required("Password is required"),
    newPassword: Yup.string()

      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        "The password must contain upper and lower case letters, numbers and symbols"
      )
      .required("New Password is required"),
    newPasswordConfirm: Yup.string()
      .when("newPassword", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newPassword")],
          "Both passwords must be the same"
        ),
      })
      .required("New Password is required"),
  });

  const onChangeUserPassword = (values, resetForm, setSubmitting) => {
    const changeUser = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
      newPasswordConfirm: values.newPasswordConfirm,
    };
    updateUserPassword(changeUser);
  };

  const getInputClasses = (filedName) => {
    if (formik.touched[filedName] && formik.errors[filedName]) {
      return true;
    }
    if (formik.touched[filedName] && !formik.errors[filedName]) {
      return false;
    }

    return false;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: PasswordSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      onChangeUserPassword(values, resetForm, setSubmitting);
      resetForm();
    },
  });
  useEffect(() => {
    if (isSuccess) {
      // handleClick();
      onHide();
    }
  }, [isLoading]);

  return (
    <Modal
      onHide={onHide}
      show={show}
      size="sm"
      aria-labelledby="edit"
      centered
      className="modal-center pr-0"
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="changePassword">Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="oldPassword"
                    className="w-100"
                    type={values.showOldPassword ? "text" : "password"}
                    label="Current Password"
                    // value={values.newPassword}
                    error={getInputClasses("oldPassword")}
                    {...formik.getFieldProps("oldPassword")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={() =>
                              handleClickShowPassword("showOldPassword")
                            }
                          >
                            <RenderIf
                              condition={values.showOldPassword}
                              renderElse={<Visibility />}
                            >
                              <VisibilityOff />
                            </RenderIf>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <RenderIf
                  condition={
                    formik.touched.oldPassword && formik.errors.oldPassword
                  }
                  renderElse={null}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.oldPassword}
                    </div>
                  </div>
                </RenderIf>
              </div>

              <div className="col-12 col-md-12 col-lg-12">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="newPassword"
                    className="w-100"
                    type={values.showNewPassword ? "text" : "password"}
                    label="New Password"
                    // value={values.confirmPassword}
                    error={getInputClasses("newPassword")}
                    {...formik.getFieldProps("newPassword")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={() =>
                              handleClickShowPassword("showNewPassword")
                            }
                          >
                            <RenderIf
                              condition={values.showOldPassword}
                              renderElse={<Visibility />}
                            >
                              <VisibilityOff />
                            </RenderIf>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <RenderIf
                  condition={
                    formik.touched.newPassword && formik.errors.newPassword
                  }
                  renderElse={null}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.newPassword}
                    </div>
                  </div>
                </RenderIf>
                <RenderIf condition={isError}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block MuiFormLabel-root Mui-error">
                      {isError}
                    </div>
                  </div>
                </RenderIf>
              </div>

              <div className="col-12 col-md-12 col-lg-12">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="newPasswordConfirm"
                    className="w-100"
                    type={values.showRePassword ? "text" : "password"}
                    label="Repeat New Password"
                    // value={values.newPassword}
                    error={getInputClasses("newPasswordConfirm")}
                    {...formik.getFieldProps("newPasswordConfirm")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={() =>
                              handleClickShowPassword("showRePassword")
                            }
                          >
                            <RenderIf
                              condition={values.showOldPassword}
                              renderElse={<Visibility />}
                            >
                              <VisibilityOff />
                            </RenderIf>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <RenderIf
                  condition={
                    formik.touched.newPasswordConfirm &&
                    formik.errors.newPasswordConfirm
                  }
                  renderElse={null}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.newPasswordConfirm}
                    </div>
                  </div>
                </RenderIf>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
            }}
          >
            Close
          </Button>
          <Button type="submit" disabled={disabled} className={`px-9`}>
            Save
            <RenderIf condition={disabled}>
              <span className="ml-3 spinner spinner-white"></span>
            </RenderIf>
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
