import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@mui/material/Checkbox";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import { RenderIf, ReactSelect, RichTextEditor } from "../../../../components";
import { formatDate } from "../../../../../Helpers/formatDate";
import { dateConvertItemOther } from "../../../../../Helpers/dateConvertOther";
import DatePickers from "../../../../components/ReactDatePicker/DatePicker";
import {
  useGetAllCommunityEventTypeWithoutFilterQuery,
  useGetCommunitiesWithoutFilterQuery,
  useGetCommunityEventQuery,
  useUpdateCommunityEventMutation,
} from "../../../../../redux/api/Community/communityApi";

const { REACT_APP_BASE_PHOTO_URL_MAIN } = process.env;
const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function Edit(props) {
  const { onHide, show, refetch, id } = props;
  const [eventTypes, setEventTypes] = useState([]);
  const [eventType, setEventType] = React.useState(null);
  const [communityTypeOptions, setCommunityTypeOptions] = useState([]);
  const [communityTypeOption, setCommunityTypeOption] = React.useState(null);
  const [eventFormat, setEventFormat] = React.useState(null);
  const [content, setContent] = useState();
  const {
    data: eventData,
    isFetching,
    isSuccess: dataSuccess,
  } = useGetCommunityEventQuery(id);
  const [fromClock, setFromClock] = useState(null);
  const [toClock, setToClock] = useState(null);
  const [date, setDate] = useState(null);
  const [isChecked, setIsChecked] = useState(null);
  const handleCheckedChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const { data: allType } = useGetAllCommunityEventTypeWithoutFilterQuery();
  const { data: communityType } = useGetCommunitiesWithoutFilterQuery();

  const options = [
    { value: 1, label: "Online" },
    { value: 2, label: "Offline" },
  ];
  function handleChangeFormat(value, setFieldValue) {
    setEventFormat((prevState) => value);
    setFieldValue("eventFormat", value.value);
  }

  function handleChangeType(value, setFieldValue) {
    setEventType((prevState) => value);
    setFieldValue("communityEventTypeId", value.value);
  }
  function handleChangeCommunityOption(value, setFieldValue) {
    setCommunityTypeOption((prevState) => value);
    setFieldValue("communityId", value.value);
  }
  useEffect(() => {
    setEventTypes(
      allType?.data?.map((suggestion) => ({
        value: suggestion.id,
        label: suggestion.title,
      }))
    );
  }, [allType]);

  useEffect(() => {
    setCommunityTypeOptions(
      communityType?.data?.map((suggestion) => ({
        value: suggestion.id,
        label: suggestion.title,
      }))
    );
  }, [communityType]);
  useEffect(() => {
    setCommunityTypeOption((prev) =>
      communityTypeOptions?.find(
        (option) => option.value === eventData?.communityId
      )
    );
  }, [eventData?.communityId, communityTypeOptions]);

  useEffect(() => {
    setEventType((prev) =>
      eventTypes?.find(
        (option) => option.value === eventData?.communityEventTypeId
      )
    );
  }, [eventData?.communityEventTypeId, eventTypes]);
  useEffect(() => {
    setEventFormat((prev) =>
      options?.find((option) => option.value === eventData?.eventFormat)
    );
  }, [eventData?.eventFormat]);

  useEffect(() => {
    if (!show) {
      setEventTypes(null);
    }
  }, [show]);

  const filePath = REACT_APP_BASE_PHOTO_URL_MAIN + eventData?.fileUrl;
  const [file, setFile] = useState("");
  const [preview, setPreview] = useState(true);
  const [imageSrc, setImageSrc] = useState(filePath);
  useEffect(() => {
    setImageSrc(filePath);
  }, [filePath]);

  const imagePreview = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    setFile(e.target.value);

    reader.onloadend = () => {
      setImageSrc(reader.result);
    };

    reader.readAsDataURL(file);

    setPreview(true);
  };
  const onClick = (e) => {
    setPreview(false);
    setImageSrc("");
    setFile("");
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [
    updateEvent,
    { isLoading, error, isSuccess },
  ] = useUpdateCommunityEventMutation();

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
    }
  }, [isLoading]);
  useEffect(() => {
    if (!isFetching && dataSuccess) {
      setIsChecked(eventData?.promoted);
    }
  }, [isFetching, dataSuccess]);

  const form = useRef(null);

  const EditSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    file: Yup.string().required("File is required"),

    description: Yup.string()
      .required("Description is required")
      .test("rich-text-not-empty", "Description cannot be empty", (value) => {
        if (typeof value !== "string") return false;
        const trimmedValue = value.trim();
        return trimmedValue !== "<p><br></p>" && trimmedValue !== "";
      }),
    link: Yup.string().required("Link format is required"),
    fromClock: Yup.string().required("Clock is required"),
    toClock: Yup.string().required("Clock is required"),
    date: Yup.string().required("Date is required"),
  });

  const getInputClasses = (touched, errors, FieldName) => {
    if (touched[FieldName] && errors[FieldName]) {
      return true;
    }
    if (touched[FieldName] && errors[FieldName]) {
      return false;
    }

    return false;
  };

  const onPutEvent = (values, resetForm, setSubmitting) => {
    const event = new FormData(form.current);
    event.append("Id", id);
    event.append("description", content);
    event.append("date", dateConvertItemOther(values.date));
    event.append("promoted", isChecked);
    event.append("link", values.link);
    event.append("eventFormat", String(values.eventFormat));
    event.append("communityEventTypeId", String(values.communityEventTypeId));
    event.append("communityId", String(values.communityId));
    updateEvent(event);
  };
  function getTime(dateString) {
    const date = new Date(dateString);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);

    return hours + ":" + minutes;
  }
  useEffect(() => {
    if (eventData) {
      setContent(eventData?.description);
    }
  }, [eventData]);
  return (
    <Modal
      size="xl"
      onHide={onHide}
      show={show}
      aria-labelledby="edit"
      className="modal-center pr-0"
      centered
    >
      <RenderIf
        condition={
          Object.keys(eventData || {}).length &&
          eventData?.constructor === Object
        }
      >
        <Formik
          initialValues={{
            title: eventData?.title,
            description: eventData?.description,
            communityId: eventData?.communityId,
            file: eventData?.fileUrl,
            communityEventTypeId: eventData?.communityEventTypeId,
            eventFormat: eventData?.eventFormat,
            promoted: eventData?.promoted,
            link: eventData?.link,
            fromClock: eventData?.fromClock,
            toClock: eventData?.toClock,
            date: eventData?.date,
          }}
          validationSchema={EditSchema}
          onSubmit={(values, { resetForm, setSubmitting }) => {
            onPutEvent(values, resetForm, setSubmitting);
          }}
        >
          {({
            values,
            errors,
            touched,
            setValues,
            setFieldValue,
            handleSubmit,
            handleChange,
            handleBlur,
            resetForm,
          }) => (
            <>
              <form onSubmit={handleSubmit} ref={form}>
                <Modal.Header closeButton>
                  <Modal.Title id="edit">Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body id="userModal">
                  <div className="col-12 pl-4 pr-4">
                    <div className="row">
                      <div className="col-12 col-md-12 col-lg-6">
                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                          <TextField
                            id="title"
                            name="title"
                            label="Title"
                            placeholder="Title"
                            className="w-100"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={getInputClasses(touched, errors, "title")}
                            onKeyDown={handleEnter}
                          />
                        </div>
                        <RenderIf condition={touched.title && errors.title}>
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.title}</div>
                          </div>
                        </RenderIf>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6">
                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                          <TextField
                            id="link"
                            name="link"
                            label="Link"
                            placeholder="Link"
                            className="w-100"
                            value={values.link}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={getInputClasses(touched, errors, "link")}
                            onKeyDown={handleEnter}
                          />
                        </div>
                        <RenderIf
                          condition={touched.link && errors.link}
                          renderElse={null}
                        >
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.link}</div>
                          </div>
                        </RenderIf>
                      </div>

                      <div className="col-12 col-md-12 col-lg-6">
                        <ReactSelect
                          options={options}
                          onChange={(value) =>
                            handleChangeFormat(value, setFieldValue)
                          }
                          single={eventFormat}
                          placeholder="Event Format"
                          inputId="eventFormat"
                        />
                      </div>

                      <div className="col-12 col-md-12 col-lg-6">
                        <DatePicker
                          selected={fromClock}
                          value={getTime(values.fromClock)}
                          id="fromClock"
                          name="fromClock"
                          dateFormat="h:mm aa"
                          onChange={(value) => {
                            setFromClock(value);
                            setFieldValue("fromClock", value);
                          }}
                          placeholderText="From Clock"
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={5}
                          timeCaption="Time"
                        />
                      </div>
                      <div className="col-12 col-md-12 col-lg-6">
                        <ReactSelect
                          options={eventTypes}
                          onChange={(value) =>
                            handleChangeType(value, setFieldValue)
                          }
                          single={eventType}
                          placeholder="Event Type"
                          inputId="eventTypeId"
                        />
                      </div>

                      <div className="col-12 col-md-12 col-lg-6">
                        <DatePicker
                          selected={toClock}
                          id="toClock"
                          value={getTime(values.toClock)}
                          name="toClock"
                          dateFormat="h:mm aa"
                          onChange={(value) => {
                            setToClock(value);
                            setFieldValue("toClock", value);
                          }}
                          placeholderText="To Clock"
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={5}
                          timeCaption="Time"
                        />
                      </div>
                      <div className="col-12 col-md-12 col-lg-6">
                        <ReactSelect
                          options={communityTypeOptions}
                          onChange={(value) =>
                            handleChangeCommunityOption(value, setFieldValue)
                          }
                          single={communityTypeOption}
                          classNamePrefix="type-select"
                          placeholder="Event Type"
                          instanceId="communityId"
                        />
                      </div>
                      <div className="col-12 col-md-12 col-lg-6">
                        <DatePickers
                          selected={date}
                          value={values.date}
                          id="date"
                          name="date"
                          dateFormat="dd-MM-yyyy"
                          onChange={(value) => {
                            setDate(value);
                            setFieldValue("date", formatDate(value));
                          }}
                          placeholderText="Date"
                        />
                      </div>
                      <div className="col-12 col-md-12 col-lg-6">
                        <div className="w-100 pt-2 pb-3">
                          <label htmlFor="contained-button-file">
                            <input
                              accept="image/*"
                              id="contained-button-file"
                              type="file"
                              name="file"
                              label="File {Path}"
                              placeholder="File Path"
                              className="w-100"
                              onChange={(e) => {
                                imagePreview(e);
                                setValues({
                                  ...values,
                                  file: e.target.files[0],
                                });
                              }}
                              value={file}
                            />
                          </label>

                          <RenderIf condition={imageSrc}>
                            <div className="image-preview mt-4">
                              <div
                                style={{
                                  position: "relative",
                                  width: "100px",
                                  height: "100px",
                                }}
                              >
                                <img
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                    borderRadius: "100%",
                                  }}
                                  src={imageSrc}
                                  alt="img"
                                />
                                <button
                                  style={{
                                    position: "absolute",
                                    right: "-30px",
                                  }}
                                  className="BtnSvg"
                                  onClick={() => {
                                    onClick();
                                    setFieldValue("file", "");
                                  }}
                                >
                                  <i
                                    style={{
                                      fontSize: "10px",
                                    }}
                                    className="flaticon2-delete"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </RenderIf>
                        </div>
                        <RenderIf condition={touched.file && errors.file}>
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.file}</div>
                          </div>
                        </RenderIf>
                      </div>

                      <div className="col-12 col-md-12 col-lg-6">
                        <div className="d-flex align-items-center">
                          <Checkbox
                            {...label}
                            // defaultChecked
                            color="success"
                            onChange={handleCheckedChange}
                            checked={isChecked}
                          />
                          <h6 className="mb-0">Promoted</h6>
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-12">
                        <div className="w-100 pt-2 pb-3">
                          <RichTextEditor
                            onChange={(newContent) => {
                              const trimmedContent = newContent.trim();
                              const isEmpty = trimmedContent === "<p><br></p>";
                              setContent(isEmpty ? "" : newContent);
                              setFieldValue(
                                "description",
                                isEmpty ? "" : newContent
                              );
                            }}
                            value={content}
                          />
                        </div>
                        <RenderIf
                          condition={touched.description && errors.description}
                        >
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {errors.description}
                            </div>
                          </div>
                        </RenderIf>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="light"
                    onClick={() => {
                      onHide(false);
                      resetForm();
                    }}
                  >
                    Close
                  </Button>
                  <Button type="submit" disabled={isLoading} className={`px-9`}>
                    Save
                    <RenderIf condition={isLoading}>
                      <span className="ml-3 spinner spinner-white"></span>
                    </RenderIf>
                  </Button>
                </Modal.Footer>
              </form>
            </>
          )}
        </Formik>
      </RenderIf>
    </Modal>
  );
}
