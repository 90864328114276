import React, { useState, useEffect } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useFormik } from "formik";
import * as Yup from "yup";
import crocusoft from "./image.png";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { useLoginUserMutation } from "../../../../redux/api/Auth/authApi";
import { RenderIf } from "../../../components";

const initialValues = {
  userName: "",
  password: "",
};

function Login(props) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loginUser, { isLoading, error }] = useLoginUserMutation();

  const [values, setValues] = useState({
    showPassword: false,
  });

  const handleClickShowPassword = (key) => {
    setValues({ ...values, [key]: !values[key] });
  };

  const LoginSchema = Yup.object().shape({
    // email: Yup.string()
    // .email("Email formatı yanlışdır")
    // .min(3, "Ən az 3 simvol olmalıdır")
    // .max(50, "Ən çox 50 simvol ola bilər")
    // .required("Email boş ola bilməz"),
    password: Yup.string()
      .min(3, "Must be at least 3 characters")
      .max(50, "Maximum of 50 characters")
      .required(" Password is required"),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (isLoading) {
        enableLoading();
      }
      loginUser(values);
    },
  });
  const today = new Date().getFullYear();
  useEffect(() => {
    if (error) {
      setErrorMessage(
        <Stack sx={{ width: "100%", height: "100px" }} spacing={2}>
          <Alert severity="error">This is an error alert — check it out!</Alert>
        </Stack>
      );
    }
  }, [error]);

  return (
    <div
      className="login-form login-signin d-flex flex-column h-100"
      id="kt_login_signin_form"
    >
      <div className="text-center mb-10 mb-lg-20 mt-auto">
        <img
          alt="Topaz logo"
          className="max-h-70px form_logo"
          src={toAbsoluteUrl("/media/logos/PashaHubLogo.png")}
        />
      </div>

      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="UserName"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "username"
            )}`}
            name="userName"
            {...formik.getFieldProps("userName")}
          />
          <RenderIf
            condition={formik.touched.username && formik.errors.username}
          >
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.username}</div>
            </div>
          </RenderIf>
        </div>
        <div className="form-group fv-plugins-icon-container">
          <TextField
            placeholder="Password"
            type={values.showPassword ? "text" : "password"}
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    style={{ marginRight: "5px" }}
                    aria-label="Toggle password visibility"
                    onClick={() => handleClickShowPassword("showPassword")}
                  >
                    <RenderIf
                      condition={values.showPassword}
                      renderElse={<Visibility />}
                    >
                      <VisibilityOff />
                    </RenderIf>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <RenderIf
            condition={formik.touched.password && formik.errors.password}
            renderElse={null}
          >
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          </RenderIf>
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={isLoading}
            style={{
              color: "#fff",
              background: "#057953",
            }}
            className={`btn  font-weight-bold px-9 py-4 my-3`}
          >
            <span>Login</span>
            <RenderIf condition={isLoading}>
              <span className="ml-3 spinner spinner-white"></span>
            </RenderIf>
          </button>
        </div>
        <RenderIf condition={errorMessage}>
          <div className="fv-plugins-message-container">
            <div className="fv-help-block MuiFormLabel-root Mui-error">
              {errorMessage}
            </div>
          </div>
        </RenderIf>
      </form>
      <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-auto">
        <div className="opacity-70 font-weight-bold">
          <a
            href="https://crocusoft.com/"
            target="_blank"
            style={{ paddingTop: "5px", textDecoration: "none" }}
            rel="noreferrer"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  color: "#0293A6",
                  fontSize: "16px",
                  paddingRight: "2px",
                }}
              >
                Designed & Developed by
              </span>
              <img
                src={crocusoft}
                alt="Crocusoft-logo"
                style={{
                  width: "25px",
                  height: "25px",
                  objectFit: "cover",
                }}
              />
              <span
                style={{
                  color: "#0293A6",
                  fontSize: "16px",
                  paddingRight: "2px",
                  fontWeight: "bold",
                }}
              >
                Crocusoft
              </span>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Login;
