import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import AlertComponent from "../../../../../_metronic/_helpers/AlertComponent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Member from "../../../../media/userIcon.png";
import { withStyles } from "@material-ui/core";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import CircularProgress from "@material-ui/core/CircularProgress/index";
import { RenderIf } from "../../../../components";
import { dateConvertItemOther } from "../../../../../Helpers/dateConvertOther";
const { REACT_APP_BASE_PHOTO_URL_MAIN } = process.env;

const headRows = [
  {
    id: "Complete time",
    numeric: false,
    disablePadding: true,
    label: "Complete time",
  },
  {
    id: "Score",
    numeric: false,
    disablePadding: true,
    label: "Score",
  },
  {
    id: "Max score",
    numeric: false,
    disablePadding: true,
    label: "Max Score",
  },
  {
    id: "PassPercent",
    numeric: false,
    disablePadding: true,
    label: "PassPercent",
  },
  {
    id: "Status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  {
    id: "Start date",
    numeric: false,
    disablePadding: true,
    label: "Start date",
  },
  {
    id: "Finished",
    numeric: false,
    disablePadding: true,
    label: "Finished",
  },
  {
    id: "Progress",
    numeric: false,
    disablePadding: true,
    label: "Course Progress",
  },
];

function EnhancedTableHead(props) {
  const { progressOrder, setProgressOrder } = props;
  return (
    <TableHead>
      <TableRow>
        <StickyTableCell
          align="left"
          padding="none"
          sortDirection={false}
        ></StickyTableCell>
        <StickyTableCell align="left" padding="none" sortDirection={false}>
          Name
        </StickyTableCell>
        <StickyTableCell align="left" padding="none" sortDirection={false}>
          Course Name
        </StickyTableCell>
        {headRows.map((row) => (
          <TableCell
            key={row.id}
            align="left"
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={false}
          >
            <RenderIf condition={row.id === "Progress"} renderElse={row.label}>
              <span className="mx-3">{row.label}</span>
              <select
                value={progressOrder}
                onChange={(e) => setProgressOrder(e.target.value)}
                id="progressOrder"
              >
                <option value="1">High to low</option>
                <option value="2">Low to high</option>
              </select>
            </RenderIf>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.bool,
  orderBy: PropTypes.string,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    flexWrap: "wrap",
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
  label: {
    fontSize: ".875rem",
    width: "100%",
  },
  toolbar: {
    zIndex: "10",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, tableHeaderRef } = props;

  return (
    <Toolbar
      className={clsx(classes.root, classes.toolbar, {
        [classes.highlight]: numSelected,
      })}
      ref={tableHeaderRef}
    >
      <div className="d-flex w-100">
        <div className={classes.title}>
          <RenderIf condition={numSelected}>
            <Typography color="inherit" variant="subtitle1">
              {numSelected} selected
            </Typography>
          </RenderIf>
        </div>
        <div className={classes.spacer} />
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  height: {
    height: "calc(100% - 65px)",
  },
  root: {
    width: "100%",
    height: "100%",
    padding: "24px 0",
  },
  paper: {
    width: "100%",
    height: "100%",
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowY: "scroll",
    maxHeight: 150,
  },
  toolbar: {
    minHeight: theme.spacing(2),
  },
  progresRoot: {
    width: "100%",
    padding: "32px 16px",
  },
  progress: {
    margin: "auto",
    display: "block",
    color: "#e2241b",
  },
}));
const StickyTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
    left: 0,
    position: "sticky",
    zIndex: 99,
  },
  body: {
    backgroundColor: "white",
    minWidth: "50px",
    left: 0,
    position: "sticky",
    zIndex: 99,
  },
}))(TableCell);
export default function EnhancedTable(props) {
  const {
    progressOrder,
    setProgressOrder,
    sortField,
    orderBy,
    setOpenRemoveAll,
    setOpenCreate,
    isLoaded,
    error,
    rows,
    count,
    setDeleteUsersID,
    deleteUsersIds,
    setPage,
    setRowsPerPage,
    page,
    rowsPerPage,
    filterInputsUpdate,
    filterInputs,
    setFilterInputs,
    filterStatusesUpdate,
    handleChangeRowsPerPage,
    handleChangePage,
    permissions,
  } = props;
  const classes = useStyles();
  const { Library_View, Library_Add } = permissions;
  function handleRequestSort() {
    setRowsPerPage(10);
    setPage(0);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);

      setDeleteUsersID(newSelecteds);
      return;
    }

    setDeleteUsersID([]);
  }

  const isSelected = (id) => deleteUsersIds?.includes(id);

  const { pathname } = useLocation();

  const content = useRef(null);
  const tableHeader = useRef(null);
  const tableWrapperHeader = useRef(null);

  const [tableWrapperHeight, setTableWrapperHeight] = useState(0);

  useEffect(() => {
    let paperHeigth = content?.current?.offsetHeight;
    let tableHeaderHeight = tableHeader?.current?.offsetHeight;
    setTableWrapperHeight(paperHeigth - tableHeaderHeight - 52);
  }, [pathname]);
  return (
    <div className={`col-12 ${classes.height}`}>
      <div className={classes.root}>
        <Paper className={classes.paper} ref={content}>
          <RenderIf condition={isLoaded && !rows.length}>
            <div className={classes.progresRoot}>
              <CircularProgress className={classes.progress} />
            </div>
          </RenderIf>

          <EnhancedTableToolbar
            numSelected={deleteUsersIds.length}
            setOpenCreate={setOpenCreate}
            setOpenRemoveAll={setOpenRemoveAll}
            Library_Add={Library_Add}
            filterInputsUpdate={filterInputsUpdate}
            filterInputs={filterInputs}
            setFilterInputs={setFilterInputs}
            filterStatusesUpdate={filterStatusesUpdate}
            tableHeaderRef={tableHeader}
          />
          <div
            className={classes.tableWrapper}
            ref={tableWrapperHeader}
            style={{ maxHeight: tableWrapperHeight + "px" }}
          >
            <RenderIf condition={rows?.length && Library_View}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                stickyHeader
              >
                <EnhancedTableHead
                  numSelected={deleteUsersIds.length}
                  order={orderBy}
                  progressOrder={progressOrder}
                  setProgressOrder={setProgressOrder}
                  orderBy={sortField}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {rows?.map((row, index) => {
                    const isItemSelected = isSelected(row?.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <StickyTableCell padding="checkbox">
                          <div className="my-2">
                            <img
                              style={{
                                width: "40px",
                                height: "40px",
                                objectFit: "cover",
                                borderRadius: "100%",
                              }}
                              src={
                                row?.siteUserFileUrl
                                  ? `${REACT_APP_BASE_PHOTO_URL_MAIN}${row?.siteUserFileUrl}`
                                  : Member
                              }
                              alt="user"
                            />
                          </div>
                        </StickyTableCell>
                        <StickyTableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          className="pr-4 p-2"
                        >
                          {row?.siteUserName}
                        </StickyTableCell>
                        <StickyTableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          className="pr-4 p-2"
                        >
                          {row?.hubDevCourseTitle}
                        </StickyTableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          className="pr-4 p-2"
                        >
                          {row?.completeTimeInMinutes} min
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          className="pr-4 p-2"
                        >
                          {row?.score}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          className="pr-4 p-2"
                        >
                          {row?.maxScore}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          className="pr-4 p-2"
                        >
                          {row?.passPercent}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          className="pr-4 p-2"
                        >
                          {row?.status}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          className="pr-4 p-2"
                        >
                          {dateConvertItemOther(row?.startDate)}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          className="pr-4 p-2 text-center"
                        >
                          {row?.isFinished ? <DoneIcon /> : <ClearIcon />}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          className="pr-4 p-2"
                        >
                          <Box sx={{ width: "100%" }}>
                            <LinearProgressWithLabel
                              value={row?.progressPercent}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </RenderIf>
            <RenderIf condition={!Library_View}>
              <AlertComponent variant="info" message="Not Found" />
            </RenderIf>
            <RenderIf condition={!rows.length && !isLoaded && !error}>
              <AlertComponent variant="info" message="Not Found" />
            </RenderIf>
            <RenderIf condition={!isLoaded && error}>
              <AlertComponent
                variant="danger"
                message="We've lost the connection"
              />
            </RenderIf>
          </div>
          <RenderIf condition={count > 10}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { value: count, label: "All" }]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              labelRowsPerPage="Line count:"
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </RenderIf>
        </Paper>
      </div>
    </div>
  );
}
