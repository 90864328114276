import React from "react";
import { Search } from "@material-ui/icons";
import "./SearchInput.scss";

const SearchInput = ({
    placeholder,
    filterInputsUpdate,
    filterInputs,
    setFilterInputs,
    label,
    field,
    name,
}) => {
    return (
        <div className="search-input">
            <Search />
            <input
                id={name}
                name={name}
                label={label}
                className="w-100"
                value={field}
                onChange={(e) => filterInputsUpdate(e)}
                type="text"
                placeholder={placeholder}
            />
        </div>
    );
};

export default SearchInput;
