import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  useGetChooseUserQuery,
  useUpdateUsersMutation,
} from "../../../../redux/api/Auth/Users/usersApi";
import { RenderIf } from "../../../../app/components";

export default function Edit(props) {
  const { onHide, show, roles, refetch, id } = props;

  const { data } = useGetChooseUserQuery(id);
  const [disabled, setDisabled] = useState(false);
  const [pvalues, setPValues] = useState({
    password: "",
    showPassword: false,
  });
  const handleClickShowPassword = (key) => {
    setPValues({ ...pvalues, [key]: !pvalues[key] });
  };
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [
    updateUsers,
    { isLoading, error, isSuccess, isFetching },
  ] = useUpdateUsersMutation();

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
    }
  }, [isLoading]);

  const onPutUser = (values, resetForm, setSubmitting) => {
    const userUpdate = {
      id: id,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      position: values.position,
      // roleId: values.roleId,
    };
    updateUsers(userUpdate);
  };

  const EditSchema = Yup.object().shape({
    // userName: Yup.string().required(
    //     "UserName is required"
    // ),
    firstName: Yup.string().required("Name is required."),
    lastName: Yup.string().required("LastName is required"),
    position: Yup.string().required("Position is required"),
    email: Yup.string()
      .email("Wrong email format. E.g. jhon.doe@example.com")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    // phone: Yup.string().required(
    //     "Phone is required"
    // ),
    //   // roleId: Yup.string().required(
    //   //   "Role is required"
    //   // ),
  });

  const getInputClasses = (touched, errors, FieldName) => {
    if (touched[FieldName] && errors[FieldName]) {
      return true;
    }
    if (touched[FieldName] && errors[FieldName]) {
      return false;
    }

    return false;
  };

  return (
    <Modal
      size="xl"
      onHide={onHide}
      show={show}
      aria-labelledby="edit"
      className="modal-center pr-0"
      centered
    >
      <RenderIf
        condition={
          data && Object.keys(data).length !== 0 && data.constructor === Object
        }
      >
        <Formik
          initialValues={{
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
            position: data?.position,
            // roleId: data?.roleId,
          }}
          validationSchema={EditSchema}
          onSubmit={(values, { resetForm, setSubmitting }) => {
            onPutUser(values, resetForm, setSubmitting);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            handleBlur,
            resetForm,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title id="edit">Edit</Modal.Title>
              </Modal.Header>
              <Modal.Body id="userModal">
                <div className="col-12 pl-4 pr-4">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-6">
                      <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                        <TextField
                          id="firstName"
                          name="firstName"
                          label="Ad"
                          placeholder="Ad"
                          className="w-100"
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getInputClasses(touched, errors, "firstName")}
                          onKeyDown={handleEnter}
                        />
                      </div>
                      <RenderIf
                        condition={touched.firstName && errors.firstName}
                        renderElse={null}
                      >
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.firstName}
                          </div>
                        </div>
                      </RenderIf>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6">
                      <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                        <TextField
                          id="lastName"
                          name="lastName"
                          label="Soyad"
                          placeholder="Soyad"
                          className="w-100"
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getInputClasses(touched, errors, "lastName")}
                          onKeyDown={handleEnter}
                        />
                      </div>
                      <RenderIf
                        condition={touched.lastName && errors.lastName}
                        renderElse={null}
                      >
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.lastName}</div>
                        </div>
                      </RenderIf>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6">
                      <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                        <TextField
                          id="email"
                          name="email"
                          label="Email"
                          placeholder="Email"
                          className="w-100"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getInputClasses(touched, errors, "email")}
                          onKeyDown={handleEnter}
                        />
                      </div>
                      <RenderIf
                        condition={touched.email && errors.email}
                        renderElse={null}
                      >
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.email}</div>
                        </div>
                      </RenderIf>
                    </div>
                    <div className="col-12 col-md-12 col-lg-6">
                      <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                        <TextField
                          id="position"
                          name="position"
                          label="position"
                          placeholder="Position"
                          className="w-100"
                          value={values.position}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getInputClasses(touched, errors, "position")}
                          onKeyDown={handleEnter}
                        />
                      </div>
                      <RenderIf
                        condition={touched.position && errors.position}
                        renderElse={null}
                      >
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.position}</div>
                        </div>
                      </RenderIf>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="light"
                  onClick={() => {
                    onHide(false);
                    resetForm();
                  }}
                >
                  Close
                </Button>
                <Button type="submit" disabled={disabled} className={`px-9`}>
                  Save
                  <RenderIf condition={disabled}>
                    <span className="ml-3 spinner spinner-white"></span>
                  </RenderIf>
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </RenderIf>
    </Modal>
  );
}
