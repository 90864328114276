import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";

export const additionalLinksApi = createApi({
    reducerPath: "additionalLinksApi",
    baseQuery: APIBaseQuery,
    keepUnusedDataFor: 1,
    endpoints: (builder) => ({
        getLinks: builder.query({
            query(filter) {
                const { skip, take, field, orderBy, name } = filter;
                return {
                    url: `additionalLink/filter?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
                        name ? `&Name=${name.name}` : ""
                    }`,
                };
            },
            keepUnusedDataFor: 1,
            transformResponse: (result) => result,
        }),
        getLink: builder.query({
            query(id) {
                return {
                    url: `additionalLink/byId?id=${id}`,
                };
            },
            keepUnusedDataFor: 1,
            transformResponse: (result) => result,
        }),
        createLink: builder.mutation({
            query(data) {
                return {
                    url: "additionalLink",
                    method: "POST",
                    data: data,
                };
            },
            transformResponse: (result) => result,
        }),
        updateLink: builder.mutation({
            query(data) {
                return {
                    url: "additionalLink",
                    method: "PUT",
                    data: data,
                };
            },
            transformResponse: (result) => result,
        }),
        deleteLink: builder.mutation({
            query(id) {
                return {
                    url: `additionalLink`,
                    method: "DELETE",
                    data: { id: id },
                };
            },
        }),
    }),
});

export const {
    useCreateLinkMutation,
    useDeleteLinkMutation,
    useGetLinkQuery,
    useGetLinksQuery,
    useUpdateLinkMutation,
} = additionalLinksApi;
