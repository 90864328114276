import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import ReactSelect from "../../UI/ReactSelect";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  useGetInterestQuery,
  useUpdateInterestMutation,
} from "../../../../redux/api/Interests/interestsApi";
import { RenderIf } from "../../../components";

export default function Edit(props) {
  const { onHide, show, refetch, id } = props;
  const { data } = useGetInterestQuery(id);

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [
    updateCities,
    { isLoading, error, isSuccess },
  ] = useUpdateInterestMutation();

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
    }
  }, [isLoading]);

  const onPutRegionalCenters = (values, resetForm, setSubmitting) => {
    const regionalCenter = {
      id: id,
      name: values.name,
    };
    updateCities(regionalCenter);
  };

  const EditSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  const getInputClasses = (touched, errors, FieldName) => {
    if (touched[FieldName] && errors[FieldName]) {
      return true;
    }
    if (touched[FieldName] && errors[FieldName]) {
      return false;
    }

    return false;
  };

  return (
    <Modal
      size="xl"
      onHide={onHide}
      show={show}
      aria-labelledby="edit"
      className="modal-center pr-0"
      centered
    >
      <RenderIf
        condition={
          data && Object.keys(data).length !== 0 && data?.constructor === Object
        }
      >
        <Formik
          initialValues={{
            name: data?.name,
          }}
          validationSchema={EditSchema}
          onSubmit={(values, { resetForm, setSubmitting }) => {
            onPutRegionalCenters(values, resetForm, setSubmitting);
            resetForm();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            handleBlur,
            resetForm,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title id="edit">Edit</Modal.Title>
              </Modal.Header>
              <Modal.Body id="userModal">
                <div className="col-12 pl-4 pr-4">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-6">
                      <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                        <TextField
                          id="name"
                          name="name"
                          label="Name"
                          placeholder="Name"
                          className="w-100"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getInputClasses(touched, errors, "name")}
                          onKeyDown={handleEnter}
                        />
                      </div>
                      <RenderIf
                        condition={touched.name && errors.name}
                        renderElse={null}
                      >
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.name}</div>
                        </div>
                      </RenderIf>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="light"
                  onClick={() => {
                    onHide(false);
                    resetForm();
                  }}
                >
                  Close
                </Button>
                <Button type="submit" disabled={isLoading} className={`px-9`}>
                  Save
                  <RenderIf condition={isLoading}>
                    <span className="ml-3 spinner spinner-white"></span>
                  </RenderIf>
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </RenderIf>
    </Modal>
  );
}
