import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RenderIf } from "../../../../components";
import { useCreateHubDevCategoryMutation } from "../../../../../redux/api/Library/libraryApi";

export default function Create(props) {
  const { show, onHide, refetch } = props;
  const form = useRef(null);
  const [file, setFile] = useState("");
  const [imageSrc, setImageSrc] = useState("");

  const imagePreview = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    setFile(file);
    formik.setFieldValue("fileUrl", file);

    reader.onloadend = () => {
      setImageSrc(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const onClick = (e) => {
    setImageSrc("");
    setFile("");
  };
  const initialValues = {
    title: "",
    fileUrl: "",
  };
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [
    createCategory,
    { isLoading, isSuccess },
  ] = useCreateHubDevCategoryMutation();

  const CreateSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    fileUrl: Yup.string().required("File is required"),
  });

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }

    return false;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      const formData = new FormData(form.current);

      formData.append("title", String(values.title));
      formData.append("fileUrl", file);
      createCategory(formData);
    },
  });

  const { resetForm } = formik;

  const onHideModal = () => {
    onHide();
    resetForm();
    setImageSrc("");
    setFile("");
  };

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
      resetForm();
    }
  }, [isLoading]);

  return (
    <Modal
      size="xl"
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
      show={show}
      onHide={onHideModal}
    >
      <form onSubmit={formik.handleSubmit} ref={form}>
        <Modal.Header closeButton>
          <Modal.Title id="create">Add</Modal.Title>
        </Modal.Header>
        <Modal.Body id="userModal">
          <div className="col-12 pl-4 pr-4">
            <div className="row align-items-center">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="title"
                    name="title"
                    label="Title"
                    placeholder="Title"
                    className="w-100"
                    error={getInputClasses("title")}
                    {...formik.getFieldProps("title")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={formik.touched.title && formik.errors.title}
                  renderElse={null}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.title}</div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6 mt-3">
                <input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  name="fileUrl"
                  label="File"
                  placeholder="File"
                  className="w-100"
                  onChange={imagePreview}
                />
                <RenderIf
                  condition={formik.touched.fileUrl && formik.errors.fileUrl}
                  renderElse={null}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.fileUrl}</div>
                  </div>
                </RenderIf>

                <RenderIf condition={imageSrc}>
                  <div className="image-preview mt-4">
                    <div
                      style={{
                        position: "relative",
                        width: "100px",
                        height: "100px",
                      }}
                    >
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          borderRadius: "100%",
                        }}
                        src={imageSrc}
                        alt="img"
                      />
                      <button
                        style={{
                          position: "absolute",
                          right: "-30px",
                        }}
                        className="BtnSvg"
                        onClick={onClick}
                      >
                        <i
                          style={{ fontSize: "10px" }}
                          className="flaticon2-delete"
                        ></i>
                      </button>
                    </div>
                  </div>
                </RenderIf>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
            }}
          >
            Close
          </Button>
          <Button type="submit" disabled={isLoading} className={`px-9`}>
            Add
            <RenderIf condition={isLoading}>
              <span className="ml-3 spinner spinner-white"></span>
            </RenderIf>
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
