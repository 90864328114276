import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.scss";

const DatePickers = ({
    onChange,
    selected,
    excludeDates,
    placeholderText,
    minDate,
    maxDate,
    readOnly,
    value,
    dateFormat,
}) => {
    return (
        <div className="styledDiv">
            <DatePicker
                formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                selected={selected}
                placeholderText={placeholderText}
                excludeDates={excludeDates}
                onChange={onChange}
                minDate={minDate}
                maxDate={maxDate}
                readOnly={readOnly}
                showPopperArrow={false}
                // locale={az}
                value={value}
                dateFormat={dateFormat}

                // showMonthYearPicker
            />
            <img
                className="datepicker-img"
                src="/media/svg/icons/Design/calendar-right.svg"
                alt=""
            />
        </div>
    );
};

export default DatePickers;
