import userReducer from "./features/User/userSlice";
import roleReducer from "./features/Role/RoleSlicer";
import progressReducer from "./features/Progress/slice";
import usersReducer from "./features/Users/usersSlice";
import { authApi } from "./api/Auth/authApi";
import { userApi } from "./api/Auth/userApi";
import { usersApi } from "./api/Auth/Users/usersApi";
import { backofficeUsersApi } from "./api/Auth/BackOfficeUsers/backofficeUsersApi";
import { rolesApi } from "./api/Roles/rolesApi";
import { interestsApi } from "./api/Interests/interestsApi";
import { contributorsApi } from "./api/Contributors/contributorsApi";
import { teamApi } from "./api/Team/teamApi";
import { valueAndEthicApi } from "./api/ValueAndEthic/valueAndEthicApi";
import { tagsApi } from "./api/Tags/tagsApi";
import { contentApi } from "./api/Content/contentApi";
import { contactApi } from "./api/Contact/contactApi";
import { libraryApi } from "./api/Library/libraryApi";
import { newsApi } from "./api/News/newsApi";
import { eventsApi } from "./api/Events/eventsApi";
import { reviewAndStoryApi } from "./api/ReviewAndStory/reviewAndStoryApi";
import { suggestApi } from "./api/Suggest/suggestApi";
import { activityApi } from "./api/Activity/activityApi";
import { partnersApi } from "./api/Partners/partnersApi";
import { communityApi } from "./api/Community/communityApi";
import { additionalLinksApi } from "./api/AdditionalLinks/additionalLinks";
import { notificationApi } from "./api/Notification/notificationApi";
import { badgeApi } from "./api/Badge/badgeApi";
import { discussionsApi } from "./api/Discussions/discussionsApi";
import { domainsApi } from "./api/Domains";

export const reducers = {
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  user: userReducer,
  usersState: usersReducer,
  rolesSlicer: roleReducer,
  progressSlicer: progressReducer,
  [suggestApi.reducerPath]: suggestApi.reducer,
  [discussionsApi.reducerPath]: discussionsApi.reducer,
  [eventsApi.reducerPath]: eventsApi.reducer,
  [newsApi.reducerPath]: newsApi.reducer,
  [activityApi.reducerPath]: activityApi.reducer,
  [libraryApi.reducerPath]: libraryApi.reducer,
  [tagsApi.reducerPath]: tagsApi.reducer,
  [valueAndEthicApi.reducerPath]: valueAndEthicApi.reducer,
  [rolesApi.reducerPath]: rolesApi.reducer,
  [contributorsApi.reducerPath]: contributorsApi.reducer,
  [interestsApi.reducerPath]: interestsApi.reducer,
  [teamApi.reducerPath]: teamApi.reducer,
  [contentApi.reducerPath]: contentApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [backofficeUsersApi.reducerPath]: backofficeUsersApi.reducer,
  [contactApi.reducerPath]: contactApi.reducer,
  [reviewAndStoryApi.reducerPath]: reviewAndStoryApi.reducer,
  [partnersApi.reducerPath]: partnersApi.reducer,
  [communityApi.reducerPath]: communityApi.reducer,
  [additionalLinksApi.reducerPath]: additionalLinksApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [badgeApi.reducerPath]: badgeApi.reducer,
  [domainsApi.reducerPath]: domainsApi.reducer,
};

export const middleWares = [
  domainsApi.middleware,
  suggestApi.middleware,
  authApi.middleware,
  userApi.middleware,
  tagsApi.middleware,
  eventsApi.middleware,
  libraryApi.middleware,
  activityApi.middleware,
  badgeApi.middleware,
  newsApi.middleware,
  notificationApi.middleware,
  valueAndEthicApi.middleware,
  contributorsApi.middleware,
  discussionsApi.middleware,
  usersApi.middleware,
  contentApi.middleware,
  teamApi.middleware,
  interestsApi.middleware,
  backofficeUsersApi.middleware,
  rolesApi.middleware,
  contactApi.middleware,
  partnersApi.middleware,
  reviewAndStoryApi.middleware,
  communityApi.middleware,
  additionalLinksApi.middleware,
];
