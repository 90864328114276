import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { useDeleteReviewMutation } from "../../../../redux/api/ReviewAndStory/reviewAndStoryApi";
import { RenderIf } from "../../../components";

const useStyles = makeStyles((theme) => ({
  textarea: {
    width: "100% !important",
    height: "100px !important",
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
}));

export default function Remove(props) {
  const classes = useStyles();
  const { onHide, show, id, refetch } = props;

  const [
    deleteReview,
    { isLoading, error, isSuccess },
  ] = useDeleteReviewMutation();

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
    }
  }, [isLoading]);

  const onDeleteMember = (e) => {
    e.preventDefault();
    deleteReview(id);
  };

  return (
    <Modal show={show} onHide={onHide} aria-labelledby="delete" centered>
      <form onSubmit={onDeleteMember}>
        <Modal.Header closeButton>
          <Modal.Title id="delete">Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 pl-4 pr-4">
            <div className="row">Are you sure to delete?</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => onHide(false)}>
            Cancel
          </Button>
          <Button type="submit">
            Delete
            <RenderIf condition={isLoading}>
              <span className="ml-3 spinner spinner-white"></span>
            </RenderIf>
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
