import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";

export const eventsApi = createApi({
  reducerPath: "eventsApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getAllEventType: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, title } = filter;
        return {
          url: `event/filterType?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            title ? `&Title=${title.title}` : ""
          }`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          // dispatch(setInterests(data.data));
        } catch (error) {}
      },
    }),
    getAllEventTypeWithoutFilter: builder.query({
      query(filter) {
        return {
          url: `event/filterType`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          // dispatch(setInterests(data.data));
        } catch (error) {}
      },
    }),
    getEventType: builder.query({
      query(id) {
        return {
          url: `event/type/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    createEventType: builder.mutation({
      query(form) {
        return {
          url: "event/type",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    updateEventType: builder.mutation({
      query(data) {
        return {
          url: "event/type",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
    }),
    deleteEventType: builder.mutation({
      query(id) {
        return {
          url: `event/type`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),

    getAllEvent: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, title } = filter;
        return {
          url: `event/filter?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            title ? `&Title=${title.title}` : ""
          }`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    getEvent: builder.query({
      query(id) {
        return {
          url: `event/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    createEvent: builder.mutation({
      query(form) {
        return {
          url: "event",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    updateEvent: builder.mutation({
      query(data) {
        return {
          url: "event",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
    }),
    deleteEvent: builder.mutation({
      query(id) {
        return {
          url: `event`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),

    rejectEvent: builder.mutation({
      query(form) {
        return {
          url: "event/reject",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    acceptEvent: builder.mutation({
      query(form) {
        return {
          url: "event/accept",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    updateEventMemberRole: builder.mutation({
      query(data) {
        return {
          url: "event/member/Role",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
    }),
    chooseManager: builder.mutation({
      query(form) {
        return {
          url: "event/manager",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
  }),
});

export const {
  useChooseManagerMutation,
  useUpdateEventMemberRoleMutation,
  useAcceptEventMutation,
  useRejectEventMutation,
  useCreateEventMutation,
  useCreateEventTypeMutation,
  useGetAllEventTypeWithoutFilterQuery,
  useDeleteEventMutation,
  useDeleteEventTypeMutation,
  useGetAllEventQuery,
  useGetAllEventTypeQuery,
  useGetEventQuery,
  useGetEventTypeQuery,
  useUpdateEventMutation,
  useUpdateEventTypeMutation,
} = eventsApi;
