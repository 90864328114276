import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";
import { setProgressData } from "../../features/Progress/slice";

export const libraryApi = createApi({
         reducerPath: "libraryApi",
         baseQuery: APIBaseQuery,
         keepUnusedDataFor: 1,
         endpoints: (builder) => ({
           getAllHubDevCategory: builder.query({
             query(filter) {
               const { skip, take, field, orderBy, title } = filter;
               return {
                 url: `hubdev/category/filter?${skip ? `&Skip=${skip}` : ""}${
                   take ? `&Take=${take}` : ""
                 }${field ? `&SortField=${field}` : ""}${
                   title.title ? `&Name=${title.title}` : ""
                 }${orderBy ? `&OrderBy=${orderBy}` : ""}`,
               };
             },
             keepUnusedDataFor: 1,
             transformResponse: (result) => result,
           }),
           getHubDevCategory: builder.query({
             query(id) {
               return {
                 url: `hubdev/hubdev/category/byId?id=${id}`,
               };
             },
             keepUnusedDataFor: 1,
             transformResponse: (result) => result,
           }),
           createHubDevCategory: builder.mutation({
             query(data) {
               return {
                 url: "hubdev/category",
                 method: "POST",
                 data,
               };
             },
             transformResponse: (result) => result,
           }),
           updateHubDevCategory: builder.mutation({
             query(data) {
               return {
                 url: "hubdev/category",
                 method: "PUT",
                 data: data,
               };
             },
             transformResponse: (result) => result,
           }),
           deleteHubDevCategory: builder.mutation({
             query(id) {
               return {
                 url: `hubdev/category`,
                 method: "DELETE",
                 data: { id: id },
               };
             },
           }),
           getAllLibrary: builder.query({
             query(filter) {
               const { skip, take, field, orderBy, name } = filter;
               return {
                 url: `library/filter?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
                   name ? `&Name=${name.name}` : ""
                 }`,
               };
             },
             keepUnusedDataFor: 1,
             transformResponse: (result) => result,
           }),
           getLibrary: builder.query({
             query(id) {
               return {
                 url: `library/byId?id=${id}`,
               };
             },
             keepUnusedDataFor: 1,
             transformResponse: (result) => result,
           }),
           createLibrary: builder.mutation({
             query(data) {
               return {
                 url: "library",
                 method: "POST",
                 data,
               };
             },
             transformResponse: (result) => result,
           }),
           updateLibrary: builder.mutation({
             query(data) {
               return {
                 url: "library",
                 method: "PUT",
                 data: data,
               };
             },
             transformResponse: (result) => result,
           }),
           deleteLibrary: builder.mutation({
             query(id) {
               return {
                 url: `library`,
                 method: "DELETE",
                 data: { id: id },
               };
             },
           }),
           createBanner: builder.mutation({
             query(data) {
               return {
                 url: "hubdev/ad",
                 method: "POST",
                 data,
               };
             },
             transformResponse: (result) => result,
           }),
           editBanner: builder.mutation({
             query(data) {
               return {
                 url: "hubdev/ad",
                 method: "PUT",
                 data,
               };
             },
             transformResponse: (result) => result,
           }),
           deleteBanner: builder.mutation({
             query(id) {
               return {
                 url: `hubdev/ad`,
                 method: "DELETE",
                 data: { id: id },
               };
             },
           }),
           getAllBanner: builder.query({
             query(filter) {
               const { skip, take, field, orderBy, name } = filter;
               return {
                 url: `hubdev/ad/filter`,
                 params: { skip, take, field, orderBy, name },
               };
             },
             keepUnusedDataFor: 1,
             transformResponse: (result) => result,
           }),
           getBanner: builder.query({
             query(id) {
               return {
                 url: `hubdev/ad/byId?id=${id}`,
               };
             },
             keepUnusedDataFor: 1,
             transformResponse: (result) => result,
           }),

           createCourseType: builder.mutation({
             query(data) {
               return {
                 url: "hubdev/course/type",
                 method: "POST",
                 data,
               };
             },
             transformResponse: (result) => result,
           }),
           editCourseType: builder.mutation({
             query(data) {
               return {
                 url: "hubdev/course/type",
                 method: "PUT",
                 data,
               };
             },
             transformResponse: (result) => result,
           }),
           deleteCourseType: builder.mutation({
             query(id) {
               return {
                 url: `hubdev/course/type`,
                 method: "DELETE",
                 data: { id: id },
               };
             },
           }),
           getAllCourseType: builder.query({
             query(filter) {
               const { skip, take, field, orderBy, name } = filter;
               return {
                 url: `hubdev/course/type/filter?${
                   skip ? `&Skip=${skip}` : ""
                 }${take ? `&Take=${take}` : ""}${
                   field ? `&SortField=${field}` : ""
                 }${name ? `&Name=${name}` : ""}${
                   orderBy ? `&OrderBy=${orderBy}` : ""
                 }`,
               };
             },
             keepUnusedDataFor: 1,
             transformResponse: (result) => result,
           }),
           getCourseType: builder.query({
             query(id) {
               return {
                 url: `hubdev/course/type/byId?id=${id}`,
               };
             },
             keepUnusedDataFor: 1,
             transformResponse: (result) => result,
           }),

           createCourseOrganization: builder.mutation({
             query(data) {
               return {
                 url: "hubdev/courseorganization",
                 method: "POST",
                 data,
               };
             },
             transformResponse: (result) => result,
           }),
           editCourseOrganization: builder.mutation({
             query(data) {
               return {
                 url: "hubdev/courseorganization",
                 method: "PUT",
                 data,
               };
             },
             transformResponse: (result) => result,
           }),
           deleteCourseOrganization: builder.mutation({
             query(id) {
               return {
                 url: `hubdev/courseorganization`,
                 method: "DELETE",
                 data: { id: id },
               };
             },
           }),
           getAllCourseOrganization: builder.query({
             query(filter) {
               const { skip, take, field, orderBy, name } = filter;
               return {
                 url: `hubdev/courseorganization/filter?${
                   skip ? `&Skip=${skip}` : ""
                 }
          ${take ? `&Take=${take}` : ""}${field ? `&SortField=${field}` : ""}${
                   name ? `&Name=${name}` : ""
                 }${orderBy ? `&OrderBy=${orderBy}` : ""}`,
               };
             },
             keepUnusedDataFor: 1,
             transformResponse: (result) => result,
           }),
           getCourseOrganization: builder.query({
             query(id) {
               return {
                 url: `hubdev/courseorganization/byId?id=${id}`,
               };
             },
             keepUnusedDataFor: 1,
             transformResponse: (result) => result,
           }),

           createCourse: builder.mutation({
             query(data) {
               return {
                 url: "hubdev/course",
                 method: "POST",
                 data,
               };
             },
             transformResponse: (result) => result,
           }),
           updateCourse: builder.mutation({
             query(data) {
               return {
                 url: "hubdev/course",
                 method: "PUT",
                 data: data,
               };
             },
             transformResponse: (result) => result,
           }),
           deleteCourse: builder.mutation({
             query(id) {
               return {
                 url: `hubdev/course`,
                 method: "DELETE",
                 data: { id: id },
               };
             },
           }),
           getAllCourse: builder.query({
             query(filter) {
               const { skip, take, field, orderBy, name } = filter;
               return {
                 url: `hubdev/course/filter?Skip=${skip}${
                   take ? `&Take=${take}` : ""
                 }${name ? `&Prompt=${name.name}` : ""}${
                   orderBy ? `&OrderBy=${orderBy}` : ""
                 }`,
               };
             },
             providesTags: ["Course"],
             keepUnusedDataFor: 1,
             transformResponse: (result) => result,
           }),

           getCourse: builder.query({
             query(id) {
               return {
                 url: `hubdev/course/byId?id=${id}`,
               };
             },
             keepUnusedDataFor: 1,
             transformResponse: (result) => result,
           }),
           getAllCourseProgress: builder.query({
             query(filter) {
               const { skip, take, orderBy, title, progressOrder } = filter;
               return {
                 url: `hubdev/courseProgress/filter?Skip=${skip}${
                   take ? `&Take=${take}` : ""
                 }${title.title ? `&Prompt=${title?.title}` : ""}${
                   title.firstName ? `&FirstName=${title.firstName}` : ""
                 }${title.lastName ? `&LastName=${title.lastName}` : ""}${
                   orderBy ? `&OrderBy=${orderBy}` : ""
                 }${progressOrder ? `&ProgressOrderBy=${progressOrder}` : ""}`,
               };
             },
             keepUnusedDataFor: 1,
             transformResponse: (result) => result,
             async onQueryStarted(args, { dispatch, queryFulfilled }) {
               try {
                 const { data } = await queryFulfilled;
                 dispatch(setProgressData(data));
               } catch (error) {}
             },
           }),
           getCourseProgress: builder.query({
             query(id) {
               return {
                 url: `hubdev/courseProgress/byId?id=${id}`,
               };
             },
             keepUnusedDataFor: 1,
             transformResponse: (result) => result,
           }),

           getExportLink: builder.query({
             query(filter) {
               const { skip, take, orderBy, title, progressOrder } = filter;

               return {
                 url: `hubdev/courseProgress/export?Skip=${skip}${
                   take ? `&Take=${take}` : ""
                 }${title.title ? `&Prompt=${title?.title}` : ""}${
                   title.firstName ? `&FirstName=${title.firstName}` : ""
                 }${title.lastName ? `&LastName=${title.lastName}` : ""}${
                   orderBy ? `&OrderBy=${orderBy}` : ""
                 }${progressOrder ? `&ProgressOrderBy=${progressOrder}` : ""}`,
               };
             },
             keepUnusedDataFor: 1,
             transformResponse: (result) => result,
           }),
           updateFilterOption: builder.mutation({
             query(data) {
               return {
                 url: "filter-option",
                 method: "PUT",
                 data: data,
               };
             },
             invalidatesTags: ["Course"],
             transformResponse: (result) => result,
           }),
           getFilterOption: builder.query({
             query(id) {
               return {
                 url: `filter-option/byId?id=${id}`,
               };
             },
             providesTags: ["Course"],
             keepUnusedDataFor: 1,
             transformResponse: (result) => result,
           }),
         }),
       });

export const {
  useGetFilterOptionQuery,
  useUpdateFilterOptionMutation,
  useLazyGetExportLinkQuery,
  useGetCourseProgressQuery,
  useGetAllCourseProgressQuery,
  useGetAllCourseQuery,
  useGetCourseQuery,
  useCreateCourseMutation,
  useUpdateCourseMutation,
  useDeleteCourseMutation,
  useCreateCourseOrganizationMutation,
  useEditCourseOrganizationMutation,
  useDeleteCourseOrganizationMutation,
  useGetAllCourseOrganizationQuery,
  useGetCourseOrganizationQuery,
  useCreateCourseTypeMutation,
  useEditCourseTypeMutation,
  useDeleteCourseTypeMutation,
  useGetAllCourseTypeQuery,
  useGetCourseTypeQuery,
  useGetBannerQuery,
  useGetAllBannerQuery,
  useDeleteBannerMutation,
  useEditBannerMutation,
  useCreateBannerMutation,
  useCreateLibraryMutation,
  useDeleteLibraryMutation,
  useGetAllLibraryQuery,
  useGetLibraryQuery,
  useUpdateLibraryMutation,
  useCreateHubDevCategoryMutation,
  useDeleteHubDevCategoryMutation,
  useGetAllHubDevCategoryQuery,
  useGetHubDevCategoryQuery,
  useUpdateHubDevCategoryMutation,
} = libraryApi;
