import React, { useState, useEffect, useRef } from "react";
import InputMask from "react-input-mask";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RenderIf } from "../../../components";
import { useCreateContactMutation } from "../../../../redux/api/Contact/contactApi";

export default function Create(props) {
  const { show, onHide, refetch } = props;
  const [isError, setIsError] = useState("");
  const [defaultState, setDefaultState] = useState(false);
  const form = useRef(null);
  const initialValues = {
    title: "",
    fax: "",
    email: "",
    location: "",
    phone: "",
    addressLatitude: "",
    addressLongtitude: "",
  };
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [createContact, { isLoading, isSuccess }] = useCreateContactMutation();

  const CreateSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    location: Yup.string().required("Loaction is required"),
    addressLongtitude: Yup.string().required("Longtitude is required"),
    addressLatitude: Yup.string().required("Latitude is required"),
    fax: Yup.string().required("Fax is required"),
    email: Yup.string()
      .email("Wrong email format. E.g. jhon.doe@example.com")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    // phone: Yup.string().required(
    //     "Phone is required"
    // ),
  });

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }

    return false;
  };

  const onPostEthic = (values, resetForm, setSubmitting) => {
    const asset = new FormData(form.current);
    createContact(asset);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      onPostEthic(values, resetForm, setSubmitting);
    },
  });

  const { setValues, resetForm, values } = formik;

  const onHideModal = () => {
    onHide();
    resetForm();
  };

  useEffect(() => {
    setIsError("");
  }, [show]);

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
      resetForm();
    }
  }, [isLoading]);

  return (
    <Modal
      size="xl"
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
      show={show}
      onHide={onHideModal}
    >
      <form onSubmit={formik.handleSubmit} ref={form}>
        <Modal.Header closeButton>
          <Modal.Title id="create">Add</Modal.Title>
        </Modal.Header>
        <Modal.Body id="userModal">
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="title"
                    name="title"
                    label="Name"
                    placeholder="Title"
                    className="w-100"
                    error={getInputClasses("title")}
                    {...formik.getFieldProps("title")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={formik.touched.title && formik.errors.title}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.title}</div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    placeholder="Email"
                    className="w-100"
                    error={getInputClasses("email")}
                    {...formik.getFieldProps("email")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={formik.touched.email && formik.errors.email}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="location"
                    name="location"
                    label="Location"
                    placeholder="Location"
                    className="w-100"
                    error={getInputClasses("location")}
                    {...formik.getFieldProps("location")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={formik.touched.location && formik.errors.location}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.location}
                    </div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="fax"
                    name="fax"
                    label="Fax"
                    placeholder="Fax"
                    className="w-100"
                    error={getInputClasses("fax")}
                    {...formik.getFieldProps("fax")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf condition={formik.touched.fax && formik.errors.fax}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.fax}</div>
                  </div>
                </RenderIf>
              </div>

              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <InputMask
                    mask="+994 (99) 999 99 99"
                    {...formik.getFieldProps("phone")}
                    disabled={false}
                    maskChar=""
                    id="phone"
                    type="number"
                  >
                    {() => (
                      <TextField
                        name="phone"
                        label="Phone"
                        placeholder="Phone"
                        className="w-100"
                        // error={getInputClasses("phone")}
                        // {...formik.getFieldProps(
                        //     "phone"
                        // )}
                      />
                    )}
                  </InputMask>
                </div>
                <RenderIf
                  condition={formik.touched.phone && formik.errors.phone}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.phone}</div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="addressLatitude"
                    name="addressLatitude"
                    label="AddressLatitude"
                    placeholder="AddressLatitude"
                    className="w-100"
                    error={getInputClasses("addressLatitude")}
                    {...formik.getFieldProps("addressLatitude")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={
                    formik.touched.addressLatitude &&
                    formik.errors.addressLatitude
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.addressLatitude}
                    </div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="addressLongtitude"
                    name="addressLongtitude"
                    label="AddressLongtitude"
                    placeholder="AddressLongtitude"
                    className="w-100"
                    error={getInputClasses("addressLongtitude")}
                    {...formik.getFieldProps("addressLongtitude")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={
                    formik.touched.addressLongtitude &&
                    formik.errors.addressLongtitude
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.addressLongtitude}
                    </div>
                  </div>
                </RenderIf>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
              setDefaultState(true);
            }}
          >
            Close
          </Button>
          <Button type="submit" disabled={isLoading} className={`px-9`}>
            Add
            <RenderIf condition={isLoading}>
              <span className="ml-3 spinner spinner-white"></span>
            </RenderIf>
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
