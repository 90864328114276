import React, { useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import * as Yup from "yup";
import { RenderIf } from "../../../components";
import {
  useGetDomainQuery,
  useUpdateDomainMutation,
} from "../../../../redux/api/Domains";
export default function Edit(props) {
  const { onHide, show, refetch, id } = props;
  const data = useGetDomainQuery(id);
  const datas = data?.currentData;

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [
    updateDomain,
    { isLoading, error, isSuccess },
  ] = useUpdateDomainMutation();

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
    }
  }, [isLoading]);

  const form = useRef(null);

  const EditSchema = Yup.object().shape({
    name: Yup.string().required("Domain is required"),
  });

  const getInputClasses = (touched, errors, FieldName) => {
    if (touched[FieldName] && errors[FieldName]) {
      return true;
    }
    if (touched[FieldName] && errors[FieldName]) {
      return false;
    }

    return false;
  };

  const onPutContributor = (values, resetForm, setSubmitting) => {
    const data = {
      id: id,
      name: values.name,
    };

    updateDomain(data);
  };

  return (
    <Modal
      size="xl"
      onHide={onHide}
      show={show}
      aria-labelledby="edit"
      className="modal-center pr-0"
      centered
    >
      <RenderIf
        condition={
          datas &&
          Object.keys(datas).length !== 0 &&
          datas.constructor === Object
        }
      >
        <Formik
          initialValues={{
            name: datas?.title,
          }}
          validationSchema={EditSchema}
          onSubmit={(values, { resetForm, setSubmitting }) => {
            onPutContributor(values, resetForm, setSubmitting);
          }}
        >
          {({
            values,
            errors,
            touched,
            setValues,
            setFieldValue,
            handleSubmit,
            handleChange,
            handleBlur,
            resetForm,
          }) => (
            <>
              <form onSubmit={handleSubmit} ref={form}>
                <Modal.Header closeButton>
                  <Modal.Title id="edit">Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body id="userModal">
                  <div className="col-12 pl-4 pr-4">
                    <div className="row">
                      <div className="col-12 col-md-12 col-lg-6">
                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                          <TextField
                            id="name"
                            name="name"
                            label="Domain"
                            placeholder="Domain"
                            className="w-100"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={getInputClasses(touched, errors, "name")}
                            onKeyDown={handleEnter}
                          />
                        </div>
                        <RenderIf
                          condition={touched.name && errors.name}
                          renderElse={null}
                        >
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.name}</div>
                          </div>
                        </RenderIf>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="light"
                    onClick={() => {
                      onHide(false);
                      resetForm();
                    }}
                  >
                    Close
                  </Button>
                  <Button type="submit" disabled={isLoading} className={`px-9`}>
                    Save
                    <RenderIf condition={isLoading}>
                      <span className="ml-3 spinner spinner-white"></span>
                    </RenderIf>
                  </Button>
                </Modal.Footer>
              </form>
            </>
          )}
        </Formik>
      </RenderIf>
    </Modal>
  );
}
