import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import FormControlLabel from "@material-ui/core/FormControlLabel/index";
import CircularProgress from "@material-ui/core/CircularProgress/index";
import Switch from "@material-ui/core/Switch/index";
import View from "./modals/View";
import Filter from "./modals/Filter";
import { updateObject } from "../../../../Helpers/updateObject";
import HubdevProgressTable from "./components/HubdevProgressTable";
import { RenderIf } from "../../../components";
import {
  useGetAllCourseProgressQuery,
  useLazyGetExportLinkQuery,
} from "../../../../redux/api/Library/libraryApi";
const { REACT_APP_BASE_URL } = process.env;

export function HubdevProgress() {
  const { progress } = useSelector((state) => state.progressSlicer);
  const [
    getExportLink,
    { isSuccess: exportSucess, isLoading: exportLoading },
  ] = useLazyGetExportLinkQuery();

  const [showView, setShowView] = React.useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const { user } = useSelector((state) => state.user);
  const permissions = user?.user?.permissions;
  const [progressOrder, setProgressOrder] = useState(1);

  const handleChange = () => setShowFilter(!showFilter);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowView(open);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [categoryId, setCategoryId] = useState(null);
  const [deleteUsersIds, setDeleteUsersIds] = useState([]);
  const [filterInputs, setFilterInputs] = useState({
    title: "",
    firstName: "",
    lastName: "",
  });

  const { data, isLoading } = useGetAllCourseProgressQuery({
    skip: page * rowsPerPage,
    take: rowsPerPage,
    orderBy: false,
    title: filterInputs,
    progressOrder: progressOrder == 1 ? "desc" : "asc",
  });
  const handleDowload = () => {
    getExportLink({
      skip: page * rowsPerPage,
      take: rowsPerPage,
      orderBy: false,
      title: filterInputs,
      progressOrder: progressOrder == 1 ? "desc" : "asc",
    });
  };

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  const filterInputsUpdate = (e) => {
    const updateFiltersInput = updateObject(filterInputs, {
      [e.target.name]: e.target.value,
    });
    setFilterInputs(updateFiltersInput);
  };
  const filterStatusesUpdate = (key, value) => {
    const updateFiltersInput = updateObject(filterInputs, {
      [key]: value,
    });

    setFilterInputs(updateFiltersInput);
  };
  useEffect(() => {
    if (exportSucess) {
      window.open(
        REACT_APP_BASE_URL +
          "hubdev/courseProgress/export?&JsonData=" +
          JSON.stringify(progress)
      );
    }
  }, [exportSucess]);
  return (
    <div className="row bg-white rounded d-flex  justify-content-center h-100">
      <div className="col-12 d-flex align-items-center py-4 height-65">
        <h1 className="display-4 mb-0">Course Progress</h1>
        <FormControlLabel
          control={
            <Switch
              checked={showFilter}
              onChange={handleChange}
              color="secondary"
            />
          }
          label={showFilter ? "Hide filter" : "Show filter"}
          className="ml-auto mb-0"
        />
        <button
          disabled={!data?.data?.length}
          type="button"
          class="btn btn-info"
          onClick={handleDowload}
        >
          Get excel
          <RenderIf condition={exportLoading}>
            <span className="mx-3 spinner spinner-white"></span>
          </RenderIf>
        </button>
      </div>
      <RenderIf condition={isLoading}>
        <div>
          <CircularProgress />
        </div>
      </RenderIf>
      <RenderIf condition={data && !isLoading} renderElse={""}>
        <HubdevProgressTable
          progressOrder={progressOrder}
          setProgressOrder={setProgressOrder}
          setOpenView={toggleDrawer(true)}
          rows={data?.data}
          count={data?.totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          permissions={permissions}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          setCategoryId={setCategoryId}
          setDeleteUsersID={setDeleteUsersIds}
          deleteUsersIds={deleteUsersIds}
          filterInputsUpdate={filterInputsUpdate}
          filterInputs={filterInputs}
          setFilterInputs={setFilterInputs}
          filterStatusesUpdate={filterStatusesUpdate}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </RenderIf>

      <RenderIf condition={showView}>
        <View open={showView} onClose={toggleDrawer(false)} id={categoryId} />
      </RenderIf>

      <Filter
        show={showFilter}
        onHide={handleChange}
        filterInputsUpdate={filterInputsUpdate}
        filterInputs={filterInputs}
        setFilterInputs={setFilterInputs}
      />
    </div>
  );
}
