import React from "react";
import { useLocation } from "react-router";
import { TiGroupOutline } from "react-icons/ti";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import {
  BiUserCircle,
  BiLibrary,
  BiComment,
  BiLink,
  BiCategory,
} from "react-icons/bi";
import { FaUserCheck, FaPeopleArrows } from "react-icons/fa";
import { TbBuildingCommunity } from "react-icons/tb";
import {
  MdOutlineSettingsSuggest,
  MdOutlineLocalActivity,
  MdOutlineEventAvailable,
  MdOutlinePolicy,
  MdContentCopy,
  MdNotifications,
  MdReportProblem,
} from "react-icons/md";
import { AiOutlineTeam, AiOutlineContacts } from "react-icons/ai";
import { DiUnitySmall } from "react-icons/di";
import { BsClipboard } from "react-icons/bs";
import { RxAccessibility } from "react-icons/rx";
import { CiHashtag } from "react-icons/ci";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { HiOutlineNewspaper, HiBadgeCheck } from "react-icons/hi";
import "./AsideMenuList.scss";
import { Link } from "react-router-dom";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const pathName = location.pathname;
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <ul className="menu-body">
        <li
          className={`menu-body_item ${
            pathName === "/dashboard" ? "li-active" : ""
          }`}
        >
          <Accordion
            expanded={expanded === "panel15"}
            onChange={handleChange("panel15")}
            className={` ${
              pathName.startsWith(`/dashboard`) ? "active-accordion" : ""
            }`}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div
                className={` ${
                  pathName.startsWith(`/dashboard`) ? "icon-active" : ""
                }`}
              >
                <BiUserCircle />
              </div>
              <span
                className={` ${
                  pathName.startsWith(`/dashboard`) ? "span-active" : ""
                }`}
              >
                Site Users
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <Link to="/dashboard">
                <div
                  className={`${
                    pathName === "/dashboard" ? "icon-active" : ""
                  }`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${
                    pathName === "/dashboard" ? "span-active" : ""
                  }`}
                >
                  Site Users
                </span>
              </Link>
              <Link className="mt-2" to="/dashboard/departments">
                <div
                  className={`${
                    pathName === "/dashboard/departments" ? "icon-active" : ""
                  }`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${
                    pathName === "/dashboard/departments" ? "span-active" : ""
                  }`}
                >
                  Departments
                </span>
              </Link>
              <Link className="mt-2" to="/dashboard/domains">
                <div
                  className={`${
                    pathName === "/dashboard/domains" ? "icon-active" : ""
                  }`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${
                    pathName === "/dashboard/domains" ? "span-active" : ""
                  }`}
                >
                  Domains
                </span>
              </Link>
              <Link className="mt-2" to="/dashboard/positions">
                <div
                  className={`${
                    pathName === "/dashboard/positions" ? "icon-active" : ""
                  }`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${
                    pathName === "/dashboard/positions" ? "span-active" : ""
                  }`}
                >
                  Positions
                </span>
              </Link>
              <Link className="mt-2" to="/dashboard/companies">
                <div
                  className={`${
                    pathName === "/dashboard/companies" ? "icon-active" : ""
                  }`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${
                    pathName === "/dashboard/companies" ? "span-active" : ""
                  }`}
                >
                  Companies
                </span>
              </Link>
            </AccordionDetails>
          </Accordion>
        </li>
        <li
          className={`menu-body_item ${
            pathName === "/backoffice-users" ? "li-active" : ""
          }`}
        >
          <Link to="/backoffice-users">
            <div
              className={`${
                pathName === "/backoffice-users" ? "icon-active" : ""
              }`}
            >
              <TiGroupOutline />
            </div>
            <span
              className={`${
                pathName === "/backoffice-users" ? "span-active" : ""
              }`}
            >
              Backoffice Users
            </span>
          </Link>
        </li>
        <li
          className={`menu-body_item ${
            pathName === "/policy" ? "li-active" : ""
          }`}
        >
          <Link to="/policy">
            <div className={`${pathName === "/policy" ? "icon-active" : ""}`}>
              <MdOutlinePolicy />
            </div>
            <span className={`${pathName === "/policy" ? "span-active" : ""}`}>
              Policy
            </span>
          </Link>
        </li>
        <li
          className={`menu-body_item ${
            pathName === "/roles" ? "li-active" : ""
          }`}
        >
          <Link to="/roles">
            <div className={`${pathName === "/roles" ? "icon-active" : ""}`}>
              <FaUserCheck />
            </div>
            <span className={`${pathName === "/roles" ? "span-active" : ""}`}>
              Roles
            </span>
          </Link>
        </li>
        <li
          className={`menu-body_item ${
            pathName === "/aboutUs" ? "li-active" : ""
          }`}
        >
          <Link to="/aboutUs">
            <div className={`${pathName === "/aboutUs" ? "icon-active" : ""}`}>
              <BiComment />
            </div>
            <span className={`${pathName === "/aboutUs" ? "span-active" : ""}`}>
              About Us
            </span>
          </Link>
        </li>
        <li
          className={`menu-body_item  ${
            pathName === "/activity" || pathName === "/activity/content"
              ? "li-active"
              : ""
          }`}
        >
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className={` ${
              pathName.startsWith(`/activity`) ? "active-accordion" : ""
            }`}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div
                className={` ${
                  pathName.startsWith(`/activity`) ? "icon-active" : ""
                }`}
              >
                <MdOutlineLocalActivity />
              </div>
              <span
                className={` ${
                  pathName.startsWith(`/activity`) ? "span-active" : ""
                }`}
              >
                Activity
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <Link to="/activity">
                <div
                  className={`${pathName === "/activity" ? "icon-active" : ""}`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${pathName === "/activity" ? "span-active" : ""}`}
                >
                  Activity
                </span>
              </Link>
              <Link className="mt-2" to="/activity/content">
                <div
                  className={`${
                    pathName === "/activity/content" ? "icon-active" : ""
                  }`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${
                    pathName === "/activity/content" ? "span-active" : ""
                  }`}
                >
                  Activity Content
                </span>
              </Link>
            </AccordionDetails>
          </Accordion>
        </li>
        <li
          className={`menu-body_item ${
            pathName === "/interests" ? "li-active" : ""
          }`}
        >
          <Link to="/interests">
            <div
              className={`${pathName === "/interests" ? "icon-active" : ""}`}
            >
              <BiUserCircle />
            </div>
            <span
              className={`${pathName === "/interests" ? "span-active" : ""}`}
            >
              Interests
            </span>
          </Link>
        </li>
        <li
          className={`menu-body_item ${
            pathName.startsWith(`/contributors`) ? "li-active" : ""
          }`}
        >
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            className={`${
              pathName.startsWith(`/contributors`) ? "active-accordion" : ""
            }`}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div
                className={` ${
                  pathName.startsWith(`/contributors`) ? "icon-active" : ""
                }`}
              >
                <AiOutlineTeam />
              </div>
              <span
                className={` ${
                  pathName.startsWith(`/contributors`) ? "span-active" : ""
                }`}
              >
                Contributors
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <Link to="/contributors">
                <div
                  className={`${
                    pathName === "/contributors" ? "icon-active" : ""
                  }`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${
                    pathName === "/contributors" ? "span-active" : ""
                  }`}
                >
                  Contributors
                </span>
              </Link>
              <Link className="mt-2" to="/contributors/content">
                <div
                  className={`${
                    pathName === "/contributors/content" ? "icon-active" : ""
                  }`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${
                    pathName === "/contributors/content" ? "span-active" : ""
                  }`}
                >
                  Contributors Content
                </span>
              </Link>
            </AccordionDetails>
          </Accordion>
        </li>
        <li
          className={`menu-body_item ${
            pathName === "/team" || pathName === "/team/content"
              ? "li-active"
              : ""
          }`}
        >
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            className={` ${
              pathName === "/team" || pathName === "/team/content"
                ? "active-accordion"
                : ""
            }`}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div
                className={` ${
                  pathName === "/team" || pathName === "/team/content"
                    ? "icon-active"
                    : ""
                }`}
              >
                <AiOutlineTeam />
              </div>
              <span
                className={` ${
                  pathName === "/team" || pathName === "/team/content"
                    ? "span-active"
                    : ""
                }`}
              >
                Team
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <Link to="/team">
                <div className={`${pathName === "/team" ? "icon-active" : ""}`}>
                  <MdContentCopy />
                </div>
                <span
                  className={`${pathName === "/team" ? "span-active" : ""}`}
                >
                  Team
                </span>
              </Link>
              <Link className="mt-2" to="/team/content">
                <div
                  className={`${
                    pathName === "/team/content" ? "icon-active" : ""
                  }`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${
                    pathName === "/team/content" ? "span-active" : ""
                  }`}
                >
                  Team Content
                </span>
              </Link>
            </AccordionDetails>
          </Accordion>
        </li>
        <li
          className={`menu-body_item ${
            pathName.startsWith(`/review`) ? "li-active" : ""
          }`}
        >
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            className={` ${
              pathName.startsWith(`/review`) ? "active-accordion" : ""
            }`}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div
                className={` ${
                  pathName.startsWith(`/review`) ? "icon-active" : ""
                }`}
              >
                <AiOutlineTeam />
              </div>
              <span
                className={` ${
                  pathName === "/review" || pathName === "/review/content"
                    ? "span-active"
                    : ""
                }`}
              >
                Review
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <Link to="/review">
                <div
                  className={`${pathName === "/review" ? "icon-active" : ""}`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${pathName === "/review" ? "span-active" : ""}`}
                >
                  Review
                </span>
              </Link>
              <Link className="mt-2" to="/review/content">
                <div
                  className={`${
                    pathName === "/review/content" ? "icon-active" : ""
                  }`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${
                    pathName === "/review/content" ? "span-active" : ""
                  }`}
                >
                  Review Content
                </span>
              </Link>
            </AccordionDetails>
          </Accordion>
        </li>
        <li
          className={`menu-body_item ${
            pathName.startsWith(`/story`) ? "li-active" : ""
          }`}
        >
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
            className={` ${
              pathName.startsWith(`/story`) ? "active-accordion" : ""
            }`}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div
                className={` ${
                  pathName.startsWith(`/story`) ? "icon-active" : ""
                }`}
              >
                <AiOutlineTeam />
              </div>
              <span
                className={` ${
                  pathName === "/story" || pathName === "/story/content"
                    ? "span-active"
                    : ""
                }`}
              >
                Story
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <Link to="/story">
                <div
                  className={`${pathName === "/story" ? "icon-active" : ""}`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${pathName === "/story" ? "span-active" : ""}`}
                >
                  Story
                </span>
              </Link>
              <Link className="mt-2" to="/story/content">
                <div
                  className={`${
                    pathName === "/story/content" ? "icon-active" : ""
                  }`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${
                    pathName === "/story/content" ? "span-active" : ""
                  }`}
                >
                  Story Content
                </span>
              </Link>
            </AccordionDetails>
          </Accordion>
        </li>
        <li
          className={`menu-body_item ${
            pathName.startsWith(`/valueOfUs`) ? "li-active" : ""
          }`}
        >
          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
            className={` ${
              pathName.startsWith(`/valueOfUs`) ? "active-accordion" : ""
            }`}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div
                className={` ${
                  pathName.startsWith(`/valueOfUs`) ? "icon-active" : ""
                }`}
              >
                <DiUnitySmall />{" "}
              </div>
              <span
                className={` ${
                  pathName === "/valueOfUs" || pathName === "/valueOfUs/content"
                    ? "span-active"
                    : ""
                }`}
              >
                Value Of Us
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <Link to="/valueOfUs">
                <div
                  className={`${
                    pathName === "/valueOfUs" ? "icon-active" : ""
                  }`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${
                    pathName === "/valueOfUs" ? "span-active" : ""
                  }`}
                >
                  Value Of Us
                </span>
              </Link>
              <Link className="mt-2" to="/valueOfUs/content">
                <div
                  className={`${
                    pathName === "/valueOfUs/content" ? "icon-active" : ""
                  }`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${
                    pathName === "/valueOfUs/content" ? "span-active" : ""
                  }`}
                >
                  Value Of Us Content
                </span>
              </Link>
            </AccordionDetails>
          </Accordion>
        </li>
        <li
          className={`menu-body_item ${
            pathName.startsWith(`/ethic`) ? "li-active" : ""
          }`}
        >
          <Accordion
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
            className={` ${
              pathName.startsWith(`/ethic`) ? "active-accordion" : ""
            }`}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div
                className={` ${
                  pathName.startsWith(`/ethic`) ? "icon-active" : ""
                }`}
              >
                <RxAccessibility />
              </div>
              <span
                className={` ${
                  pathName.startsWith(`/ethic`) ? "span-active" : ""
                }`}
              >
                Ethic
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <Link to="/ethic">
                <div
                  className={`${pathName === "/ethic" ? "icon-active" : ""}`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${pathName === "/ethic" ? "span-active" : ""}`}
                >
                  Ethic
                </span>
              </Link>
              <Link className="mt-2" to="/ethic/content">
                <div
                  className={`${
                    pathName === "/ethic/content" ? "icon-active" : ""
                  }`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${
                    pathName === "/ethic/content" ? "span-active" : ""
                  }`}
                >
                  Ethic Content
                </span>
              </Link>
            </AccordionDetails>
          </Accordion>
        </li>
        <li
          className={`menu-body_item ${
            pathName === "/tags" ? "li-active" : ""
          }`}
        >
          <Link to="/tags">
            <div className={`${pathName === "/tags" ? "icon-active" : ""}`}>
              <CiHashtag />
            </div>
            <span className={`${pathName === "/tags" ? "span-active" : ""}`}>
              Tags
            </span>
          </Link>
        </li>
        <li
          className={`menu-body_item ${
            pathName === "/news" ? "li-active" : ""
          }`}
        >
          <Link to="/news">
            <div className={`${pathName === "/news" ? "icon-active" : ""}`}>
              <HiOutlineNewspaper />
            </div>

            <Tooltip
              placement="right"
              className={`${pathName === "/news" ? "span-active" : ""}`}
            >
              <Button>News</Button>
            </Tooltip>
          </Link>
        </li>
        <li
          className={`menu-body_item ${
            pathName.startsWith(`/contact`) ? "li-active" : ""
          }`}
        >
          <Accordion
            expanded={expanded === "panel8"}
            onChange={handleChange("panel8")}
            className={` ${
              pathName.startsWith(`/contact`) ? "active-accordion" : ""
            }`}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div
                className={` ${
                  pathName.startsWith(`/contact`) ? "icon-active" : ""
                }`}
              >
                <AiOutlineContacts />
              </div>
              <span
                className={` ${
                  pathName === "/contact" || pathName === "/contact/content"
                    ? "span-active"
                    : ""
                }`}
              >
                Contact
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <Link to="/contact">
                <div
                  className={`${pathName === "/contact" ? "icon-active" : ""}`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${pathName === "/contact" ? "span-active" : ""}`}
                >
                  Contact
                </span>
              </Link>
              <Link className="mt-2" to="/contact/content">
                <div
                  className={`${
                    pathName === "/contact/content" ? "icon-active" : ""
                  }`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${
                    pathName === "/contact/content" ? "span-active" : ""
                  }`}
                >
                  Contact Content
                </span>
              </Link>
            </AccordionDetails>
          </Accordion>
        </li>
        <li
          className={`menu-body_item ${
            pathName.startsWith(`/suggest`) ? "li-active" : ""
          }`}
        >
          <Accordion
            expanded={expanded === "panel9"}
            onChange={handleChange("panel9")}
            className={` ${
              pathName.startsWith(`/suggest`) ? "active-accordion" : ""
            }`}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div
                className={` ${
                  pathName.startsWith(`/suggest`) ? "icon-active" : ""
                }`}
              >
                <MdOutlineSettingsSuggest />
              </div>
              <span
                className={` ${
                  pathName.startsWith(`/suggest`) ? "span-active" : ""
                }`}
              >
                Suggest
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <Link to="/suggest">
                <div
                  className={`${pathName === "/suggest" ? "icon-active" : ""}`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${pathName === "/suggest" ? "span-active" : ""}`}
                >
                  Suggest
                </span>
              </Link>
              <Link className="mt-2" to="/suggest/content">
                <div
                  className={`${
                    pathName === "/suggest/content" ? "icon-active" : ""
                  }`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${
                    pathName === "/suggest/content" ? "span-active" : ""
                  }`}
                >
                  Suggest Content
                </span>
              </Link>
              <Link className="mt-2" to="/suggest/complainReason">
                <div
                  className={`${
                    pathName === "/suggest/complainReason" ? "icon-active" : ""
                  }`}
                >
                  <BiCategory />
                </div>
                <span
                  className={`${
                    pathName === "/suggest/complainReason" ? "span-active" : ""
                  }`}
                >
                  Complain Reason
                </span>
              </Link>
            </AccordionDetails>
          </Accordion>
        </li>
        <li
          className={`menu-body_item ${
            pathName.startsWith(`/hubdev`) ? "li-active" : ""
          }`}
        >
          <Accordion
            expanded={expanded === "panel10"}
            onChange={handleChange("panel10")}
            className={` ${
              pathName.startsWith(`/hubdev`) ? "active-accordion" : ""
            }`}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div
                className={` ${
                  pathName.startsWith(`/hubdev`) ? "icon-active" : ""
                }`}
              >
                <BiLibrary />
              </div>

              <Tooltip
                placement="right"
                className={` ${
                  pathName.startsWith(`/hubdev`) ? "span-active" : ""
                }`}
              >
                <Button>Hubdev</Button>
              </Tooltip>
            </AccordionSummary>
            <AccordionDetails>
              <Link to="/hubdev">
                <div
                  className={`${pathName === "/hubdev" ? "icon-active" : ""}`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${pathName === "/hubdev" ? "span-active" : ""}`}
                >
                  Hubdev Course
                </span>
              </Link>
              <Link className="mt-2" to="/hubdev/content">
                <div
                  className={`${
                    pathName === "/hubdev/content" ? "icon-active" : ""
                  }`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${
                    pathName === "/hubdev/content" ? "span-active" : ""
                  }`}
                >
                  Hubdev Content
                </span>
              </Link>
              <Link className="mt-2" to="/hubdev/category">
                <div
                  className={`${
                    pathName === "/hubdev/category" ? "icon-active" : ""
                  }`}
                >
                  <BiCategory />
                </div>
                <span
                  className={`${
                    pathName === "/hubdev/category" ? "span-active" : ""
                  }`}
                >
                  Hubdev Category
                </span>
              </Link>
              <Link className="mt-2" to="/hubdev/banner">
                <div
                  className={`${
                    pathName === "/hubdev/banner" ? "icon-active" : ""
                  }`}
                >
                  <BiCategory />
                </div>
                <span
                  className={`${
                    pathName === "/hubdev/banner" ? "span-active" : ""
                  }`}
                >
                  Hubdev Banner
                </span>
              </Link>
              <Link className="mt-2" to="/hubdev/courseType">
                <div
                  className={`${
                    pathName === "/hubdev/courseType" ? "icon-active" : ""
                  }`}
                >
                  <BiCategory />
                </div>
                <span
                  className={`${
                    pathName === "/hubdev/courseType" ? "span-active" : ""
                  }`}
                >
                  Hubdev Course Type
                </span>
              </Link>
              <Link className="mt-2" to="/hubdev/courseOrganization">
                <div
                  className={`${
                    pathName === "/hubdev/courseOrganization"
                      ? "icon-active"
                      : ""
                  }`}
                >
                  <BiCategory />
                </div>
                <span
                  className={`${
                    pathName === "/hubdev/courseOrganization"
                      ? "span-active"
                      : ""
                  }`}
                >
                  Hubdev Course Organization
                </span>
              </Link>
              <Link className="mt-2" to="/hubdev/courseProgress">
                <div
                  className={`${
                    pathName === "/hubdev/courseProgress" ? "icon-active" : ""
                  }`}
                >
                  <BiCategory />
                </div>
                <span
                  className={`${
                    pathName === "/hubdev/courseProgress" ? "span-active" : ""
                  }`}
                >
                  Hubdev Course Progress
                </span>
              </Link>
            </AccordionDetails>
          </Accordion>
        </li>
        <li
          className={`menu-body_item ${
            pathName.startsWith(`/events`) ? "li-active" : ""
          } `}
        >
          <Accordion
            expanded={expanded === "panel11"}
            onChange={handleChange("panel11")}
            className={` ${
              pathName.startsWith(`/events`) ? "active-accordion" : ""
            }`}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div
                className={`${
                  pathName.startsWith(`/events`) ? "icon-active" : ""
                }`}
              >
                <MdOutlineEventAvailable />
              </div>

              <Tooltip
                placement="right"
                className={`${
                  pathName.startsWith(`/events`) ? "span-active" : ""
                }`}
              >
                <Button>Events</Button>
              </Tooltip>
            </AccordionSummary>
            <AccordionDetails>
              <Link to="/events">
                <div
                  className={`${pathName === "/events" ? "icon-active" : ""}`}
                >
                  <BiCategory />
                </div>
                <span
                  className={`${pathName === "/events" ? "span-active" : ""}`}
                >
                  Event
                </span>
              </Link>
              <Link className="mt-2" to="/events/eventType">
                <div
                  className={`${
                    pathName === "/events/eventType" ? "icon-active" : ""
                  }`}
                >
                  <BiCategory />
                </div>
                <span
                  className={`${
                    pathName === "/events/eventType" ? "span-active" : ""
                  }`}
                >
                  Event Type
                </span>
              </Link>
            </AccordionDetails>
          </Accordion>
        </li>
        <li
          className={`menu-body_item ${
            pathName.startsWith(`/community`) ? "li-active" : ""
          }`}
        >
          <Accordion
            expanded={expanded === "panel12"}
            onChange={handleChange("panel12")}
            className={` ${
              pathName.startsWith(`/community`) ? "active-accordion" : ""
            }`}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div
                className={`${
                  pathName.startsWith(`/community`) ? "icon-active" : ""
                }`}
              >
                <TbBuildingCommunity />
              </div>

              <Tooltip
                placement="right"
                className={`mx-3 ${
                  pathName.startsWith(`/community`) ? "span-active" : ""
                }`}
              >
                <Button>Community</Button>
              </Tooltip>
            </AccordionSummary>
            <AccordionDetails>
              <Link to="/community">
                <div
                  className={`${
                    pathName === "/community" ? "icon-active" : ""
                  }`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${
                    pathName === "/community" ? "span-active" : ""
                  }`}
                >
                  Community
                </span>
              </Link>
              <Link className="mt-2" to="/community/eventType">
                <div
                  className={`${
                    pathName === "/community/eventType" ? "icon-active" : ""
                  }`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${
                    pathName === "/community/eventType" ? "span-active" : ""
                  }`}
                >
                  Community Event Type
                </span>
              </Link>
              <Link className="mt-2" to="/community/events">
                <div
                  className={`${
                    pathName === "/community/events" ? "icon-active" : ""
                  }`}
                >
                  <BiCategory />
                </div>
                <span
                  className={`${
                    pathName === "/community/events" ? "span-active" : ""
                  }`}
                >
                  Community Events
                </span>
              </Link>
            </AccordionDetails>
          </Accordion>
        </li>
        <li
          className={`menu-body_item ${
            pathName === "/partners" || pathName === "/partners/content"
              ? "li-active"
              : ""
          }`}
        >
          <Accordion
            expanded={expanded === "panel13"}
            onChange={handleChange("panel13")}
            className={` ${
              pathName === "/partners" || pathName === "/partners/content"
                ? "active-accordion"
                : ""
            }`}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div
                className={` ${
                  pathName === "/partners" || pathName === "/partners/content"
                    ? "icon-active"
                    : ""
                }`}
              >
                <FaPeopleArrows />
              </div>
              <span
                className={` ${
                  pathName === "/partners" || pathName === "/partners/content"
                    ? "span-active"
                    : ""
                }`}
              >
                Partners
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <Link to="/partners">
                <div
                  className={`${pathName === "/partners" ? "icon-active" : ""}`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${pathName === "/partners" ? "span-active" : ""}`}
                >
                  Partners
                </span>
              </Link>
              <Link className="mt-2" to="/partners/content">
                <div
                  className={`${
                    pathName === "/partners/content" ? "icon-active" : ""
                  }`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${
                    pathName === "/partners/content" ? "span-active" : ""
                  }`}
                >
                  Partners Content
                </span>
              </Link>
            </AccordionDetails>
          </Accordion>
        </li>
        <li
          className={`menu-body_item ${
            pathName === "/additionalLinks" ? "li-active" : ""
          }`}
        >
          <Link to="/additionalLinks">
            <div
              className={`${
                pathName === "/additionalLinks" ? "icon-active" : ""
              }`}
            >
              <BiLink />
            </div>
            <span
              className={`${
                pathName === "/additionalLinks" ? "span-active" : ""
              }`}
            >
              Additional Links
            </span>
          </Link>
        </li>
        {/* <li
          className={`menu-body_item ${
            pathName === "/popup" ? "li-active" : ""
          }`}
        >
          <Link to="/popup">
            <div className={`${pathName === "/popup" ? "icon-active" : ""}`}>
              <MdNotifications />
            </div>
            <span className={`${pathName === "/popup" ? "span-active" : ""}`}>
              Popup
            </span>
          </Link>
        </li> */}
        <li
          className={`menu-body_item ${
            pathName === "/badge" ? "li-active" : ""
          }`}
        >
          <Link to="/badge">
            <div className={`${pathName === "/badge" ? "icon-active" : ""}`}>
              <HiBadgeCheck />
            </div>
            <span className={`${pathName === "/badge" ? "span-active" : ""}`}>
              Bagde
            </span>
          </Link>
        </li>
        <li
          className={`menu-body_item ${
            pathName === "/reports" ? "li-active" : ""
          }`}
        >
          <Link to="/reports">
            <div className={`${pathName === "/reports" ? "icon-active" : ""}`}>
              <MdReportProblem />
            </div>
            <span className={`${pathName === "/reports" ? "span-active" : ""}`}>
              Reports
            </span>
          </Link>
        </li>
        <li
          className={`menu-body_item ${
            pathName.startsWith(`/onboarding`) ? "li-active" : ""
          }`}
        >
          <Accordion
            expanded={expanded === "panel14"}
            onChange={handleChange("panel14")}
            className={` ${
              pathName.startsWith(`/onboarding`) ? "active-accordion" : ""
            }`}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div
                className={` ${
                  pathName.startsWith(`/onboarding`) ? "icon-active" : ""
                }`}
              >
                <BsClipboard />
              </div>
              <span
                className={` ${
                  pathName.startsWith(`/onboarding`) ? "span-active" : ""
                }`}
              >
                OnBoarding
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <Link to="/onboarding/stepfirst">
                <div
                  className={`${
                    pathName === "/onboarding/stepfirst" ? "icon-active" : ""
                  }`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${
                    pathName === "/onboarding/stepfirst" ? "span-active" : ""
                  }`}
                >
                  Step First
                </span>
              </Link>
              <Link className="mt-2 mb-2" to="/onboarding/stepsecond">
                <div
                  className={`${
                    pathName === "/onboarding/stepsecond" ? "icon-active" : ""
                  }`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${
                    pathName === "/onboarding/stepsecond" ? "span-active" : ""
                  }`}
                >
                  Step Second
                </span>
              </Link>
              <Link to="/onboarding/stepthird">
                <div
                  className={`${
                    pathName === "/onboarding/stepthird" ? "icon-active" : ""
                  }`}
                >
                  <MdContentCopy />
                </div>
                <span
                  className={`${
                    pathName === "/onboarding/stepthird" ? "span-active" : ""
                  }`}
                >
                  Step Third
                </span>
              </Link>
            </AccordionDetails>
          </Accordion>
        </li>
      </ul>
    </>
  );
}
