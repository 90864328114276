import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";

export const badgeApi = createApi({
    reducerPath: "badgeApi",
    baseQuery: APIBaseQuery,
    keepUnusedDataFor: 1,
    endpoints: (builder) => ({
        getBadges: builder.query({
            query(filter) {
                const { skip, take, field, orderBy, name } = filter;
                return {
                    url: `badge/filter?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
                        name ? `&Name=${name.name}` : ""
                    }`,
                };
            },
            keepUnusedDataFor: 1,
            transformResponse: (result) => result,
        }),
        getBadge: builder.query({
            query(id) {
                return {
                    url: `badge/byId?id=${id}`,
                };
            },
            keepUnusedDataFor: 1,
            transformResponse: (result) => result,
        }),
        createBadge: builder.mutation({
            query(data) {
                return {
                    url: "badge",
                    method: "POST",
                    data: data,
                };
            },
            transformResponse: (result) => result,
        }),
        createAddBadge: builder.mutation({
            query(data) {
                return {
                    url: "badge/addBadge",
                    method: "POST",
                    data: data,
                };
            },
            transformResponse: (result) => result,
        }),
        removeBadge: builder.mutation({
            query(data) {
                return {
                    url: "badge/removeBadge",
                    method: "DELETE",
                    data: data,
                };
            },
            transformResponse: (result) => result,
        }),
        updateBadge: builder.mutation({
            query(data) {
                return {
                    url: "badge",
                    method: "PUT",
                    data: data,
                };
            },
            transformResponse: (result) => result,
        }),
        deleteBadge: builder.mutation({
            query(id) {
                return {
                    url: `badge`,
                    method: "DELETE",
                    data: { id: id },
                };
            },
        }),
    }),
});

export const {
    useCreateAddBadgeMutation,
    useCreateBadgeMutation,
    useDeleteBadgeMutation,
    useGetBadgeQuery,
    useGetBadgesQuery,
    useUpdateBadgeMutation,
    useRemoveBadgeMutation,
} = badgeApi;
