import React from "react";
import ReactSelect, { components } from "react-select";
import RenderIf from "../RenderIf";
import chroma from "chroma-js";

const { Option, SingleValue } = components;
const IconOption = ({ options, ...props }) => (
  <Option {...props}>
    {props.data.icon}
    {props.data.label}
  </Option>
);

const ValueOption = ({ options, ...props }) => (
  <SingleValue {...props}>
    {props.data.icon}
    {props.data.label}
  </SingleValue>
);

const ReactCatagorySelect = ({
  label,
  placeholder,
  onChange,
  defaultValue,
  required,
  options,
  isMulti,
  single,
  isSearchable = false,
  menuPosition,
  classNamePrefix,
}) => {
  const colourStyles = {
    control: ({ styles, data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      width: "100%",
      minHeight: "52px",
      zIndex: "1",
      display: "flex",
      alignItems: "center",
      paddingLeft: "8px",
      borderRadius: "8px",
      border: "1px solid #b3b3b3",
      boxShadow: "var(--primary-shadow)",
      color: "#464a4d",
      "& svg:nth-of-type(2)": {
        color: "#464a4d",
      },
      "& svg:nth-of-type": {
        marginRight: "10px",
        width: "20px",
        height: "20px",
        marginBottom: "4px",
      },
      "& span": {
        display: "none",
      },
    }),

    singleValue: (styles, isFocused) => ({
      ...styles,
      color: "black",
      zIndex: "1",
      fontStyle: "normal",
      border: isFocused ? "1px solid #red" : "",
      fontWeight: 500,

      fontSize: "15px",
      "& svg": {
        marginRight: "10px",
        width: "20px",
        height: "20px",
        marginBottom: "4px",
      },
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "white",
        height: "30px",
        alignItems: "center",
        borderRadius: "16px",
        border: "1px solid #e0e0e0",
      };
    },
    valueContainer: (provided) => ({
      ...provided,
      minHeight: "30px",
      display: "inherit",
      overflow: "visible",
    }),

    placeholder: (styles) => ({
      ...styles,
      color: "#464a4d",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = data?.color ? chroma(data?.color) : "";
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? color
          : undefined,

        color: !data?.color
          ? isSelected || isFocused
            ? "#464a4d"
            : "#464a4d"
          : isSelected
          ? "#464a4d"
          : data?.color,
        cursor: isDisabled ? "not-allowed" : "default",
        "& svg": {
          marginRight: "10px",
          width: "20px",
          height: "20px",
          marginBottom: "4px",
        },
        "&:nth-last-of-type(1) ": {
          borderBottom: "none !important",
        },

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color
            : undefined,
        },
      };
    },

    options: ({ styles, data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        width: "100%",
      };
    },
  };

  return (
    <div className="Select">
      <label
        className="mb-2"
        style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "1rem" }}
      >
        <RenderIf condition={required} renderElse={<>{label}</>}>
          <>
            {label}{" "}
            <span className="text-danger" style={{ fontWeight: 600 }}>
              {" "}
              *
            </span>
          </>
        </RenderIf>
      </label>
      <ReactSelect
        styles={colourStyles}
        defaultValue={defaultValue}
        value={single}
        options={options}
        placeholder={placeholder}
        isMulti={isMulti}
        onChange={onChange}
        components={{ Option: IconOption, SingleValue: ValueOption }}
        menuPlacement="auto"
        isSearchable={isSearchable}
        id="category-value-select"
        instanceId="category-value-select"
        menuPosition={menuPosition}
        // menuIsOpen={true}
        // classNamePrefix={classNamePrefix}
        classNamePrefix="category-value-select"
      />
    </div>
  );
};

export default ReactCatagorySelect;
