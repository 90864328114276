export const videoTypes = ["mp4", "mov"];
export const fileTypes = ["pdf", "docs", "doc"];
export const complexitiesOptions = [
  { value: 1, label: "Easy" },
  { value: 2, label: "Medium" },
  { value: 3, label: "Hard" },
  { value: 4, label: "Very Hard" },
];
export const imgTypes = [
  "tif",
  "tiff",
  "bmp",
  "jpg",
  "jpeg",
  "gif",
  "png",
  "raw",
];
export const allVideoTypes = [
  "3gp",
  "avi",
  "flv",
  "mkv",
  "mov",
  "mp4",
  "mpeg",
  "mpg",
  "ogg",
  "rm",
  "vob",
  "webm",
  "wmv",
];
