import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";

export const partnersApi = createApi({
  reducerPath: "partnersApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getPartners: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, url } = filter;
        return {
          url: `partner/filter?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            url ? `&Url=${url.url}` : ""
          }`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          // dispatch(setInterests(data.data));
        } catch (error) {}
      },
    }),
    getPartner: builder.query({
      query(id) {
        return {
          url: `partner/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    createPartner: builder.mutation({
      query(form) {
        return {
          url: "partner",
          method: "POST",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    updatePartner: builder.mutation({
      query(form) {
        return {
          url: "partner",
          method: "PUT",
          data: form,
        };
      },
      transformResponse: (result) => result,
    }),
    deletePartner: builder.mutation({
      query(id) {
        return {
          url: `partner`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),
  }),
});

export const {
  useCreatePartnerMutation,
  useDeletePartnerMutation,
  useGetPartnerQuery,
  useGetPartnersQuery,
  useUpdatePartnerMutation,
} = partnersApi;
