import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RenderIf, RichTextEditor } from "../../../components";
import { useCreateTeamMemberMutation } from "../../../../redux/api/Team/teamApi";

export default function Create(props) {
  const { show, onHide, refetch } = props;
  const [content, setContent] = useState("");
  const [isError, setIsError] = useState("");
  const [defaultState, setDefaultState] = useState(false);
  const [file, setFile] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [preview, setPreview] = useState(false);

  const initialValues = {
    firstName: "",
    lastName: "",
    file: "",
    position: "",
    description: "",
    title: "",
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };
  const imagePreview = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    setFile(file);
    formik.setFieldValue("file", file);

    reader.onloadend = () => {
      setImageSrc(reader.result);
    };

    reader.readAsDataURL(file);

    setPreview(true);
  };

  const onClick = (e) => {
    setPreview(false);
    setImageSrc("");
    setFile("");
  };
  const handleContentChange = (content) => {
    setContent(content);
    formik.setFieldValue("description", content);
  };
  const [
    createTeamMember,
    { isLoading, error, isSuccess },
  ] = useCreateTeamMemberMutation();

  const CreateSchema = Yup.object().shape({
    firstName: Yup.string().required("Name is required"),
    lastName: Yup.string().required("LastName is required"),
    description: Yup.string()
      .required("Description is required")
      .test("rich-text-not-empty", "Description cannot be empty", (value) => {
        if (typeof value !== "string") return false;
        const trimmedValue = value.trim();
        return trimmedValue !== "<p><br></p>" && trimmedValue !== "";
      }),
    file: Yup.string().required("File is required"),
    position: Yup.string().required("Position is required"),
    title: Yup.string().required("Title is required"),
  });

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }

    return false;
  };

  const form = useRef();
  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values) => {
      const formData = new FormData(form.current);

      formData.append("firstName", String(values.firstName));
      formData.append("lastName", String(values.lastName));
      formData.append("position", String(values.position));
      formData.append("description", String(values.description));
      formData.append("title", String(values.title));

      formData.append("file", file);
      createTeamMember(formData);
    },
  });

  const { resetForm } = formik;
  const onHideModal = () => {
    onHide();
    resetForm();
    setPreview(false);
    setImageSrc("");
    setFile("");
  };

  useEffect(() => {
    setIsError("");
  }, [show]);

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
      resetForm();
    }
  }, [isLoading]);

  return (
    <Modal
      size="xl"
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
      show={show}
      onHide={onHideModal}
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="create">Add</Modal.Title>
        </Modal.Header>
        <Modal.Body id="userModal">
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="firstName"
                    name="firstName"
                    label="Name"
                    placeholder="Name"
                    className="w-100"
                    error={getInputClasses("firstName")}
                    {...formik.getFieldProps("firstName")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={
                    formik.touched.firstName && formik.errors.firstName
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.firstName}
                    </div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="lastName"
                    name="lastName"
                    label="Lastname"
                    placeholder="Lastname"
                    className="w-100"
                    error={getInputClasses("lastName")}
                    {...formik.getFieldProps("lastName")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={formik.touched.lastName && formik.errors.lastName}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.lastName}
                    </div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="position"
                    name="position"
                    label="Position"
                    placeholder="Position"
                    className="w-100"
                    error={getInputClasses("position")}
                    {...formik.getFieldProps("position")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={formik.touched.position && formik.errors.position}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.position}
                    </div>
                  </div>
                </RenderIf>
              </div>

              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="title"
                    name="title"
                    label="title"
                    placeholder="title"
                    className="w-100"
                    error={getInputClasses("title")}
                    {...formik.getFieldProps("title")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf
                  condition={formik.touched.title && formik.errors.title}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.title}</div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 mt-3 col-lg-6">
                <input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  name="file"
                  label="File"
                  placeholder="File"
                  className="w-100"
                  onChange={imagePreview}
                />
                <RenderIf condition={formik.touched.file && formik.errors.file}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.file}</div>
                  </div>
                </RenderIf>

                <RenderIf condition={imageSrc}>
                  <div className="image-preview mt-4">
                    <div
                      style={{
                        position: "relative",
                        width: "100px",
                        height: "100px",
                      }}
                    >
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          borderRadius: "100%",
                        }}
                        src={imageSrc}
                        alt="img"
                      />
                      <button
                        style={{
                          position: "absolute",
                          right: "-30px",
                        }}
                        className="BtnSvg"
                        onClick={onClick}
                      >
                        <i
                          style={{ fontSize: "10px" }}
                          className="flaticon2-delete"
                        ></i>
                      </button>
                    </div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-12 mt-3">
                <RichTextEditor
                  onChange={(newContent) => {
                    const trimmedContent = newContent.trim();
                    const isEmpty = trimmedContent === "<p><br></p>";
                    setContent(isEmpty ? "" : newContent);
                    formik.setFieldValue(
                      "description",
                      isEmpty ? "" : newContent
                    );
                  }}
                  value={content}
                />
                <RenderIf
                  condition={
                    formik.touched.description && formik.errors.description
                  }
                  renderElse={null}
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.description}
                    </div>
                  </div>
                </RenderIf>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
              setDefaultState(true);
            }}
          >
            Close
          </Button>
          <Button type="submit" disabled={isLoading} className={`px-9`}>
            Add
            <RenderIf condition={isLoading}>
              <span className="ml-3 spinner spinner-white"></span>
            </RenderIf>
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
