import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { Formik } from "formik";
import * as Yup from "yup";
import { RenderIf, RichTextEditor } from "../../../components";
import {
  useGetCommunityQuery,
  useUpdateCommunityMutation,
} from "../../../../redux/api/Community/communityApi";
const { REACT_APP_BASE_PHOTO_URL_MAIN } = process.env;
export default function Edit(props) {
  const { onHide, show, refetch, id } = props;
  const [content, setContent] = useState(false);
  const { data, isSuccess: dataSuccess } = useGetCommunityQuery(id);
  const filePath = REACT_APP_BASE_PHOTO_URL_MAIN + data?.fileUrl;
  const [file, setFile] = useState("");
  const [preview, setPreview] = useState(true);
  const [imageSrc, setImageSrc] = useState(filePath);
  useEffect(() => {
    setImageSrc(filePath);
  }, [filePath]);

  const imagePreview = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    setFile(e.target.value);

    reader.onloadend = () => {
      setImageSrc(reader.result);
    };

    reader.readAsDataURL(file);

    setPreview(true);
  };
  const onClick = (e) => {
    setPreview(false);
    setImageSrc("");
    setFile("");
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [
    updateCommunity,
    { isLoading, error, isSuccess },
  ] = useUpdateCommunityMutation();

  useEffect(() => {
    if (isSuccess) {
      refetch();
      onHide();
    }
  }, [isLoading]);

  const form = useRef(null);

  const EditSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    file: Yup.string().required("File is required"),
    description: Yup.string()
      .required("Description is required")
      .test("rich-text-not-empty", "Description cannot be empty", (value) => {
        if (typeof value !== "string") return false;
        const trimmedValue = value.trim();
        return trimmedValue !== "<p><br></p>" && trimmedValue !== "";
      }),
  });

  const getInputClasses = (touched, errors, FieldName) => {
    if (touched[FieldName] && errors[FieldName]) {
      return true;
    }
    if (touched[FieldName] && errors[FieldName]) {
      return false;
    }

    return false;
  };

  const onPutCommunity = (values, resetForm, setSubmitting) => {
    const community = new FormData(form.current);
    community.append("Id", id);
    community.append("description", String(values.description));

    updateCommunity(community);
  };
  useEffect(() => {
    if (data) {
      setContent(data?.description);
    }
  }, [data]);
  return (
    <Modal
      size="xl"
      onHide={onHide}
      show={show}
      aria-labelledby="edit"
      className="modal-center pr-0"
      centered
    >
      <RenderIf
        condition={
          data && Object.keys(data).length !== 0 && data.constructor === Object
        }
      >
        <Formik
          initialValues={{
            title: data?.title,
            description: data?.description,
            file: data?.fileUrl,
          }}
          validationSchema={EditSchema}
          onSubmit={(values, { resetForm, setSubmitting }) => {
            onPutCommunity(values, resetForm, setSubmitting);
          }}
        >
          {({
            values,
            errors,
            touched,
            setValues,
            setFieldValue,
            handleSubmit,
            handleChange,
            handleBlur,
            resetForm,
          }) => (
            <>
              <form onSubmit={handleSubmit} ref={form}>
                <Modal.Header closeButton>
                  <Modal.Title id="edit">Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body id="userModal">
                  <div className="col-12 pl-4 pr-4">
                    <div className="row align-items-center">
                      <div className="col-12 col-md-12 col-lg-6">
                        <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                          <TextField
                            id="title"
                            name="title"
                            label="Name"
                            placeholder="Name"
                            className="w-100"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={getInputClasses(touched, errors, "title")}
                            onKeyDown={handleEnter}
                          />
                        </div>
                        <RenderIf
                          condition={touched.title && errors.title}
                          renderElse={null}
                        >
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.title}</div>
                          </div>
                        </RenderIf>
                      </div>

                      <div className="col-12 col-md-12 col-lg-6">
                        <div className="w-100 pt-2 pb-3">
                          <label htmlFor="contained-button-file">
                            <input
                              accept="image/*"
                              id="contained-button-file"
                              type="file"
                              name="file"
                              label="File {Path}"
                              placeholder="File Path"
                              className="w-100"
                              onChange={(e) => {
                                imagePreview(e);
                                setValues({
                                  ...values,
                                  file: e.target.files[0],
                                });
                              }}
                              value={file}
                            />
                          </label>

                          <RenderIf condition={imageSrc}>
                            <div className="image-preview mt-4">
                              <div
                                style={{
                                  position: "relative",
                                  width: "100px",
                                  height: "100px",
                                }}
                              >
                                <img
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                    borderRadius: "100%",
                                  }}
                                  src={imageSrc}
                                  alt="img"
                                />
                                <button
                                  style={{
                                    position: "absolute",
                                    right: "-30px",
                                  }}
                                  className="BtnSvg"
                                  onClick={() => {
                                    onClick();
                                    setFieldValue("file", "");
                                  }}
                                >
                                  <i
                                    style={{
                                      fontSize: "10px",
                                    }}
                                    className="flaticon2-delete"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </RenderIf>
                        </div>
                        <RenderIf
                          condition={touched.file && errors.file}
                          renderElse={null}
                        >
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{errors.file}</div>
                          </div>
                        </RenderIf>
                      </div>
                      <div className="col-12 col-md-12 col-lg-12">
                        <div className="w-100 pt-2 pb-3">
                          <RichTextEditor
                            // onChange={(content) => {
                            //   setContent(content);
                            //   setFieldValue("description", content);
                            // }}
                            onChange={(newContent) => {
                              const trimmedContent = newContent.trim();
                              const isEmpty = trimmedContent === "<p><br></p>";
                              setContent(isEmpty ? "" : newContent);
                              setFieldValue(
                                "description",
                                isEmpty ? "" : newContent
                              );
                            }}
                            value={content}
                          />
                        </div>
                        <RenderIf
                          condition={touched.description && errors.description}
                        >
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {errors.description}
                            </div>
                          </div>
                        </RenderIf>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="light"
                    onClick={() => {
                      onHide(false);
                      resetForm();
                    }}
                  >
                    Close
                  </Button>
                  <Button type="submit" disabled={isLoading} className={`px-9`}>
                    Save
                    <RenderIf condition={isLoading}>
                      <span className="ml-3 spinner spinner-white"></span>
                    </RenderIf>
                  </Button>
                </Modal.Footer>
              </form>
            </>
          )}
        </Formik>
      </RenderIf>
    </Modal>
  );
}
