import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";

export const discussionsApi = createApi({
  reducerPath: "discussionsApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    deleteDiscussion: builder.mutation({
      query(id) {
        return {
          url: `discussion`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),
    deleteDiscussionReport: builder.mutation({
      query(id) {
        return {
          url: `discussion/report`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),
  }),
});

export const {
  useDeleteDiscussionMutation,
  useDeleteDiscussionReportMutation,
} = discussionsApi;
