import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";

export const newsApi = createApi({
  reducerPath: "newsApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getAllNews: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, name, typeId } = filter;
        return {
          url: `news/filter?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            name ? `&Name=${name.name}` : ""
          }${typeId ? `&TypeId=${typeId}` : ""}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          // dispatch(setInterests(data.data));
        } catch (error) {}
      },
    }),
    getNews: builder.query({
      query(id) {
        return {
          url: `news/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    createNews: builder.mutation({
      query(data) {
        return {
          url: "news",
          method: "POST",
          data,
        };
      },
      transformResponse: (result) => result,
    }),
    updateNews: builder.mutation({
      query(data) {
        return {
          url: "news",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
    }),
    deleteNews: builder.mutation({
      query(id) {
        return {
          url: `news`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),
  }),
});

export const {
  useCreateNewsMutation,
  useDeleteNewsMutation,
  useGetAllNewsQuery,
  useGetNewsQuery,
  useUpdateNewsMutation,
} = newsApi;
