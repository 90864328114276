import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";

export const tagsApi = createApi({
  reducerPath: "tagsApi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getAllTags: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, name } = filter;
        return {
          url: `tag/filter?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            name ? `&Name=${name.name}` : ""
          }`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          // dispatch(setInterests(data.data));
        } catch (error) {}
      },
    }),
    getTag: builder.query({
      query(id) {
        return {
          url: `tag/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    createTag: builder.mutation({
      query(data) {
        return {
          url: "tag",
          method: "POST",
          data,
        };
      },
      transformResponse: (result) => result,
    }),
    updateTag: builder.mutation({
      query(data) {
        return {
          url: "tag",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
    }),
    deleteTag: builder.mutation({
      query(id) {
        return {
          url: `tag`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),
    getAllTagsWithoutFilter: builder.query({
      query(filter) {
        return {
          url: `tag/filter`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          // dispatch(setInterests(data.data));
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useGetAllTagsWithoutFilterQuery,
  useCreateTagMutation,
  useDeleteTagMutation,
  useGetAllTagsQuery,
  useGetTagQuery,
  useUpdateTagMutation,
} = tagsApi;
