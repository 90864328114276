import React, { useMemo, useState, useEffect } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import {
    startOfDay,
    endOfDay,
    startOfWeek,
    endOfWeek,
    startOfMonth,
    endOfMonth,
    addDays,
    subDays,
    format,
} from "date-fns";
import { dateConvert } from "../../../../Helpers/dateConvert";

export function Topbar(props) {
    const { setMessage } = props;
    const uiService = useHtmlClassService();
    const [dateState, setDateState] = useState(
        format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'")
    );
    useEffect(() => {
        setInterval(
            () => setDateState(format(new Date(), "dd.MM.yyyy HH:mm:ss")),
            1000
        );
    }, []);
    const layoutProps = useMemo(() => {
        return {
            viewSearchDisplay: objectPath.get(
                uiService.config,
                "extras.search.display"
            ),
            viewNotificationsDisplay: objectPath.get(
                uiService.config,
                "extras.notifications.display"
            ),
            viewQuickActionsDisplay: objectPath.get(
                uiService.config,
                "extras.quick-actions.display"
            ),
            viewCartDisplay: objectPath.get(
                uiService.config,
                "extras.cart.display"
            ),
            viewQuickPanelDisplay: objectPath.get(
                uiService.config,
                "extras.quick-panel.display"
            ),
            viewLanguagesDisplay: objectPath.get(
                uiService.config,
                "extras.languages.display"
            ),
            viewUserDisplay: objectPath.get(
                uiService.config,
                "extras.user.display"
            ),
        };
    }, [uiService]);

    return (
        <div className="topbar">
            {/* <div>{setInterval(() => dateConvert(new Date(), true), 1000)}</div> */}

            {process.env.REACT_APP_STAGING === "true" ? (
                <h5 className="d-flex align-items-center h1 text-info">
                    Staging
                </h5>
            ) : (
                ""
            )}

            {layoutProps.viewSearchDisplay && <SearchDropdown />}

            {layoutProps.viewUserDisplay && (
                <QuickUserToggler setMessage={setMessage} />
            )}
        </div>
    );
}
