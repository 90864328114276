/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector, useDispatch } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import Tooltip from "@material-ui/core/Tooltip/index";
import LockIcon from "@material-ui/icons/Lock";
import ChangePassword from "./modals/ChangePassword";
import { logout } from "../../../../../redux/features/User/userSlice";

export function UserProfileDropdown(props) {
  const dispatch = useDispatch();
  const { setMessage } = props;
  const { user } = useSelector((state) => state.user);
  const uiService = useHtmlClassService();
  const [showChangePassword, setShowChangePassword] = useState(false);
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          }
        >
          <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
            Hello,
          </span>
          <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline">
            {user?.firstName} {user?.lastName}
          </span>

          {user.roles?.length > 0 &&
            (user.roles[0].description == "Admin" ? (
              <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline">
                Admin
              </span>
            ) : (
              <span>
                {user.roles?.map((role, index, array) => (
                  <span>
                    {index == array.length - 1 ? (
                      <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline user-roles">
                        {role.description}
                      </span>
                    ) : (
                      <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline user-roles">
                        {role.description},
                      </span>
                    )}
                  </span>
                ))}
              </span>
            ))}

          <span className="symbol symbol-35 symbol-light-primary ml-3">
            <span className="symbol-label font-size-h5 font-weight-bold">
              {user && user?.firstName && user?.firstName[0]}
            </span>
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {layoutProps.light && (
            <>
              <div className="d-flex align-items-center p-8 rounded-top">
                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                  <img src={toAbsoluteUrl("/media/users/300_21.jpg")} alt="" />
                </div>
                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                  {user.firstName} {user.lastName}
                </div>
              </div>
              <div className="separator separator-solid"></div>
            </>
          )}

          {!layoutProps.light && (
            <div
              className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
              style={{
                background: "#057953",
              }}
            >
              <div className="symbol bg-white-o-15 mr-3">
                <span className="symbol-label text-primary font-weight-bold font-size-h4">
                  {user && user?.firstName && user?.firstName[0]}
                </span>
              </div>
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                {user.firstName} {user.lastName} <br></br>
                {user.email}
                {user.roles?.length > 0 &&
                  (user.roles[0].description == "Admin" ? (
                    <span>Admin</span>
                  ) : (
                    <span>
                      {user.roles?.map((role, index, array) => (
                        <span>
                          {index == array.length - 1 ? (
                            <span className="user-roles">
                              {role.description}
                            </span>
                          ) : (
                            <span className="user-roles">
                              {role.description},
                            </span>
                          )}
                        </span>
                      ))}
                    </span>
                  ))}
              </div>
            </div>
          )}
        </>

        <div className="navi navi-spacer-x-0 pt-5">
          <div className="navi-footer  px-8 py-5">
            <a
              onClick={() => {
                dispatch(logout());
              }}
              href="#"
            >
              Logout
            </a>

            <Tooltip title="Change password" placement="top">
              <button
                aria-label="changePassword"
                className="btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-warning"
                onClick={() => setShowChangePassword(true)}
              >
                <LockIcon />
              </button>
            </Tooltip>
          </div>
          <ChangePassword
            show={showChangePassword}
            onHide={setShowChangePassword}
            id={user.id}
            setMessage={setMessage}
          />
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
