import { createApi } from "@reduxjs/toolkit/query/react";
import { APIBaseQuery } from "../AxiosBase";

export const contactApi = createApi({
  reducerPath: "contactAPi",
  baseQuery: APIBaseQuery,
  keepUnusedDataFor: 1,
  endpoints: (builder) => ({
    getContacts: builder.query({
      query(filter) {
        const { skip, take, field, orderBy, title } = filter;
        return {
          url: `contact/filter?Skip=${skip}&Take=${take}&SortField=${field}&OrderBy=${orderBy}${
            title ? `&Title=${title.title}` : ""
          }`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          // dispatch(setInterests(data.data));
        } catch (error) {}
      },
    }),
    getContact: builder.query({
      query(id) {
        return {
          url: `contact/byId?id=${id}`,
        };
      },
      keepUnusedDataFor: 1,
      transformResponse: (result) => result,
    }),
    createContact: builder.mutation({
      query(data) {
        return {
          url: "contact",
          method: "POST",
          data: data,
        };
      },
      transformResponse: (result) => result,
    }),
    updateContact: builder.mutation({
      query(data) {
        return {
          url: "contact",
          method: "PUT",
          data: data,
        };
      },
      transformResponse: (result) => result,
    }),
    deleteContact: builder.mutation({
      query(id) {
        return {
          url: `contact`,
          method: "DELETE",
          data: { id: id },
        };
      },
    }),
  }),
});

export const {
  useCreateContactMutation,
  useDeleteContactMutation,
  useGetContactsQuery,
  useGetContactQuery,
  useUpdateContactMutation,
} = contactApi;
